import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useApiKeysTableHeadData(permissionsStyle: any) {
  const [translate] = useTranslation();
  return [
    {
      title: `${translate('NAME')}`,
      key: 'name',
    },
    {
      title: `${translate('KEY')}`,
      key: 'apikey',
    },
    {
      title: `${translate('PERMISSIONS')}`,
      key: (row: any) => {
        return permissionsStyle(row);
      },
    },
    {
      title: `${translate('CREATION_DATE')}`,
      key: (row: any) => {
        return moment(row.date).format('YYYY-MM-DD');
      },
    },
    {
      title: `${translate('ACTIVE')}`,
      key: (row: any) => {
        return row?.is_active;
      },
    },
  ];
}

export const apiKeyValidationSchema = Yup.object({
  apiKeyName: Yup.string()
    .trim()
    .required('API_KEY_NAME_REQUIRED_VALIDATION')
    .min(4, 'API_KEY_NAME_MIN_LENGTH_VALIDATION')
    .matches(/^\w+$/g, 'API_KEY_NAME_NO_WHITE_SPACE_VALIDATION'),
});

export const apiKeyFormSchema = {
  apiKeyName: '',
  isActive: false,
  isAdmin: false,
  isIndexing: false,
  isSearch: false,
};
