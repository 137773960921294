import { lablebClient } from '../../api-client';
import { useQuery } from 'react-query';
import { indicesKeys } from './indices.key';

interface UseProjectIndicesParams {
  projectId: string;
  enable?: boolean;
}

export function useProjectIndices(params: UseProjectIndicesParams) {
  const { projectId, enable } = params;

  return useQuery(
    indicesKeys.all(projectId),
    function fetchIndices() {
      return lablebClient.indices.fetchIndices({ platformId: projectId });
    },
    {
      select: (response) =>
        response.response.indices.filter(
          (indexItem) => !indexItem.id.includes('-suggest')
        ),
      enabled: enable || false,
    }
  );
}

export function useIndexHandlers({
  platformId,
  indexId,
}: {
  platformId: string;
  indexId: string;
}) {
  const indicesResponse = useProjectIndices({ projectId: platformId });

  const searchHandlers =
    indicesResponse?.data?.find((indexItem) => indexItem.id == indexId)
      ?.search_handlers ?? [];
  const autocompleteHandlers =
    indicesResponse?.data?.find((indexItem) => indexItem.id == indexId)
      ?.autocomplete_handlers ?? [];
  const recommendHandlers =
    indicesResponse?.data?.find((indexItem) => indexItem.id == indexId)
      ?.recommended_handlers ?? [];

  const isLoading = indicesResponse?.isLoading;
  const isFetching = indicesResponse?.isFetching;

  const searchlist = searchHandlers.map((item: any) => (item.type = 'search'));
  const AutoList = autocompleteHandlers.map(
    (item: any) => (item.type = 'autocomplete')
  );
  const recoList = recommendHandlers.map(
    (item: any) => (item.type = 'recommend')
  );

  return {
    searchHandlers,
    autocompleteHandlers,
    recommendHandlers,
    isLoading,
    isFetching,
  };
}
