import styled from '@emotion/styled';

const SliderWrapper = styled('div')`
  .slick-dots {
    text-align: right;
    padding: 0px 18px;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: #d2dbee;
  }

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: white;
    font-size: 10px;
  }
`;

export default SliderWrapper;
