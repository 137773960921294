import { CopyFiledSection } from './components/copy-field-section';
import { SchemaFieldsSection } from './components/schema-fields-section';
import { SuggestCopyFieldSection } from './components/suggest-copy-field-section';

export function SchemaPageLoader() {
  return (
    <>
      <div className="w-full flex flex-col gap-10">
        <SchemaFieldsSection
          loading={true}
          schemaResponse={[]}
          viewField={{}}
          fieldTypeStyle={() => {}}
        />

        <CopyFiledSection
          loading={true}
          schemaResponse={[]}
          fieldTypeStyle={() => {}}
          viewCopyField={{}}
        />

        <SuggestCopyFieldSection
          loading={true}
          suggestSchemaResponse={[]}
          fieldTypeStyle={() => {}}
          viewSuggestCopyField={{}}
        />
      </div>
    </>
  );
}
