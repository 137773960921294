import { useTranslation } from 'react-i18next';
import { BillingCard } from '../../design-system/billing-card/billing-card';
import { CurrentPlanCard } from './components/current-plan-card';
import { LablebCreditCard } from './components/credit-card';
import requestUsageIcon from '../../assets/icons/request-usage-icon.svg';
import documentUsageIcon from '../../assets/icons/document-usage-icon.svg';
export function BillingsPageLoader() {
  const [translate] = useTranslation();
  return (
    <>
      <div className="w-full">
        <div className="flex items-center mb-8">
          <span className="text-[20px] font-bold">{translate('BILLING')}</span>
        </div>

        <div className="flex w-full justify-between items-center mb-5 flex-wrap">
          <div className="w-[49%]">
            <BillingCard
              loading={true}
              cardIcon={requestUsageIcon}
              cardTitle={'REQUESTS_USAGE'}
              cardValue={0}
              percentageValue={0}
              progressColor={'#3398DA'}
              totalValue={0}
            />
          </div>
          <div className="w-[49%]">
            <BillingCard
              loading={true}
              cardIcon={documentUsageIcon}
              cardTitle={'DOCUMENTS_USAGE'}
              cardValue={0}
              percentageValue={0}
              progressColor={'#9B5AB6'}
              totalValue={0}
            />
          </div>
        </div>

        <div className="max-w-[470px]  flex flex-col">
          <span className="text-[#030F2C] font-medium mb-3 text-[24px]">
            {translate('CURRENT_PLAN')}
          </span>
          <CurrentPlanCard plan={{}} loading={true} />

          <div className="w-full">
            <span className="text-[#030F2C] font-medium text-[24px]">
              {translate('PAYMENT_METHODE')}
            </span>
            <div className="mt-5">
              <LablebCreditCard
                loading={true}
                paymentInfo={{}}
                hasCreditCard={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
