import { useTranslation } from 'react-i18next';
import { LablebInfoPopover } from '../info-popover/info-popover';
import infoIcon from '../../assets/icons/info-circle.svg';
import { LablebCountUp } from '../count-up/count-up';
import { LablebSkeleton } from '../skeleton/skeleton';
interface AnalyticsCardProps {
  cardIcon?: any;
  title: string;
  value: number;
  info?: string;
  loading?: boolean;
  suffix?: string;
}

export function AnalyticsCard(props: AnalyticsCardProps) {
  const [translate] = useTranslation();

  const { cardIcon, title, value, info, loading, suffix } = props;

  return (
    <>
      <div
        key={title}
        className="flex  items-center px-5 py-5 mb-5 w-[274px] h-[100px] bg-white rounded-[4px]"
      >
        <img src={cardIcon} className="w-[40px] h-[40px]" alt="analyticIcon" />
        <div className="flex flex-col  mx-5">
          <div className="flex items-center">
            <span className="text-[16px] whitespace-nowrap	 text-[#6D7B9B]">
              {translate(title)}
            </span>
            {info ? (
              <LablebInfoPopover
                popoverButton={
                  <img
                    src={infoIcon}
                    className="max-w-[25px] mx-3"
                    alt="edit"
                  />
                }
              >
                <div className="w-full px-1 ">
                  <span className="whitespace-nowrap text-[13px]">
                    {translate(info)}
                  </span>
                </div>
              </LablebInfoPopover>
            ) : null}
          </div>

          <span className="text-[24px] mt-2 font-bold text-[#030F2C]">
            {loading ? (
              <LablebSkeleton style="w-14 h-5 " />
            ) : (
              <LablebCountUp value={value} suffix={suffix} />
            )}
          </span>
        </div>
      </div>
    </>
  );
}
