import { useEffect, useState } from 'react';
import LablebLogo from '../../assets/images/lableb-logo.png';
import websiteIcon from '../../assets/icons/website.svg';
import accountIcon from '../../assets/icons/account.svg';

import projectIcon from '../../assets/icons/project-icon.svg';
import indexIcon from '../../assets/icons/index-icon.svg';
import menuIcon from '../../assets/icons/menu-2.svg';
import chevronsRightIcon from '../../assets/icons/chevrons-right.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import fileIcon from '../../assets/icons/file-text.svg';
import searchIcon from '../../assets/icons/search.svg';
import squarePlusIcon from '../../assets/icons/square-plus.svg';
import { ChevronDownIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { LablebPopover } from '../../design-system/popover/popover';
import { accountList, settingsList } from './top-nav-constants';
import { useTranslation } from 'react-i18next';
import useStore from '../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useProjects } from '../../hooks/projects/projects.hook';
import { useProjectIndices } from '../../hooks/indices/indices.hook';
import { LablebWarningDialog } from '../../design-system/warning-dialog/warning-dialog';
import { Popover } from '@headlessui/react';
import { ProjectsPopover } from './components/projects-popover';
import { useProject } from '../../hooks/projects/project.hook';
import { useIsEcommerce } from '../../utils/hooks';

interface TopNavProps {
  open: boolean;
  openSideNavHandler: () => void;
  closeSideNavHandler: () => void;
}

export function TopNav(props: TopNavProps) {
  const { projectId }: any = useParams();
  const { indexId }: any = useParams();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState<boolean>(false);
  const isEcommerce = useIsEcommerce(projectId);

  const [searchIndexs, setSearchIndexs] = useState<string>('');

  function searchIndexHandler(event: any) {
    setSearchIndexs(event.target.value);
  }

  const [searchProjects, setSearchProjects] = useState<string>('');

  function searchProjectHandler(event: any) {
    setSearchProjects(event.target.value);
  }
  const setOpenDrawerSideNav = useStore((state) => state?.setOpenDrawerSideNav);

  const { open, openSideNavHandler, closeSideNavHandler } = props;
  // const [direction, setDirection] = useState<string>('rtl')
  const { i18n, t } = useTranslation();
  const currentLanguage = useStore((state) => state.currentLanguage);
  const changeLang = useStore((state) => state.changeLang);
  const setAuthToken = useStore((state) => state.setAuthToken);

  const { data: projects, isLoading } = useProjects();

  let platformsNames = projects?.map((x: any) => x?.id) ?? [];
  let currentPlatform = projectId === undefined ? platformsNames[0] : projectId;

  const currentProject = useStore((state) => state?.currentProject);
  const changeProject = useStore((state) => state.changeProject);

  const indicesResponse = useProjectIndices({
    projectId: currentProject,
    enable: Boolean(currentProject),
  });
  let indexs = indicesResponse.data?.map((x) => x.id) ?? [];
  let currentInd = indexId === undefined ? indexs[0] : indexId;
  const currentIndex = useStore((state) => state.currentIndex);
  const changeIndex = useStore((state) => state.changeIndex);
  const { data: projectInfo }: any = useProject(currentPlatform);

  useEffect(() => {
    changeProject(currentPlatform);
    changeIndex(currentInd);
    //eslint-disable-next-line
  }, [currentPlatform, currentInd]);

  useEffect(() => {
    changeLanguages(currentLanguage);
    //eslint-disable-next-line
  }, [currentLanguage]);

  function changeLanguages(lang: string) {
    document.body.setAttribute('dir', lang === 'en' ? 'ltr' : 'rtl');
    i18n.changeLanguage(lang);
    document.documentElement.setAttribute('lang', lang);
  }
  const setWarningDialogHandler = useStore(
    (state) => state.setWarningDialogHandler
  );
  const closeWarningDialogHandler = useStore(
    (state) => state.closeWarningDialogHandler
  );

  function accountListHandler(item: any) {
    if (item?.isLogout) {
      setWarningDialogHandler({
        open: true,
        title: 'LOGOUT',
        body: 'LOGOUT_WARNING',
        onAcceptDispatch: () => logoutHandler(),
        onCancelDispatch: () => closeWarningDialogHandler(),
      });
    } else navigate(`/projects/${projectId}/me`);
  }

  function logoutHandler() {
    setAuthToken('');
    navigate(`/login`);
    localStorage.removeItem('token');
    closeWarningDialogHandler();
  }

  function viewWebsite() {
    window.open(projectInfo?.website);
  }
  return (
    <>
      <div className="w-full min-h-[64px]  bg-white  flex justify-between items-center px-10 border-b-2 sticky top-0 z-[9]">
        <div className="flex items-center">
          <img
            src={open ? menuIcon : chevronsRightIcon}
            onClick={open ? closeSideNavHandler : openSideNavHandler}
            className={clsx('max-w-[25px] cursor-pointer hidden sm:block', {
              'rotate-180': currentLanguage === 'en',
            })}
            alt="menu"
          />

          <img
            src={menuIcon}
            onClick={() => setOpenDrawerSideNav(true)}
            className="max-w-[25px] cursor-pointer block sm:hidden"
            alt="menu"
          />

          <img
            src={LablebLogo}
            alt="lableb-logo"
            className="w-[80px]  h-[40px] mx-7"
          />

          <div className="hidden sm:flex items-center justify-between mx-20">
            {projectInfo?.website ? (
              <img
                src={websiteIcon}
                alt="website"
                className="w-[22px] cursor-pointer h-[22px]"
                onClick={viewWebsite}
              />
            ) : null}

            <ProjectsPopover />
          </div>
        </div>

        <div className="flex items-center justify-between gap-5">
          <LablebPopover
            popoverButton={
              <img
                src={settingsIcon}
                className="max-w-[25px] hidden sm:block"
                alt="settings"
              />
            }
          >
            {settingsList(t, navigate, projectId).map((item: any) => (
              <Popover.Button key={item.name}>
                <div
                  key={item.name}
                  onClick={item.onClick}
                  className="flex items-center hover:bg-[#c5d7ff] rounded-lg justify-between cursor-pointer p-2 min-w-[200px]"
                >
                  <img
                    src={item.icon}
                    className="max-w-[25px]"
                    alt={item.alt}
                  />

                  <p className="text-sm font-medium text-gray-900">
                    {item.name}
                  </p>
                </div>
              </Popover.Button>
            ))}
          </LablebPopover>
          {!projectId?.includes('zid_') ? (
            <LablebPopover
              popoverButton={
                <img
                  src={accountIcon}
                  className="max-w-[25px] w-[20px]"
                  alt="button-icon"
                />
              }
            >
              {accountList(t, isEcommerce).map((item: any) =>
                !item.hide ? (
                  <Popover.Button key={item.name}>
                    <div
                      onClick={() => {
                        accountListHandler(item);
                      }}
                      key={item.name}
                      className="flex items-center hover:bg-[#c5d7ff] rounded-lg justify-between cursor-pointer p-2 min-w-[150px]"
                    >
                      <img
                        src={item.icon}
                        className="max-w-[25px]"
                        alt="button-icon"
                      />

                      <p className="text-sm font-medium text-gray-900">
                        {t(item.name)}
                      </p>
                    </div>
                  </Popover.Button>
                ) : null
              )}
            </LablebPopover>
          ) : null}

          <hr className="hidden sm:block border w-[1px] h-[20px] border-[#adadad]" />
          <div className="hidden sm:block border rounded-md px-2 text-primary text-[14px] border-primary">
            <button
              className="rtl:font-[SF-Pro-Display-Regular]  ltr:font-[DubaiW23-Regular]"
              onClick={() => changeLang(currentLanguage === 'ar' ? 'en' : 'ar')}
            >
              {t('LANGUAGE')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
