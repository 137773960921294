import searchIcon from '../../assets/icons/search.svg';
import circlePlusIcon from '../../assets/icons/circle-plus.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LablebTable } from '../../design-system/table/table';
import { queriesTableHeadData } from './queries-handlers.page.constants';

export function QueriesHandlersPageLoader() {
  const [inputSearch, setinputSearch] = useState<string>('');

  function inputSearchHandler(event: any) {
    setinputSearch(event.target.value);
  }
  function queriesTypeStyle(item: any) {
    return (
      <span
        style={{ color: `${item?.color}`, borderColor: `${item?.color}` }}
        className={`px-3 py-[2px] border-[1px] text-[12px]  rounded-2xl`}
      >
        {item?.lable}
      </span>
    );
  }
  const [translate] = useTranslation();
  return (
    <>
      <div className="w-full ">
        <div className="w-full flex items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">{translate('QUERIES')}</span>
          <div className=" flex items-center justify-between">
            <div className="relative mx-3">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[22px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                value={inputSearch}
                onChange={inputSearchHandler}
              />
            </div>

            <div className="flex p-2 cursor-pointer rounded-xl bg-primary items-center justify-center ">
              <img src={circlePlusIcon} className="max-w-[25px]" alt="add" />
            </div>
          </div>
        </div>

        <LablebTable
          loading={true}
          tableHeader={queriesTableHeadData(translate, queriesTypeStyle)}
          tableRows={[]}
        />
      </div>
    </>
  );
}
