import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import arrowDown from '../../assets/icons/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { boolean } from 'yup';

interface LablebSelectProps {
  selectedItem: any;
  onItemChange: any;
  itemsList: any;
  disabled?: boolean;
  icon?: any;
  reverseOption?: boolean;
}

export function LablebSelect(props: LablebSelectProps) {
  const {
    selectedItem,
    onItemChange,
    itemsList,
    disabled,
    icon,
    reverseOption,
  } = props;
  const [translate] = useTranslation();
  return (
    <>
      <Listbox value={selectedItem} disabled={disabled} onChange={onItemChange}>
        <div className="relative min-w-[200px]">
          <Listbox.Button
            className={`${
              disabled ? 'bg-[#f5f8ff]' : 'bg-white'
            } relative flex w-full items-center cursor-pointer py-3 px-5 rounded-lg  min-h-[50px]  border-[1px] outline-none border-[#d2dbee] bg-white    focus:outline-none focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-[#c5d7ff] sm:text-sm`}
          >
            {icon ? (
              <img src={icon} className="max-w-[25px] " alt="index" />
            ) : null}

            <span className={clsx('', { 'mx-3': icon })}>
              {translate(selectedItem)}
            </span>
            <span className="pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 px-3 flex items-center ">
              <img src={arrowDown} alt={'down'} className="w-15 h-15" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={clsx(
                'absolute  z-[9999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                {
                  'bottom-12': reverseOption,
                }
              )}
            >
              {itemsList?.map((item: any) => (
                <Listbox.Option
                  key={item}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 px-4 ${
                      active
                        ? 'bg-[#c5d7ff] rounded-md text-gray-900'
                        : 'text-gray-900'
                    }`
                  }
                  value={typeof item == 'object' ? item?.key : item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {typeof item == 'object'
                          ? translate(item?.value)
                          : translate(item)}
                      </span>
                      {/* {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null} */}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
}
