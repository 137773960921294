import { useQuery } from 'react-query';
import { lablebClient } from '../../api-client';

export function useProject(projectId: string) {
  return useQuery(
    ['project', projectId],
    function fetchProjectDetails() {
      return lablebClient.platforms.fetchById({
        platformId: projectId,
      });
    },
    {
      select: (response) => response.response.project,
    }
  );
}
