import moment from 'moment';
import { LablebDatePicker } from '../date-picker/date-picker';
import { LablebSelect } from '../select/select';
import useStore from '../../store/store';

// interface FilterDatePickerProps {
//   selectedOption: string;
//   setSelectedOption: any;
//   fromDate: Date;
//   setFromDate: any;
//   toDate: Date;
//   setToDate: any;
// }
const filterDateList = [
  'LAST1DAYS',
  'LAST7DAYS',
  'LAST30DAYS',
  'LAST90DAYS',
  'CUSTOM',
];
export function FilterDatePicker() {
  // const {
  //   selectedOption,
  //   setSelectedOption,
  //   fromDate,
  //   setFromDate,
  //   toDate,
  //   setToDate,
  // } = props;

  const analyticsDateFilters = useStore((state) => state.analyticsDateFilters);
  const setAnalyticsDateFilters = useStore(
    (state) => state.setAnalyticsDateFilters
  );

  return (
    <>
      <div className="flex items-center flex-wrap gap-4">
        {analyticsDateFilters.currentSelect === 'CUSTOM' ? (
          <LablebDatePicker
            fromDate={analyticsDateFilters.from}
            setFromDate={(value) =>
              setAnalyticsDateFilters({ ...analyticsDateFilters, from: value })
            }
            toDate={analyticsDateFilters.to}
            setToDate={(value) =>
              setAnalyticsDateFilters({ ...analyticsDateFilters, to: value })
            }
          />
        ) : null}

        <div className="w-[250px]">
          <LablebSelect
            selectedItem={analyticsDateFilters.currentSelect}
            onItemChange={(value: any) => {
              if (value === 'LAST1DAYS') {
                setAnalyticsDateFilters({
                  currentSelect: value,
                  from: new Date(),
                  to: new Date(),
                });
              }
              if (value === 'LAST7DAYS') {
                setAnalyticsDateFilters({
                  currentSelect: value,
                  from: moment().subtract('7', 'day').toDate(),
                  to: new Date(),
                });
              }
              if (value === 'LAST30DAYS') {
                setAnalyticsDateFilters({
                  currentSelect: value,
                  from: moment().subtract('30', 'day').toDate(),
                  to: new Date(),
                });
              }
              if (value === 'LAST90DAYS') {
                setAnalyticsDateFilters({
                  currentSelect: value,
                  from: moment().subtract('90', 'day').toDate(),
                  to: new Date(),
                });
              }
              if (value === 'CUSTOM') {
                setAnalyticsDateFilters({
                  ...analyticsDateFilters,
                  currentSelect: 'CUSTOM',
                });
              }
            }}
            itemsList={filterDateList}
          />
        </div>
      </div>
    </>
  );
}
