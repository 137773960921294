import { useQuery } from 'react-query';
import { lablebClient } from '../../api-client';

export function useCurrentUser(projectId: string) {
  return useQuery(
    ['project-roles', projectId],
    function fetchProjectRoles() {
      return lablebClient.roles.fetchPlatformRoles({
        platformId: projectId,
      });
    },
    {
      select: (response) => response.response.user,
    }
  );
}
