import { SchemaField } from '@lableb/api-client';
import { fieldTypeArray } from '../schema/schema.page.constants';

export const queriesHandlersType: any = [
  {
    type: 'search',
    lable: 'Search Handlers',
    color: '#9B5AB6',
  },
  {
    type: 'autocomplete',
    lable: 'Autocomplete Handlers',
    color: '#26AE60',
  },
  {
    type: 'recommend',
    lable: 'Recommendation Handlers',
    color: '#2980B9',
  },
];

export function queriesTableHeadData(translate: any, queriesTypeStyle: any) {
  return [
    {
      title: `${translate('QUERIY_TITLE')}`,
      key: 'name',
    },
    {
      title: `${translate('QUERIY_TYPE')}`,
      key: (row: any) => {
        return (
          queriesTypeStyle(
            queriesHandlersType.find(
              (option: any) => option?.type === row?.type
            )
          ) ?? ''
        );
      },
    },
  ];
}

export function getFieldType({
  fieldName,
  schemaFields,
}: {
  fieldName: string;
  schemaFields: SchemaField[];
}) {
  let type =
    schemaFields?.find((field) => field.name == fieldName)?.field_type ?? '';
  return fieldTypeArray.find((option: any) => option?.fieldType === type) ?? '';
}

export function searchHandlersTableHeadData(
  isCopyFieldIcon: any,
  translate: any,
  fieldTypeStyle: any,
  schemaFields: any
) {
  return [
    {
      title: `${translate('FIELD')}`,
      key: 'name',
    },
    {
      title: `${translate('FIELD_TYPE')}`,
      key: (row: any) => {
        if (row?.isCopyField) {
          return isCopyFieldIcon();
        } else {
          const fieldType = getFieldType({
            fieldName: row.name,
            schemaFields,
          });
          return fieldTypeStyle(fieldType) ?? '';
        }
      },
    },
    {
      title: `${translate('SEARCHABLE')}`,
      key: (row: any) => {
        return row?.is_active;
      },
    },
    {
      title: `${translate('API_RESPONES')}`,
      key: (row: any) => {
        return row?.is_response;
      },
    },
    {
      title: `${translate('Facets')}`,
      key: (row: any) => {
        return row?.is_facet_field;
      },
    },
    {
      title: `${translate('Highlight')}`,
      key: (row: any) => {
        return row?.is_highlight || false;
      },
    },
  ];
}

export function autocompleteHandlersTableHeadData(
  isCopyFieldIcon: any,
  translate: any,
  fieldTypeStyle: any,
  schemaFields: any
) {
  return [
    {
      title: `${translate('FIELD')}`,
      key: 'name',
    },
    {
      title: `${translate('FIELD_TYPE')}`,
      key: (row: any) => {
        if (row?.isCopyField) {
          return isCopyFieldIcon();
        } else {
          const fieldType = getFieldType({
            fieldName: row.name,
            schemaFields,
          });
          return fieldTypeStyle(fieldType) ?? '';
        }
      },
    },
    {
      title: `${translate('API_RESPONES')}`,
      key: (row: any) => {
        return row?.is_response;
      },
    },
    {
      title: `${translate('Facets')}`,
      key: (row: any) => {
        return row?.is_facet_field;
      },
    },
  ];
}

export function recommenderHandlersTableHeadData(
  isCopyFieldIcon: any,
  translate: any,
  fieldTypeStyle: any,
  schemaFields: any
) {
  return [
    {
      title: `${translate('FIELD')}`,
      key: 'name',
    },
    {
      title: `${translate('FIELD_TYPE')}`,
      key: (row: any) => {
        if (row?.isCopyField) {
          return isCopyFieldIcon();
        } else {
          const fieldType = getFieldType({
            fieldName: row.name,
            schemaFields,
          });
          return fieldTypeStyle(fieldType) ?? '';
        }
      },
    },
    {
      title: `${translate('Similarity')}`,
      key: (row: any) => {
        if (row?.is_short_similarity_field || row?.is_long_similarity_field) {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      title: `${translate('API_RESPONES')}`,
      key: (row: any) => {
        return row?.is_response;
      },
    },
  ];
}

export function relevanceFormulaTableHeadData(translate: any) {
  return [
    {
      title: `${translate('FRESHNESS_FIELD')}`,
      key: 'freshness_field',
    },
    {
      title: `${translate('FRESHNESS_WEIGHT')}`,
      key: 'freshness_weight',
    },
    {
      title: `${translate('TEXT_WEIGHT')}`,
      key: 'text_weight',
    },
    {
      title: `${translate('CLICKS_WEIGHT')}`,
      key: 'clicks_weight',
    },
    {
      title: `${translate('RELEVANCE_FACTOR')}`,
      key: 'relevance_factor',
    },
  ];
}

export function customParamtersTableHeadData(translate: any) {
  return [
    {
      title: `${translate('PARAMETER_NAME')}`,
      key: 'name',
    },
    {
      title: `${translate('PARAMETER_VALUE')}`,
      key: 'value',
    },
  ];
}
