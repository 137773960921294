import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { SideNav } from '../side-nav/side-nav';
import { TopNav } from '../top-nav/top-nav';
import { ResponsiveSideNav } from '../side-nav/responsive-side-nav/responsive-side-nav';
import useStore from '../../store/store';
import { useProjects } from '../../hooks/projects/projects.hook';
import { useTranslation } from 'react-i18next';

function useDisplayProjectsWithIssues() {
  const { data: projects } = useProjects();
  const setWarningDialogHandler = useStore(
    (state) => state.setWarningDialogHandler
  );

  const [translate] = useTranslation();

  /**
   * list the projects that need payment
   */
  useEffect(() => {
    const projectsThatNeedAttention: string[] =
      projects
        ?.filter(
          (project) =>
            project?.subscription?.invoice?.status == 'open' ||
            project?.subscription?.invoice?.status == 'uncollectible'
        )
        ?.map((project) => project.id) ?? [];

    if (projectsThatNeedAttention.length) {
      setWarningDialogHandler({
        hideAcceptButton: true,
        open: true,
        title: 'BILLING_REQUIRED_WARNING_TITLE',
        body: (
          <>
            <p className="">
              {translate(`BILLING_REQUIRED_WARNING_DESCRIPTION`)}
            </p>
            <div className="flex flex-col gap-2 my-3">
              <span className="text-[#909090] text-md">
                {translate('PROJECTS')}:
              </span>
              {projectsThatNeedAttention.map((project) => (
                <span className="font-bold">{project}</span>
              ))}
            </div>
          </>
        ),
      });
    }
  }, [projects?.length]);
}

export function LayoutPage() {
  const [open, setOpen] = useState<boolean>(false);

  function openSideNavHandler() {
    setOpen(true);
  }

  function closeSideNavHandler() {
    setOpen(false);
  }
  useDisplayProjectsWithIssues();
  return (
    <>
      <div className="w-full overflow-auto relative h-full min-h-[100vh] flex flex-col">
        <TopNav
          open={open}
          openSideNavHandler={openSideNavHandler}
          closeSideNavHandler={closeSideNavHandler}
        />
        <div className="w-full overflow-auto  h-full flex-grow flex">
          <ResponsiveSideNav />
          <SideNav open={open} />
          <div className="flex flex-grow  bg-[#F5F8FF] p-5">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
