export function indiciesTableHeadData(translate: any) {
  return [
    {
      title: `${translate('INDEX')}`,
      key: 'name',
    },
    {
      title: `${translate('LAST_UPDATED')}`,
      key: 'documents_count',
    },
    {
      title: `${translate('RECORDS')}`,
      key: 'documents_count',
    },
  ];
}

export function platformTypes(translate: any) {
  return [
    {
      label: `Wordpress`,
      value: 'wordpress',
    },
    {
      label: `Magento`,
      value: 'magento',
    },
    {
      label: `${translate('ELECTONICS_ECOMMERCE')}`,
      value: 'electronics-ecommerce',
    },
  ];
}
