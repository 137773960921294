import { useParams } from 'react-router-dom';
import { LablebSelect } from '../../../../../../design-system/select/select';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSearchAnalytics } from '../../../../../../hooks/analytics/search-analytics.hook';
import { usePaginatedData } from '../../../../../../utils/hooks/paginatedData';
import {
  queryClicksLogsTableHeadData,
  queryClicksTableHeadData,
  searchAnalyticsTableHeadData,
} from '../../../search-analytics.page.constants';
import { LablebPagination } from '../../../../../../design-system/pagination/pagination';
import { LablebTable } from '../../../../../../design-system/table/table';
import useStore from '../../../../../../store/store';
import { QueryInfoTab } from '../../most-clicked-results-page/query-info-clicked/components/query-info-tab';
import { QueryClicksTab } from './query-clicks-tab';

interface QueryClicksTableProps {
  index?: string;
}

export function QueryClicksTable(props: QueryClicksTableProps) {
  const { query, projectId, metricType }: any = useParams();
  const [translate] = useTranslation();
  const analyticsFilters = useStore((state) => state.analyticsFilters);
  const setCurrentQuery = useStore((state) => state.setCurrentQuery);
  const [queryClickInfo, setQueryClickInfo] = useState<any>('');
  const [tableValues, setTableValues] = useState<any>({});
  const { index } = props;
  function reverseUrl(url: any) {
    return (
      <div dir="ltr" className="flex rtl:justify-end">
        {url}
      </div>
    );
  }
  const queryClicksTableList = [
    {
      name: 'SEARCH',
      tableHead: queryClicksTableHeadData(reverseUrl),
      metric: 'search_query_clicks',
    },
    {
      name: 'AUTOCOMPLETE',
      tableHead: queryClicksTableHeadData(reverseUrl),
      metric: 'autocomplete_query_clicks',
    },
  ];
  const queryClicksLogsTableList = [
    {
      name: 'SEARCH',
      tableHead: queryClicksLogsTableHeadData(translate),
      metric: 'search_click_logs',
    },
    {
      name: 'AUTOCOMPLETE',
      tableHead: queryClicksTableHeadData(translate),
      metric: 'autocomplete_click_logs',
    },
  ];
  useEffect(() => {
    setTableValues(
      queryClicksTableList.find((item) => item.name == metricType.toUpperCase())
    );
  }, []);
  const { data, isFetching } = useSearchAnalytics({
    metrics: [tableValues.metric],
    projectId: projectId,
    index: index,
    query: query,
  });

  const { currentPage, currentRecords, setCurrentPage } = usePaginatedData({
    data: (data as any)?.[tableValues.metric]?.items_info,
  });
  function addIsSelect(item: any) {
    if (item?.item_id == queryClickInfo?.item_id)
      return { ...item, selected: true };
    else return item;
  }
  const tableQueryInfoRows = currentRecords?.map(addIsSelect);
  useEffect(() => {
    setCurrentQuery((data as any)?.[tableValues.metric]?.query);
  }, [data]);
  function viewQueryInfo(item: any) {
    setQueryClickInfo(item);
  }
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-5 mb-5">
        <div className="w-full flex  flex-col ">
          <div className="w-full flex items-center justify-between min-h-[76px] rounded-t-xl bg-white p-3 border-b-4 ">
            <span className="text-[20px] font-bold">
              {translate('QUERY_CLICKS')}
            </span>
          </div>
          <LablebTable
            loading={isFetching}
            tableHeader={tableValues.tableHead}
            tableRows={tableQueryInfoRows}
            viewHandler={viewQueryInfo}
          />
          <div className="flex flex-row-reverse mt-3 w-full">
            <LablebPagination
              currentPage={currentPage}
              displayedCount={currentRecords?.length}
              itemsCount={
                (data as any)?.[tableValues.metric]?.items_info?.length
              }
              onPageChange={setCurrentPage}
            />
          </div>
        </div>
        {queryClickInfo ? (
          <QueryClicksTab
            tableName="QUERY_CLICKS_LOGS"
            queryClicksTableList={queryClicksLogsTableList}
            index={index}
            query={query}
            clickInfo={queryClickInfo}
          />
        ) : null}
      </div>
    </>
  );
}
