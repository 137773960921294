import { LablebAPIClient } from '@lableb/api-client';

const storedToken = localStorage.getItem('token') || '';
function onRequestSuccess(config: any) {
  let url = config.url;
  /**
   * the url from the library is always fixed
   * to change it based on env variable we replace the domain name here
   */
  let newURL = url.replace(
    'https://api.lableb.com',
    process.env.REACT_APP_BASE_URL
  );

  return { ...config, url: newURL };
}

function onRequestError(error: any) {
  //dashboardStore.dispatch(decrementLoadingState());
}

function onResponseError(error: any) {
  /*
        let inCreateProjecPage = window.location.href.includes('/create-project');
    
        //dashboardStore.dispatch(decrementLoadingState());
    
        if (error?.response?.status === 401 && !inCreateProjecPage) {
            // TODO do something if the user is unauthorized
            console.error('Unauthorized..');
            dashboardStore.dispatch(openSnackBar({
                message: error?.response?.data?.response ?? 'You are not authorized',
                severity: 'error'
            }));
        }
     */
}

function onResponseSuccess(response: any) {
  //dashboardStore.dispatch(decrementLoadingState())
  return response;
}

export const lablebClient = LablebAPIClient({
  interceptors: [
    {
      requestInterceptor: {
        onError: onRequestError,
        onRequest: onRequestSuccess,
      },
      responseInterceptor: {
        onError: onResponseError,
        onResponse: onResponseSuccess,
      },
    },
  ],
});

export const lablebAnalyticsClient = LablebAPIClient({
  interceptors: [
    {
      requestInterceptor: {
        onError() {},
        onRequest(config) {
          let url = config.url;
          /**
           * the url from the library is always fixed
           * to change it based on env variable we replace the domain name here
           */
          let newURL = process.env.REACT_APP_ANALYTICS_API
            ? url?.replace(
                'https://api.lableb.com',
                process.env.REACT_APP_ANALYTICS_API
              )
            : url;

          return { ...config, url: newURL };
        },
      },
      responseInterceptor: {
        onError() {},
        onResponse(response) {
          return response;
        },
      },
    },
  ],
});

export const lablebUsageClient = LablebAPIClient({
  interceptors: [
    {
      requestInterceptor: {
        onError() {},
        onRequest(config) {
          let url = config.url;
          /**
           * the url from the library is always fixed
           * to change it based on env variable we replace the domain name here
           */
          let newURL = process.env.REACT_APP_USAGE_API
            ? url?.replace(
                'https://api.lableb.com',
                process.env.REACT_APP_USAGE_API
              )
            : url;

          return { ...config, url: newURL };
        },
      },
      responseInterceptor: {
        onError() {},
        onResponse(response) {
          return response;
        },
      },
    },
  ],
});

lablebClient.setJWTToken(storedToken);
lablebUsageClient.setJWTToken(storedToken);
lablebAnalyticsClient.setJWTToken(storedToken);
export const LABLEB_API_DATE_FORMAT = 'yyyy-MM-DD';
