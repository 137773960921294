import { useTranslation } from 'react-i18next';
import { RegisterLayout } from '../../components/register-layout/register-layout';
import clsx from 'clsx';

export function LoginPageLoader() {
  const [translate] = useTranslation();

  return (
    <>
      <RegisterLayout>
        <div className="flex flex-col">
          <span className="text-[25px] mb-2 font-bold">
            {translate('LOGIN')}
          </span>
          <span className="text-gray700 ">
            {translate('LOGIN_DESCRIPTION')}
          </span>
        </div>
        <div className="w-full flex flex-col lg:my-8 md:my-2 sm:my-2">
          <div className="w-full flex flex-col lg:mb-5 md:mb-3 sm:mb-3">
            <span className="text-[14px] mb-2">{translate('EMAIL')}</span>
            <input
              className="rounded-xl py-2 px-5 border-[1px] h-[56px] border-[#c7c7c7] outline-none"
              readOnly
            />
          </div>

          <div className="w-full flex flex-col mb-3">
            <span className="text-[14px] mb-2">{translate('PASSWORD')}</span>

            <div className="relative w-full">
              <input
                id="password-input"
                className="w-full rounded-xl py-2 px-5 border-[1px] h-[56px] border-[#c7c7c7] outline-none"
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col ">
          <button
            type="submit"
            className=" rounded-xl py-3 h-[56px]  flex justify-center items-center bg-primary"
          >
            <span className="text-[16px] font-bold text-white mx-7">
              {translate('LOGIN')}
            </span>
          </button>
        </div>
      </RegisterLayout>
    </>
  );
}
