import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSearchAnalytics } from '../../../../../../hooks/analytics/search-analytics.hook';
import { LablebTable } from '../../../../../../design-system/table/table';
import { LablebPagination } from '../../../../../../design-system/pagination/pagination';

interface QueryClicksTabProps {
  queryClicksTableList: any;
  tableName: string;

  index?: string;
  query?: string;
  clickInfo: any;
}
export function QueryClicksTab(props: QueryClicksTabProps) {
  const { projectId, metricType }: any = useParams();
  const [translate] = useTranslation();
  const [tableValues, setTableValues] = useState<any>({});
  const {
    queryClicksTableList,
    tableName,

    index,
    query,
    clickInfo,
  } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setTableValues(
      queryClicksTableList.find(
        (item: any) => item.name == metricType.toUpperCase()
      )
    );
  }, []);

  const { data, isFetching } = useSearchAnalytics({
    metrics: [tableValues?.metric],
    projectId: projectId,
    index: index,
    itemId: clickInfo?.item_id,
    query: query,
    page: currentPage - 1,
    limit: 20,
  });
  function reverseUrl(url: any) {
    return (
      <div dir="ltr" className="flex rtl:justify-end">
        {url}
      </div>
    );
  }
  return (
    <>
      <div className="w-full flex  flex-col ">
        <div className="w-full flex gap-4 flex-col min-h-[76px] rounded-t-xl bg-white p-3 border-b-4 ">
          <span className="text-[20px] font-bold">{translate(tableName)}</span>
          <div className="flex items-center gap-3">
            <span className="text-[14px] font-bold">
              {translate('CLICK_QUERY')}:
            </span>
            <span className="text-[14px] text-primary truncate font-semibold">
              {reverseUrl(decodeURI(clickInfo?.url))}
            </span>
          </div>
        </div>
        <LablebTable
          loading={isFetching}
          tableHeader={tableValues.tableHead}
          tableRows={(data as any)?.[tableValues.metric]?.logs}
        />
        <div className="flex flex-row-reverse mt-3 w-full">
          <LablebPagination
            currentPage={currentPage}
            displayedCount={(data as any)?.[tableValues.metric]?.logs?.length}
            itemsCount={(data as any)?.[tableValues.metric]?.count}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
}
