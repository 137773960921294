import { useTranslation } from 'react-i18next';
import { FilterDatePicker } from '../../design-system/filter-date-picker/filter-date-picker';
import moment from 'moment';
import { useState } from 'react';
import { AnalyticsCard } from '../../design-system/analytics-card/analytics-card';
import totalRequestIcon from '../../assets/icons/total-request-icon.svg';
import searchAnalyticsIcon from '../../assets/icons/search-analytics-icon.svg';
import autocompleteIcon from '../../assets/icons/autocomplete-icon.svg';
import recommendOverviewIcon from '../../assets/icons/recommend-overview-icon.svg';
import { LablebPopover } from '../../design-system/popover/popover';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { LablebLineChart } from '../../design-system/line-chart/line-chart';
import { LablebCountUp } from '../../design-system/count-up/count-up';
import { LablebCircularProgress } from '../../design-system/circular-progress/circular-progress';
import { AnalyticsTable } from '../../design-system/analytics-table/analytics-table';
import { useParams } from 'react-router-dom';
import { RequestsFrequency } from '../analytics/search-analytics/components/requests-frequency';
export function ProjectOverviewPageLoader() {
  const { projectId }: any = useParams();

  const [translate, { language }] = useTranslation();

  const overviewData = [
    {
      title: 'TOTAL_REQUESTS',
      count: 0,
      icon: totalRequestIcon,
    },
    {
      title: 'SEARCH_REQUESTS',
      count: 0,
      icon: searchAnalyticsIcon,
    },
    {
      title: 'AUTOCOMPLETE',
      count: 0,
      icon: autocompleteIcon,
    },
    {
      title: 'RECOMMENDATIONS',
      count: 0,
      icon: recommendOverviewIcon,
    },
  ];

  const MostFrequentTableList = [
    {
      name: 'SEARCH',
      path: `/projects/${projectId}/search-analytics/search`,
      tableHead: [],
      metric: 'frequent_search_custom_range',
    },
    {
      name: 'AUTOCOMPLETE',
      path: `/projects/${projectId}/search-analytics/search`,
      tableHead: [],
      metric: 'frequent_autocomplete_custom_range',
    },
  ];

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="flex w-full items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">
            {translate('CONTROL_PANEL')}
          </span>
          <FilterDatePicker />
        </div>
        <div className="w-full mb-5 grid gap-2 grid-cols-cards ">
          {overviewData?.map((item) => (
            <div key={item?.title}>
              <AnalyticsCard
                title={item?.title}
                value={item?.count}
                cardIcon={item?.icon}
                loading
              />
            </div>
          ))}
        </div>

        <div className="w-full flex  justify-between gap-5 mb-3 ">
          <LablebCircularProgress
            title={'REQUESTS'}
            value={0}
            total={0}
            strokeWidth={20}
            sqSize={220}
            percentage={0}
            language={language}
            needUpdatePlan={false}
            isUnlimited={false}
            loading
          />
          <LablebCircularProgress
            title={'DOCUMENTS'}
            value={0}
            total={0}
            strokeWidth={20}
            sqSize={220}
            percentage={0}
            language={language}
            needUpdatePlan={false}
            isUnlimited={false}
            loading
          />
        </div>

        <RequestsFrequency />

        <div className="my-3">
          <AnalyticsTable
            tableName={'MOST_FREQUENT_SEARCH'}
            analyticsTableList={[MostFrequentTableList]}
          />
        </div>
      </div>
    </>
  );
}
