import { LablebPopover } from '../../popover/popover';
import dotsIcon from '../../../assets/icons/dots.svg';
import clsx from 'clsx';

interface TablePermissionsMenu {
  permissionsList: any;
  row: any;
  rowIndex: any;
  disable?: boolean;
}

export function TablePermissionsMenu(props: TablePermissionsMenu) {
  const { permissionsList, row, rowIndex, disable } = props;

  return (
    <>
      <LablebPopover
        popoverButton={
          <img
            src={dotsIcon}
            className={clsx('max-w-[25px] ', {
              'cursor-not-allowed': disable,
            })}
            alt="edit"
            onClick={(event) => (disable ? event.preventDefault() : null)}
          />
        }
      >
        {permissionsList.map((item: any) => (
          <div
            key={item.name}
            onClick={() => item?.onClick(row, rowIndex)}
            className="flex items-center justify-between cursor-pointer p-2 hover:bg-[#c5d7ff] rounded-md min-w-[100px]"
          >
            <img src={item.icon} className="max-w-[25px]" alt={item.alt} />

            <p className="text-sm font-medium text-gray-900">{item.name}</p>
          </div>
        ))}
      </LablebPopover>
    </>
  );
}
