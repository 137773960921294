export const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  dotsClass: 'slick-dots slick-thumb',
  slidesToScroll: 1,
  rtl: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 650,
      settings: {
        dots: false,
      },
    },
  ],
};
