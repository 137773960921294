import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { lablebClient } from '../../api-client';
import useStore from '../../store/store';

export function useCancelSubscription(projectId: string) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const closeWarningDialogHandler = useStore(
    (state) => state?.closeWarningDialogHandler
  );
  const setSnackbarInfo = useStore((state) => state?.setSnackbarInfo);

  return useMutation(
    ['cancel-subscription', projectId],

    function cancelSub() {
      return lablebClient.billing.subscription.cancelSubscription({
        platformId: projectId,
      });
    },
    {
      onSuccess() {
        setSnackbarInfo({
          open: true,
          message: 'SUBSCRIPRION_CANELLED_SCCESS',
          severity: 'success',
        });

        closeWarningDialogHandler();
        queryClient.invalidateQueries(['projects']);

        queryClient.invalidateQueries(['project-roles', projectId]);

        queryClient.invalidateQueries(['project', projectId]);

        navigate(`/projects/${projectId}/overview`);
      },
      onError() {
        closeWarningDialogHandler();
        setSnackbarInfo({
          open: true,
          message: 'CANCEL_SUBSCRIPTION_ERROR',
          severity: 'error',
        });
      },
    }
  );
}
