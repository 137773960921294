import { useTranslation } from 'react-i18next';
import { LablebSkeleton } from '../skeleton/skeleton';
import { LablebCountUp } from '../count-up/count-up';

interface LablebCircularProgressProps {
  strokeWidth: number;
  sqSize: number;
  percentage: number;
  language: string;
  needUpdatePlan: boolean;
  isUnlimited: boolean;
  loading?: boolean;
  total: number;
  value: number | undefined;
  title: string;
}

export function LablebCircularProgress(props: LablebCircularProgressProps) {
  let {
    sqSize,
    strokeWidth,
    percentage,
    language,
    needUpdatePlan,
    isUnlimited,
    loading,
    total,
    value,
    title,
  } = props;

  let [translate] = useTranslation();

  if (isUnlimited) {
    percentage = 100;
  }
  const radius = (sqSize - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dashOffset = circumference - (circumference * percentage) / 100;

  // if (loading) {
  //   return (
  //     <LablebSkeleton
  //       classStyle={{ borderRadius: '100%' }}
  //       style="h-[240px] w-[240px]"
  //     />
  //   );
  // }

  return (
    <>
      <div className="flex flex-col  p-4 bg-white  rounded w-full">
        <span className="text-xl font-bold text-[#030F2C]">
          {translate(title)}
        </span>

        <div className="flex items-center rtl:justify-end rtl:flex-row-reverse">
          <span className="text-sm font-bold text-[#35405A]">
            {<LablebCountUp loading={loading} value={value ?? 0} />}
          </span>
          {'/'}
          <span className="text-sm font-bold text-[#35405A]">
            {!isUnlimited ? (
              <LablebCountUp loading={loading} value={total ?? 0} />
            ) : (
              translate('UNLIMITED')
            )}
          </span>
        </div>

        <div className="flex justify-center items-start w-full">
          {loading ? (
            <LablebSkeleton
              classStyle={{ borderRadius: '100%' }}
              style="h-[240px] w-[240px]"
            />
          ) : (
            <svg width={sqSize} height={sqSize}>
              <circle
                cx={sqSize / 2}
                cy={sqSize / 2}
                r={radius}
                stroke="#ddd"
                strokeWidth={`${strokeWidth}px`}
                fill="none"
              />

              <circle
                style={{
                  stroke: needUpdatePlan || isUnlimited ? '#F6A609' : '#40A8E2',
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeDasharray: circumference,
                  strokeDashoffset: dashOffset,
                  fill: 'none',
                }}
                cx={sqSize / 2}
                cy={sqSize / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`}
                transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
              />
              <text
                className="circle-text"
                x="50%"
                y="50%"
                dy=".3em"
                style={{
                  fontSize: '36px',
                  fontWeight: 'bold',
                  fill: '#000',
                }}
                textAnchor="middle"
              >
                {`${
                  Number.isInteger(percentage)
                    ? percentage
                    : percentage.toFixed(2)
                }%`}
              </text>

              <text
                x={language === 'ar' ? '63%' : '38%'}
                y="65%"
                dy=".3em"
                style={{
                  fontSize: '16px',
                  fill: '#000',
                }}
              >
                {isUnlimited ? translate('UNLIMITED') : translate('USED')}
              </text>
            </svg>
          )}
        </div>
      </div>
    </>
  );
}
