import { useTranslation } from 'react-i18next';
import circleCheckIcon from '../../../assets/icons/circle-check.svg';
import circleCheckWhiteIcon from '../../../assets/icons/circle-check-white.svg';
import { LablebSkeleton } from '../../../design-system/skeleton/skeleton';
import { transformNumberWithCommas } from '../../../utils/functions/numbers-utils';

interface PlanCardProps {
  planInformation: any;
  currentPlanId: any;
  onSelect: (plan: any) => void;
  needSubscription: boolean;
  loading?: boolean;
}

export function PlanCard(props: PlanCardProps) {
  const {
    planInformation,
    currentPlanId,
    onSelect,
    needSubscription,
    loading,
  } = props;
  const [translate] = useTranslation();

  if (loading) {
    return (
      <div className="w-[274px] h-[471px] p-3 mb-3 justify-between bg-white rounded-2xl flex flex-col">
        <LablebSkeleton style="w-[90%] h-5" />
        <LablebSkeleton style="w-[70%] h-5" />
        <LablebSkeleton style="w-[60%] h-5" />
        <LablebSkeleton style="w-[100%] h-5" />
        <LablebSkeleton style="w-[80%] h-5" />
        <LablebSkeleton style="w-[40%] h-5" />
        <LablebSkeleton style="w-[100%] h-5" />
        <LablebSkeleton style="w-[80%] h-5" />
        <LablebSkeleton style="w-[40%] h-5" />
        <LablebSkeleton style="w-[100%] h-5" />
      </div>
    );
  }

  return (
    <>
      <div className="max-w-[274px] h-[471px] p-3 mb-3 bg-white rounded-2xl flex flex-col">
        <span className="text-xl text-[#030F2C] font-bold">
          {translate(planInformation?.name)}
        </span>
        <div className="flex items-end my-4">
          <span className="text-[34px]  text-[#030F2C]">
            {planInformation?.price}
          </span>
          <span className="text-[14px] mx-1 mb-2 text-[#9BA6C0] ">
            {translate('MONTHLY')}
          </span>
        </div>
        <span className="text-[16px] text-[#6D7B9B]">
          {translate(planInformation?.description)}
        </span>
        <div className="flex flex-col justify-between flex-grow">
          <div className="my-4 flex flex-col justify-between flex-grow">
            {planInformation?.features.map((feature: any, index: number) => (
              <div
                key={index}
                className="w-full flex justify-between items-center"
              >
                <div className="flex items-center">
                  <img
                    src={circleCheckIcon}
                    className="w-[16px] h-[16px]"
                    alt={'check-icon'}
                  />
                  <span className="text-[16px] mx-3 text-[#6D7B9B]">
                    {translate(`${feature.name}`)}
                  </span>
                </div>
                <span className="text-[16px] font-medium text-[#35405A]">
                  {typeof feature?.value === 'number'
                    ? transformNumberWithCommas(feature?.value)
                    : feature?.value}{' '}
                  {feature?.name === 'ANALYITC_INTERVAL' ? (
                    <span>{translate('DAY')}</span>
                  ) : null}
                </span>
              </div>
            ))}
          </div>

          {currentPlanId === planInformation?.id ? (
            needSubscription ? (
              <button
                onClick={() => onSelect(planInformation)}
                className="outline-none bg-[#EBEFF1]  text-[#345C75] text-[16px] font-bold rounded-xl  py-3"
              >
                {translate('SELECT_PLAN')}
              </button>
            ) : (
              <div className="w-full flex justify-center rounded-xl bg-[#1F3746] items-center py-3">
                <img
                  src={circleCheckWhiteIcon}
                  alt={'check-icon'}
                  className="w-[24px] h-[24px]"
                />
                <span className="text-[16px] text-white font-bold mx-3">
                  {translate('CURRENT_PLAN')}
                </span>
              </div>
            )
          ) : (
            <button
              onClick={() => onSelect(planInformation)}
              className="outline-none bg-[#EBEFF1]  text-[#345C75] text-[16px] font-bold rounded-xl  py-3"
            >
              {translate('SELECT_PLAN')}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
