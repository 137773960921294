import { useTranslation } from 'react-i18next';
import { LablebSkeleton } from '../../skeleton/skeleton';
import { LablebCountUp } from '../../count-up/count-up';

interface CardLinearProgressProps {
  total: number;
  value: number;
  percentageValue: number;
  color: string;
  loading?: boolean;
}

export function CardLinearProgress(props: CardLinearProgressProps) {
  const { total, value, percentageValue, color, loading } = props;

  const [translate] = useTranslation();
  return (
    <>
      <div className="flex flex-col w-full">
        <div className="w-full flex mb-2 items-center justify-between">
          {loading ? (
            <LablebSkeleton style="w-14 h-5" />
          ) : (
            <span className="text-[16px] text-[#030F2C]">
              {`${
                Number.isInteger(percentageValue)
                  ? percentageValue
                  : percentageValue.toFixed(2)
              }%`}
            </span>
          )}

          <div className="flex flex-row items-center rtl:flex-row-reverse">
            <span className="text-[16px] text-[#6D7B9B]">
              {loading ? (
                <LablebSkeleton style="w-14 h-5 " />
              ) : (
                <LablebCountUp value={value} />
              )}
            </span>
            <span className="text-[16px] text-[#6D7B9B] mx-1">{'/'}</span>
            <span className="text-[16px] text-[#6D7B9B]">
              {loading ? (
                <LablebSkeleton style="w-14 h-5 " />
              ) : (
                <LablebCountUp value={total} />
              )}
            </span>
          </div>
        </div>

        <div className="w-[full] bg-[#F5F8FF] rounded-[10px] h-[8px]">
          <div
            className="h-full rounded-[10px] transition-all duration-500"
            style={{
              width: `${percentageValue}%`,
              backgroundColor: `${color}`,
            }}
          ></div>
        </div>
      </div>
    </>
  );
}
