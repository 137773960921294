import {
  AnalyticsMetrics,
  DailyRecommendAnalyticsMetrics,
  RecommendAnalyticsMetrics,
} from '@lableb/api-client';

export const analyticsKeys = {
  searchDailyAnalytics: (params: { date: string; projectId: string }) =>
    ['daily-search-analytics', params] as const,

  searchAnalytics: (params: {
    projectId: string;
    from: string;
    to: string;
    metrics: Array<(typeof AnalyticsMetrics)[number]>;
    index?: string;
    page?: number;
    query?: string;
    itemId?: string;
    platform?: string;
  }) =>
    [
      'search-analytics',
      params.projectId,
      params.metrics.join(','),
      params.from,
      params.to,
      params.index,
      params.page,
      params.query,
      params.itemId,
      params.platform,
    ] as const,

  recommendDailyAnalytics: (params: {
    date: string;
    projectId: string;
    metrics: Array<(typeof DailyRecommendAnalyticsMetrics)[number]>;
  }) => ['daily-recommend-analytics', params] as const,

  recommendAnalytics: (params: {
    projectId: string;
    from: string;
    to: string;
    metrics: Array<(typeof RecommendAnalyticsMetrics)[number]>;
    index?: string;
    platform?: string;
  }) => ['recommend-analytics', params] as const,
};
