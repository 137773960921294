import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Tab } from '@headlessui/react';
import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

interface LablebNestedMenuProps {
  title: string;
  icon: string;
  path: string;
  links: any;
  isSideNavOpen: boolean;
}

export function LablebNestedMenu(props: LablebNestedMenuProps) {
  const { title, icon, path, links, isSideNavOpen } = props;

  const [open, setOpen] = useState<boolean>(false);

  function handleOpenClick() {
    setOpen((open) => !open);
  }

  return (
    <>
      <NavLink id="RouterNavLink" to={`${path}`}>
        {({ isActive }) => (
          <div
            onClick={handleOpenClick}
            className={clsx(
              'flex items-center justify-between px-3 py-3 rounded-xl cursor-pointer ',
              {
                'bg-primary': isActive,
              }
            )}
          >
            <div className="flex items-center">
              <img src={icon} className="max-w-[25px]" alt="title-icon" />
              {isSideNavOpen === false ? (
                <span
                  className={clsx(
                    'text-l mx-3 whitespace-nowrap text-primary',
                    {
                      'text-slate-100': isActive,
                    }
                  )}
                >
                  {title}
                </span>
              ) : null}
            </div>
            {isSideNavOpen === false ? (
              <ChevronDownIcon
                className={clsx('ml-2 -mr-1 h-5 w-5 text-gray700', {
                  'rotate-180': open,
                  'text-gray400': isActive,
                })}
                aria-hidden="true"
              />
            ) : null}
          </div>
        )}
      </NavLink>
      {open && isSideNavOpen === false ? (
        <div className="my-3">
          <Tab.Group vertical>
            <Tab.List className="flex flex-col">
              {links?.map((link: any) => (
                <NavLink key={link.name} id="RouterNavLink" to={`${link.path}`}>
                  {({ isActive }) =>
                    !link.hide ? (
                      <Tab
                        key={link.name}
                        className="relative w-[60%] whitespace-nowrap outline-none py-2 px-4 text-start"
                      >
                        <>
                          <div
                            className={clsx(
                              'absolute rounded h-[100%] w-[2px]  top-0 -translate-x-1 ltr:translate-x-1',
                              {
                                'bg-primary': isActive,
                                'bg-slate-300': !isActive,
                              }
                            )}
                          />

                          <span
                            className={clsx('mx-5', {
                              ' text-primary text-l font-bold': isActive,
                              'text-gray700 text-l': !isActive,
                            })}
                          >
                            {link.name}
                          </span>
                        </>
                      </Tab>
                    ) : null
                  }
                </NavLink>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>
      ) : null}
    </>
  );
}
