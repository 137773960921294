import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LablebSelect } from '../../../../../../design-system/select/select';
import { LablebTable } from '../../../../../../design-system/table/table';
import { useSearchAnalytics } from '../../../../../../hooks/analytics/search-analytics.hook';
import { LablebPagination } from '../../../../../../design-system/pagination/pagination';
import useStore from '../../../../../../store/store';
interface QueryInfoTableProps {
  queryInfoTableList: any;
  tableName: string;

  index?: string;
}
export function QueryInfoTable(props: QueryInfoTableProps) {
  const { query, projectId, metricType }: any = useParams();
  const [translate] = useTranslation();
  const [tableValues, setTableValues] = useState<any>({});
  const { queryInfoTableList, tableName, index } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const analyticsFilters = useStore((state) => state.analyticsFilters);

  useEffect(() => {
    setTableValues(
      queryInfoTableList.find(
        (item: any) => item.name == metricType.toUpperCase()
      )
    );
  }, []);

  const { data, isFetching } = useSearchAnalytics({
    metrics: [tableValues.metric],
    projectId: projectId,
    index: index,
    query: query,
    page: currentPage - 1,
    limit: 20,
  });

  return (
    <>
      <div className="w-full flex  flex-col ">
        <div className="w-full flex items-center justify-between min-h-[76px] rounded-t-xl bg-white p-3 border-b-4 ">
          <span className="text-[20px] font-bold">{translate(tableName)}</span>
        </div>
        <LablebTable
          loading={isFetching}
          tableHeader={tableValues.tableHead}
          tableRows={(data as any)?.[tableValues.metric]?.logs}
        />
        <div className="flex flex-row-reverse mt-3 w-full">
          <LablebPagination
            currentPage={currentPage}
            displayedCount={(data as any)?.[tableValues.metric]?.logs?.length}
            itemsCount={(data as any)?.[tableValues.metric]?.count}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
}
