import { fieldTypeArray } from '../schema/schema.page.constants';

export function suggetsRulesTableHeadData(
  translate: any,
  fieldTypeStyle: any,
  suggestionRulesCount?: any
) {
  return [
    {
      title: `${translate('FIELD')}`,
      key: 'name',
    },
    {
      title: `${translate('FIELD_TYPE')}`,
      key: (row: any) => {
        return (
          fieldTypeStyle(
            fieldTypeArray.find(
              (option: any) => option?.fieldType === row?.field_type
            )
          ) ?? ''
        );
      },
    },
    {
      title: `${translate('Filter')}`,
      key: (row: any) => {
        return row?.suggest_rules?.has_filter;
      },
    },
    {
      title: `${translate('Navigational')}`,
      key: (row: any) => {
        return row?.suggest_rules?.has_navigational;
      },
    },

    // {
    //     title: `${translate('اقتراحات الترشيح')}`,
    //     key: (row: any) => {
    //         return suggestionRulesCount(row,'filter')
    //     }
    // }
  ];
}
