import React from 'react';
import { RegisterLayout } from '../../../components/register-layout/register-layout';

export function SignupStopedPage() {
  return (
    <RegisterLayout>
      <div className="shadow-xl rounded-md w-full h-[500px] flex  items-center">
        <span className="text-[27px] text-center text-black">
          {
            'أعزائنا التجار، نود أن نعلمكم أن تطبيق لبلب ضمن منصة إكسباند كارت، تم توقيفه حالياً.'
          }
        </span>
      </div>
    </RegisterLayout>
  );
}
