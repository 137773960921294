import { useTranslation } from 'react-i18next';
import { LablebSelect } from '../select/select';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { LablebTable } from '../table/table';
import useStore from '../../store/store';
import { useSearchAnalytics } from '../../hooks/analytics/search-analytics.hook';

interface AnalyticsTableProps {
  analyticsTableList: any;
  tableName: string;
  fromDate?: any;
  toDate?: any;
  index?: string;
  viewHandler?: any;
}

export function AnalyticsTable(props: AnalyticsTableProps) {
  const { projectId }: any = useParams();
  const {
    analyticsTableList,
    tableName,
    toDate,
    fromDate,
    index,
    viewHandler,
  } = props;

  const [tableValues, setTableValues] = useState<any>({});
  const currentLanguage = useStore((state) => state.currentLanguage);
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const analyticsFilters = useStore((state) => state.analyticsFilters);
  const setAnalyticsFilters = useStore((state) => state.setAnalyticsFilters);

  useEffect(() => {
    setTableValues(analyticsTableList[0]);
  }, []);

  const { data, isFetching } = useSearchAnalytics({
    metrics: [tableValues.metric],
    projectId: projectId,
    index: index,
  });

  function tableValuesSelectHandler(event: any) {
    analyticsTableList.map((item: any) => {
      if (item.name === event) {
        setTableValues({
          name: item.name,
          path: item.path,
          tableHead: item.tableHead,
          metric: item.metric,
        });
        setAnalyticsFilters({
          ...analyticsFilters,
          metricType: item.name,
        });
      }
    });
  }

  return (
    <>
      <div className="w-full flex  flex-col ">
        <div className="w-full flex items-center justify-between min-h-[76px] rounded-t-xl bg-white p-3 border-b-4 ">
          <span className="text-[20px] font-bold">{translate(tableName)}</span>

          <div className="flex flex-col md:flex-row items-center gap-3 cursor-pointer">
            <div className="w-[200px] mx-3">
              <LablebSelect
                selectedItem={tableValues.name}
                onItemChange={tableValuesSelectHandler}
                itemsList={['SEARCH', 'AUTOCOMPLETE']}
              />
            </div>
            <div className="flex items-center">
              <span
                onClick={() => {
                  navigate(tableValues.path),
                    setAnalyticsFilters({
                      ...analyticsFilters,
                      metricType: tableValues.name,
                    });
                }}
                className="text-[16px] mx-3 text-[#6D7B9B]"
              >
                {translate('VIEW_MORE')}
              </span>
              <ChevronLeftIcon
                className={clsx('ml-2 -mr-1 h-5 w-5 text-[#6D7B9B]', {
                  'rotate-180': currentLanguage === 'en',
                })}
              />
            </div>
          </div>
        </div>
        <LablebTable
          viewHandler={
            viewHandler
              ? (row: any) => {
                  viewHandler(row);
                }
              : null
          }
          loading={isFetching}
          tableHeader={tableValues.tableHead}
          tableRows={(data as any)?.[tableValues.metric]?.slice(0, 7)}
        />
      </div>
    </>
  );
}
