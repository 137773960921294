import { useQueryClient, useMutation } from 'react-query';
import { lablebClient } from '../../api-client';
import { schemaKeys } from './fields.keys';
import useStore from '../../store/store';

interface UseCopyFieldDeleteParams {
  projectId: string;
  indexId: string;
  copyFieldName: string;
}

export function useCopyFieldDelete(params: UseCopyFieldDeleteParams) {
  const {
    projectId,
    // indexId,
    // copyFieldName
  } = params;
  const closeWarningDialogHandler = useStore(
    (state) => state?.closeWarningDialogHandler
  );
  const queryClient = useQueryClient();
  const setSnackbarInfo = useStore((state) => state?.setSnackbarInfo);
  return useMutation(
    schemaKeys.copyFieldDetails(params),

    function deleteCopyField(
      params: Omit<
        Parameters<
          typeof lablebClient.schema.copyFields.deleteCopyFieldFromSchema
        >[0],
        'platformId'
      >
    ) {
      return lablebClient.schema.copyFields.deleteCopyFieldFromSchema({
        platformId: projectId,
        ...params,
      });
    },

    {
      onSuccess() {
        closeWarningDialogHandler();
        setSnackbarInfo({
          open: true,
          message: 'COPY_FIELD_DELETE_SUCCESS',
          severity: 'success',
        });
        queryClient.invalidateQueries(schemaKeys.all(params));
      },
    }
  );
}
