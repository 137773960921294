import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDatePicker } from '../../../../design-system/filter-date-picker/filter-date-picker';
import searchIcon from '../../../../assets/icons/search.svg';
import { LablebTable } from '../../../../design-system/table/table';
import { mostVistiedPagesTableHeadData } from '../recommendation-analytics.page.constants';

export function MostVisitedPageLoader() {
  const [translate] = useTranslation();

  return (
    <>
      <div className="w-full flex flex-col justify-between">
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center mb-5 justify-between">
            <div className="flex items-center">
              <span className="text-[#6D7B9B] text-[24px]">
                {translate('RECOMMENDATION_ANALYTICS')}
              </span>
              <hr className="w-[2px] h-[20px] bg-[#6D7B9B] border-none rounded-sm mx-2" />
              <span className="text-[#030F2C] text-[24px]">
                {translate('MOST_VISTIED_PAGES')}
              </span>
            </div>

            <div className="flex items-center">
              <FilterDatePicker />

              <div className="relative  mx-3 w-[180px]">
                <button className="absolute top-[20%] mx-2">
                  <img src={searchIcon} className="max-w-[22px]" alt="search" />
                </button>

                <input
                  className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                  placeholder={translate('SEARCH_PLACEHOLDER')}
                  readOnly
                />
              </div>

              {/* <div className="flex p-2 cursor-pointer rounded-xl  bg-primary items-center justify-center border-[1px] border-[#c7c7c7]">
                <img src={downloadIcon} className="max-w-[25px]" alt="filter" />
              </div> */}
            </div>
          </div>

          <div className="mb-3">
            <LablebTable
              loading
              tableHeader={mostVistiedPagesTableHeadData(translate)}
              tableRows={[]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
