import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useStore from '../../../store/store';
import { useProject } from '../../../hooks/projects/project.hook';
import { useCancelSubscription } from '../../../hooks/billing/cancel-subscription.hook';
import checkIcon from '../../../assets/icons/check-icon.svg';
import { LablebSkeleton } from '../../../design-system/skeleton/skeleton';
import { useState, useEffect } from 'react';
import { lablebClient } from '../../../api-client';
import { transformNumberWithCommas } from '../../../utils/functions/numbers-utils';
interface CurrentPlanCardProps {
  plan: any;
  loading?: boolean;
}

export function CurrentPlanCard(props: CurrentPlanCardProps) {
  const { projectId }: any = useParams();

  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [paymentURL, setPaymentURL] = useState('');

  const { plan, loading } = props;
  const setWarningDialogHandler = useStore(
    (state) => state.setWarningDialogHandler
  );
  const { data: project } = useProject(projectId);
  const isFreeTrial = project?.subscription?.is_free_trial ?? false;

  const { mutate: cancelProjectSubscription } =
    useCancelSubscription(projectId);

  useEffect(() => {
    if (project?.subscription?.invoice?.status == 'open' && !paymentURL) {
      lablebClient.billing.paymentMethod
        .fetchPaymentURLForUncollectiblePlatform({
          platformId: projectId,
          invoiceId: String(project.subscription.invoice.id),
        })
        .then(({ response: { paymentPageUrl } }) => {
          setPaymentURL(paymentPageUrl);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [project]);

  function cancelSubscription() {
    if (project?.subscription?.invoice?.status == 'open') {
      setWarningDialogHandler({
        open: true,
        title: 'CANNOT_CANCEL_SUBSCRIBTION_TITLE',
        body: (
          <>
            {translate(`CANNOT_CANCEL_SUBSCRIBTION_DESCRIPTION`)}
            {paymentURL ? (
              <>
                <span>{`, ${translate('PLEASE_VISIT')} `}</span>
                <a
                  className="underline font-bold mx-1"
                  href={paymentURL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {translate('STRIPE_PAYMENT_PAGE')}
                </a>
                <span>{translate('STRIPE_PAYMENT_PAGE_DETAILS')}</span>
              </>
            ) : null}
          </>
        ),
        onAcceptDispatch: {},
        onCancelDispatch: {},
        hideAcceptButton: true,
      });
    } else {
      setWarningDialogHandler({
        open: true,
        title: 'CANCEL_SUBSCRIBTION_WARNING_TITLE',
        body: 'CANCEL_SUBSCRIBTION_WARNING_DESCRIPTION',
        onAcceptDispatch: () => cancelProjectSubscription(),
        onCancelDispatch: {},
      });
    }
  }

  function changePlanHandler() {
    if (project?.subscription?.invoice?.status == 'open') {
      setWarningDialogHandler({
        open: true,
        title: 'CANNOT_CANCEL_SUBSCRIBTION_TITLE',
        body: (
          <>
            {translate(`CANNOT_CANCEL_SUBSCRIBTION_DESCRIPTION`)}
            {paymentURL ? (
              <>
                <span>{`, ${translate('PLEASE_VISIT')} `}</span>
                <a
                  className="underline font-bold mx-1"
                  href={paymentURL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {translate('STRIPE_PAYMENT_PAGE')}
                </a>
                <span>{translate('STRIPE_PAYMENT_PAGE_DETAILS')}</span>
              </>
            ) : null}
          </>
        ),
        onAcceptDispatch: {},
        onCancelDispatch: {},
        hideAcceptButton: true,
      });
    } else {
      navigate(`/projects/${projectId}/billing/payment-plans`);
    }
  }

  if (loading) {
    return (
      <div className="w-full bg-white p-4 justify-between min-h-[306px] mb-5 rounded-[12px] flex flex-col">
        <LablebSkeleton style="w-[90%] h-5" />
        <LablebSkeleton style="w-[70%] h-5" />
        <LablebSkeleton style="w-[60%] h-5" />
        <LablebSkeleton style="w-[100%] h-5" />
        <LablebSkeleton style="w-[80%] h-5" />
        <LablebSkeleton style="w-[40%] h-5" />
      </div>
    );
  }

  return (
    <>
      <div className="w-full bg-white p-4 h-[306px] justify-between mb-5 rounded-[12px] flex flex-col">
        <div className="w-full flex mb-4 justify-between items-center">
          <div className="flex flex-col">
            <span className="text-[20px] mb-3 text-[#030F2C]">
              {translate(`${plan?.name}`)}
            </span>
            <span className="text-[16px]  text-[#6D7B9B]">
              {translate(`${plan?.description}`)}
            </span>
          </div>
          <span className="text-[34px] text-[#030F2C]">{plan?.price}</span>
        </div>
        <div className="flex flex-col h-full justify-between">
          {plan?.features.map((feature: any) => (
            <div
              key={feature.name}
              className="w-full flex justify-between items-center"
            >
              <div className="flex items-center">
                <img src={checkIcon} alt={'check-icon'} />
                <span className="text-[16px] mx-3 text-[#6D7B9B]">
                  {translate(`${feature.name}`)}
                </span>
              </div>
              <span className="text-[16px] font-medium text-[#35405A]">
                {typeof feature?.value === 'number'
                  ? transformNumberWithCommas(feature?.value)
                  : translate(`${feature?.value}`)}{' '}
                {feature?.name === 'ANALYITC_INTERVAL' ? (
                  <span>{translate('DAY')}</span>
                ) : null}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex items-center mb-5 justify-between">
        <button
          onClick={changePlanHandler}
          className="w-[49%] outline-none bg-[#345C75] text-white text-[16px] font-bold rounded-xl  py-3"
        >
          {translate(isFreeTrial ? 'CHOOSE_PLAN' : 'CHANGE_PLAN')}
        </button>
        <button
          onClick={cancelSubscription}
          className="w-[49%] outline-none bg-[#f6e7ed]  text-[#FB4E4E] text-[16px] font-bold rounded-xl  py-3"
        >
          {translate('CANCEL_SUBSCRIPTION')}
        </button>
      </div>
    </>
  );
}
