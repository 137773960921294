export const contentList = [
  {
    title: 'INTEGRATED_SEARCH_EXPERIENCE',
    content: 'INTEGRATED_SEARCH_DESCRIPTION',
  },
  {
    title: 'FILTER_SEARCH_RESULTS',
    content: 'FILTER_RESULTS_DESCRIPTION',
  },
  {
    title: 'ANALYTICS',
    content: 'ANALYTICS_DESCRIPTION',
  },
];
