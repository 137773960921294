import { useParams } from 'react-router-dom';
import { useProjectIndices } from '../../../../hooks/indices/indices.hook';
import { LablebSelect } from '../../../../design-system/select/select';
import indexIcon from '../../../../assets/icons/index-icon.svg';
import useStore from '../../../../store/store';
import { useEffect } from 'react';
let defaultIndexsList = ['production', 'index', 'default', 'master'];

export function AnalyticsIndexFilter() {
  const currentIndex = useStore((state) => state.analyticsIndexFilter);
  const setCurrentIndex = useStore((state) => state.setAnalyticsIndexFilter);
  const { projectId }: any = useParams();
  const analyticsFilters = useStore((state) => state.analyticsFilters);
  const { data: indicesResponse, isLoading } = useProjectIndices({
    projectId: projectId,
    enable: true,
  });
  let indexList = indicesResponse
    ?.map((indice) => indice.name)
    .filter((indice) => indice)
    .concat('ALL_INDEXS');

  useEffect(() => {
    if (!analyticsFilters.index) {
      indexList?.forEach((element) => {
        if (
          defaultIndexsList?.findIndex(
            (collection) => collection === element
          ) != -1
        ) {
          setCurrentIndex(element);
        }
      });
    }
  }, []);
  return (
    <>
      <div className="w-[250px]">
        <LablebSelect
          selectedItem={currentIndex}
          onItemChange={(value: any) => {
            setCurrentIndex(value);
          }}
          itemsList={indexList}
          icon={indexIcon}
        />
      </div>
    </>
  );
}
