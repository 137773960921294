import jwtDecode from 'jwt-decode';
import moment from 'moment';

export function isValidToken(token: string) {
  try {
    const now = moment();

    const expirationData = moment((jwtDecode(token) as any)?.exp * 1000);

    let diff = expirationData.diff(now);

    if (diff < 0) return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
