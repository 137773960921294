export const planNames = [
  // 'FREE_PLAN',
  'STARTED_PLAN',
  'PRO_PLAN',
  'ENTERPRICE_PLAN',
];

export const comparePlansData: any = {
  FEATURES: [
    {
      key: 'PRODUCTS_SLASH_DOCUMENTS',
      values: [5000, 50000, 1000000], //1000,
    },
    {
      key: 'SEARCH_REQUESTS',
      values: [30000, 300000, 2000000], //5000,
    },
    {
      key: 'SEARCH_ANALYTICS',
      values: [true, true, true], //true,
    },
    {
      key: 'ANALYTICS_RETENTION',
      values: ['30', '90', '90'], //'7',
    },
    {
      key: 'SYNONYMS',
      values: [1000, 10000, 10000], //100,
    },
  ],
  ADDITIONAL_FEATURES: [
    {
      key: 'SECURE_ENV',
      values: [true, true, true], //true,
    },
    {
      key: 'ADDITIONAL_TEAM_MEMBERS',
      values: ['20', '20', '20'], //'5',
    },
    {
      key: 'SEARCH_ANALYTICS',
      values: [true, true, true], //true,
    },
    {
      key: 'QUERY_SUGGESTIONS',
      values: [true, true, true], //true,
    },
    {
      key: 'ADVANCED_API',
      values: [false, false, true], //false,
    },
  ],
  SUPPORT: [
    {
      key: 'DOCUMENTATION',
      values: [true, true, true], //true,
    },
    {
      key: 'API_STATUS_UPDATES',
      values: [true, true, true], //true,
    },
    {
      key: 'EMAIL_AND_WEBSITE_SUPPORT',
      values: [true, true, true], //false,
    },
  ],
  SUCCESS_PLANS: [
    {
      key: 'ESSENTIAL_TRAINING',
      values: [true, true, true], //false,
    },
    {
      key: 'IMPLEMENTATION_REVIEW',
      values: ['CONTACT_SALES', 'CONTACT_SALES', 'CONTACT_SALES'], //'-',
    },
    // {
    //     key: '',
    //     values: [
    //         '',
    //         '',
    //         '',
    //         '',
    //     ]
    // },
    {
      key: 'PRICE_MONTHLY_STARTER_AT',
      values: ['$29.00', '$99.00', '$499.00'], //'-',
    },
    {
      key: 'PRICE_YEARLY_STARTER_AT',
      values: ['$348.00', '$1,188.00', '$5,988.00'], //'-',
    },
    {
      key: 'ADDITIONAL_FEES_FOR_EACH_1_000_DOCS',
      values: ['$1.00', '$1.00', '$1.00'], //'-',
    },
    {
      key: 'ADDITIONAL_FEES_FOR_EACH_10_000_REQUESTS',
      values: ['$5.00', '$5.00', '$5.00'], //'-',
    },
  ],
};
