import { useTranslation } from 'react-i18next';
import searchIcon from '../../assets/icons/search.svg';
import { useState } from 'react';
import circlePlusIcon from '../../assets/icons/circle-plus.svg';
import { LablebTable } from '../../design-system/table/table';
import { useApiKeysTableHeadData } from './api-keys.page.constants';
import clsx from 'clsx';

export function ApiKeysPageLoader() {
  const [translate] = useTranslation();
  const [searchInput, setSearchInput] = useState<string>('');
  function searchInputHandler(event: any) {
    setSearchInput(event.target.value);
  }

  function permissionsStyle(item: any) {
    function permissionName(permission: any) {
      if (permission?.is_admin) {
        return translate('ADMIN');
      }
      if (permission?.is_indexing) {
        return translate('INDEXING');
      }
      if (permission?.is_search) {
        return translate('SEARCH');
      }
    }
    return (
      <div className="flex justify-center items-center">
        <span
          className={clsx(
            'px-3 py-[2px] border-[1px] text-[12px] mx-1 rounded-2xl',
            {
              'text-[#8E44AD] bg-[#8E44AD]': item?.is_admin,
              'text-[#2980B9] bg-[#2980B9]': item?.is_indexing,
              'text-[#26AE60] bg-[#26AE60]': item?.is_search,
            }
          )}
        >
          {permissionName(item)}
        </span>
      </div>
    );
  }
  const tableHeader = useApiKeysTableHeadData(permissionsStyle);
  return (
    <>
      <div className="w-full ">
        <div className="w-full flex items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">{translate('API_KEYS')}</span>
          <div className=" flex items-center justify-between">
            <div className="relative mx-3">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[22px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                value={searchInput}
                onChange={searchInputHandler}
              />
            </div>

            <div className="flex p-2 cursor-pointer rounded-xl bg-primary items-center justify-center ">
              <img src={circlePlusIcon} className="max-w-[25px]" alt="add" />
            </div>
          </div>
        </div>

        <LablebTable tableHeader={tableHeader} tableRows={[]} loading />
      </div>
    </>
  );
}
