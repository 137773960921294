import DatePicker from 'react-date-picker/dist/entry.nostyle';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import calendarIcon from '../../assets/icons/calendar.svg';

interface LablebDatePickerProps {
  fromDate: Date;
  setFromDate: (d: Date) => void;
  toDate: Date;
  setToDate: (d: Date) => void;
}

export function LablebDatePicker(props: LablebDatePickerProps) {
  const { fromDate, setFromDate, toDate, setToDate } = props;

  return (
    <>
      <div className="flex justify-between items-center border-2 rounded-lg px-4 py-1 bg-white">
        <DatePicker
          value={fromDate}
          onChange={setFromDate}
          // minDate={moment().subtract(allowedPreviousDays, 'day').toDate()}
          maxDate={toDate}
          format="y/M/d"
          clearIcon={null}
          className="text-[#35405A] border-none outline-none"
          calendarIcon={
            <img src={calendarIcon} className="max-w-[25px]" alt="add" />
          }
        />
        <span className="mx-3">{'-'}</span>
        <DatePicker
          value={toDate}
          onChange={setToDate}
          minDate={fromDate}
          maxDate={moment().toDate()}
          format="y/M/d"
          clearIcon={null}
          className="text-[#35405A]"
          calendarIcon={
            <img src={calendarIcon} className="max-w-[25px]" alt="add" />
          }
        />
      </div>
    </>
  );
}
