import { transformNumberWithCommas } from '../../utils/functions/numbers-utils';
import CountUp from 'react-countup';
import { LablebSkeleton } from '../skeleton/skeleton';
interface LablebCountUpProps {
  value: number;
  loading?: boolean;
  suffix?: string;
}

export function LablebCountUp(props: LablebCountUpProps) {
  const { value, loading, suffix } = props;
  const transformValues = (value: any) =>
    `${transformNumberWithCommas(value)}${suffix || ''}`;
  if (loading) {
    return <LablebSkeleton style="w-7 h-3" />;
  }
  return (
    <>
      <CountUp
        duration={1}
        start={0}
        end={value}
        formattingFn={transformValues}
      />
    </>
  );
}
