import { useTranslation } from 'react-i18next';
import searchIcon from '../../assets/icons/search.svg';
import { LablebTable } from '../../design-system/table/table';
import { projectsTableHeadData } from './projects.page.constants';

export function ProjectsPageLoader() {
  const [translate] = useTranslation();
  return (
    <>
      <div className="w-full ">
        <div className="w-full flex items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">{translate('PROJECTS')}</span>
          <div className=" flex items-center justify-between">
            <div className="relative mx-3">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[22px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                readOnly
              />
            </div>
          </div>
        </div>

        <LablebTable
          tableHeader={projectsTableHeadData(translate)}
          tableRows={[]}
          loading
        />
      </div>
    </>
  );
}
