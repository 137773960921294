import { comparePlansData, planNames } from '../billings.page.constants';
import circleCheck from '../../../assets/icons/circle-check.svg';
import circleUncheck from '../../../assets/icons/circle-uncheck.svg';
import { useTranslation } from 'react-i18next';
import { boolean } from 'yup';
import { LablebSkeleton } from '../../../design-system/skeleton/skeleton';
import { transformNumberWithCommas } from '../../../utils/functions/numbers-utils';

interface ComparePlansTableProps {
  loading?: boolean;
}

export function ComparePlansTable(props: ComparePlansTableProps) {
  const [translte] = useTranslation();

  const { loading } = props;

  if (loading) {
    return (
      <div className="max-w-full flex flex-col justify-between min-h-[300px] bg-white rounded p-5">
        <LablebSkeleton style="w-[90%] h-5" />
        <LablebSkeleton style="w-[70%] h-5" />
        <LablebSkeleton style="w-[60%] h-5" />
        <LablebSkeleton style="w-[100%] h-5" />
        <LablebSkeleton style="w-[80%] h-5" />
        <LablebSkeleton style="w-[40%] h-5" />
      </div>
    );
  }

  return (
    <>
      <div className="max-w-full bg-white rounded">
        <table className="w-full">
          <thead>
            <tr>
              <td></td>
              {planNames.map((name) => (
                <td key={name} className="py-4 text-center">
                  {translte(name)}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(comparePlansData).map((key) => (
              <>
                <tr>
                  <td colSpan={5} className="bg-[#1F3746] py-4 px-5 text-white">
                    {translte(key)}
                  </td>
                </tr>
                {comparePlansData[key].map((subFeature: any, index: number) => (
                  <>
                    <tr key={index}>
                      <td className="py-4 px-5">{translte(subFeature?.key)}</td>
                      {subFeature?.values.map((value: any, index: number) => (
                        <td key={index} className="py-4 text-center ">
                          {typeof value == 'boolean' ? (
                            value ? (
                              <div className="flex justify-center items-center">
                                <img src={circleCheck} alt="circle-check" />
                              </div>
                            ) : (
                              <div className="flex justify-center items-center">
                                <img src={circleUncheck} alt="circle-uncheck" />
                              </div>
                            )
                          ) : subFeature?.key === 'ANALYTICS_RETENTION' ? (
                            <span>
                              {value} {translte('DAY')}
                            </span>
                          ) : (
                            <span>
                              {typeof value === 'number'
                                ? transformNumberWithCommas(value)
                                : translte(value)}
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                  </>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
