import { useMutation, useQueryClient } from 'react-query';
import { suggestRulesKeys } from './suggest-rules.keys';
import { lablebClient } from '../../api-client';
import { schemaKeys } from '../schema/fields.keys';
import { useTranslation } from 'react-i18next';
import useStore from '../../store/store';

export interface UseRemoveDefaultSuggestRulesParams {
  projectId: string;
  indexId: string;
}

export function useRemoveDefaultSuggestRules(
  params: UseRemoveDefaultSuggestRulesParams
) {
  const { projectId, indexId } = params;

  const queryClient = useQueryClient();
  const [t, { language }] = useTranslation();
  const setSnackbarInfo = useStore((state) => state?.setSnackbarInfo);

  function messageTranslate(fieldName: string) {
    if (language === 'ar')
      return `تمت إزالة قواعد الإكمال التلقائي لـ${fieldName}`;
    else return `Remove autocomplete rules for ${fieldName}`;
  }

  return useMutation(
    suggestRulesKeys.deleteDefaults({ projectId, indexId }),

    function addDefaultSuggestRules(fieldName: string) {
      return lablebClient.suggestRules.removeDefaultAutoSuggestsRulesFromField({
        platformId: projectId,
        indexId,
        fieldName,
      });
    },

    {
      onSuccess(_data, fieldName) {
        queryClient.invalidateQueries(schemaKeys.all({ projectId, indexId }));
        setSnackbarInfo({
          open: true,
          message: messageTranslate(fieldName),
          severity: 'success',
        });
      },
    }
  );
}
