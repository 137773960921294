import { useTranslation } from 'react-i18next';
import { FilterDatePicker } from '../../../../../design-system/filter-date-picker/filter-date-picker';
import { AnalyticsIndexFilter } from '../../components/indexs-filter';
import { useState } from 'react';
import moment from 'moment';
import { QueryClicksTable } from './components/query-clicks-table';
import { QueryInfoTable } from './components/query-info-table';
import useStore from '../../../../../store/store';
import { queryLogsTableHeadData } from '../../search-analytics.page.constants';

export function QueryInfoPageLoader() {
  const [translate] = useTranslation();

  const currentIndex = useStore((state) => state.analyticsIndexFilter);
  const queryLogsTableList = [
    {
      name: 'SEARCH',
      tableHead: queryLogsTableHeadData(translate),
      metric: 'search_query_logs',
    },
    {
      name: 'AUTOCOMPLETE',
      tableHead: queryLogsTableHeadData(translate),
      metric: 'autocomplete_query_logs',
    },
  ];
  return (
    <div className="w-full flex flex-col justify-between">
      <div className="w-full flex flex-col">
        <div className="w-full flex items-center gap-5 mb-5 justify-between flex-wrap">
          <div className="flex items-center ">
            <span className="text-[#030F2C] text-[24px] whitespace-nowrap">
              {translate('QUERY_INFO')}
            </span>
          </div>

          <div className="flex items-center flex-wrap gap-5 ">
            <div className="flex items-center gap-5 flex-wrap">
              <AnalyticsIndexFilter />
              <FilterDatePicker />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <QueryClicksTable index={currentIndex} />

          <QueryInfoTable
            tableName="QUERY_LOGS"
            queryInfoTableList={queryLogsTableList}
            index={currentIndex}
          />
        </div>
      </div>
    </div>
  );
}
