import { useTranslation } from 'react-i18next';
import searchIcon from '../../assets/icons/search.svg';
import { LablebTable } from '../../design-system/table/table';
import { suggetsRulesTableHeadData } from './suggest-rules.page.constants';

export function SuggestRulesPageLoader() {
  const [translate] = useTranslation();
  function fieldTypeStyle(item: any) {
    return (
      <span
        style={{ color: `${item?.color}`, borderColor: `${item?.color}` }}
        className={`px-3 py-[2px] border-[1px] text-[12px]  rounded-2xl`}
      >
        {item?.lable}
      </span>
    );
  }
  return (
    <>
      <div className="w-full ">
        <div className="w-full flex items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">
            {translate('SUGGESTION_RULES')}
          </span>
          <div className=" flex items-center justify-between">
            <div className="relative">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[22px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                value={''}
              />
            </div>
          </div>
        </div>

        <LablebTable
          loading
          tableHeader={suggetsRulesTableHeadData(translate, fieldTypeStyle)}
          tableRows={[]}
        />
      </div>
    </>
  );
}
