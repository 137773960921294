import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useStore from '../../store/store';
import {
  lablebAnalyticsClient,
  lablebClient,
  lablebUsageClient,
} from '../../api-client';

export function OauthPage() {
  const { token }: any = useParams();
  const navigate = useNavigate();
  const setAuthToken = useStore((state) => state.setAuthToken);
  useEffect(() => {
    localStorage.setItem('token', token);
    lablebClient.setJWTToken(token);
    lablebUsageClient.setJWTToken(token);
    lablebAnalyticsClient.setJWTToken(token);
    setAuthToken(token);
    navigate(`/projects`);
  }, []);

  return <></>;
}
