import { useTranslation } from 'react-i18next';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import useStore from '../../store/store';
import { transformNumberWithCommas } from '../../utils/functions/numbers-utils';
import { LablebSkeleton } from '../skeleton/skeleton';

interface LablebLineChartProps {
  data: any;
  linesData?: any;
  loading?:boolean
}

export function LablebLineChart(props: LablebLineChartProps) {
  const { data, linesData,loading } = props;

  const currentLanguage = useStore((state) => state.currentLanguage);
  const [translate] = useTranslation();

  // function transformNumberWithCommas(x: number) {

  //     let res = x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? String(x);

  //     return res;

  // }

  if(loading){
    return(
      <LablebSkeleton style='h-full w-full'/>
    )
  }

  return (
    <>
      <ResponsiveContainer width="99%">
        <LineChart
          style={{ direction: currentLanguage === 'ar' ? 'ltr' : undefined }}
          height={300}
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{ fontSize: 12 }}
            dataKey="name"
            reversed={currentLanguage === 'ar' ? true : false}
          />
          <YAxis
            tick={{ fontSize: 12 }}
            orientation={currentLanguage === 'ar' ? 'right' : 'left'}
          />
          <Tooltip
            formatter={(value, name, props) =>
              transformNumberWithCommas(Number(value))
            }
          />
          {linesData.map((line: any) => (
            <Line
              key={String(line.name + line.color)}
              type="monotone"
              dataKey={translate(line.name)}
              stroke={line.color}
              strokeWidth={2}
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
