export function mostVistiedPagesTableHeadData(translate: any) {
  return [
    {
      title: `${translate('PAGE')}`,
      key: 'title',
    },
    {
      title: `${translate('COUNT')}`,
      key: 'total',
    },
  ];
}
export function frequentViewedPagesTableHeadData(translate: any) {
  return [
    {
      title: `${translate('PAGE')}`,
      key: 'title',
    },
    {
      title: `${translate('COUNT')}`,
      key: 'total',
    },
  ];
}
