import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import circleCheck from '../../assets/icons/circle-check.svg';
import circleUncheck from '../../assets/icons/circle-uncheck.svg';
import { LablebTableLoader } from './table.loader';
import eyeIcon from '../../assets/icons/eye.svg';
import trashIcon from '../../assets/icons/trash.svg';
import { TablePermissionsMenu } from './components/permissions-menu';
import { transformNumberWithCommas } from '../../utils/functions/numbers-utils';
interface LablebTableProps {
  tableHeader: any;
  tableRows: any;
  deleteHandler?: any;
  viewHandler?: any;
  hasPermissionsList?: boolean;
  loading?: boolean;
  permissionsListItems?: any;
  rowActions?: any;
}

export function LablebTable(props: LablebTableProps) {
  const {
    tableHeader,
    tableRows,
    hasPermissionsList,
    deleteHandler,
    viewHandler,
    loading,
    permissionsListItems,
    rowActions,
  } = props;
  const [translate] = useTranslation();

  let defaultPermissionsList = [
    {
      name: `${translate('VIEW')}`,
      icon: eyeIcon,
      alt: 'view',
      onClick: viewHandler,
    },
    {
      name: `${translate('DELETE')}`,
      icon: trashIcon,
      alt: 'delete',
      onClick: deleteHandler,
    },
  ];
  let permissionsList = permissionsListItems
    ? permissionsListItems
    : defaultPermissionsList;
  if (loading) {
    return <LablebTableLoader tableHeader={tableHeader} />;
  }
  return (
    <>
      <div className="w-full  bg-white  rounded-b-xl py-4">
        <table className="w-full">
          <thead>
            <tr className="border-b-4">
              {tableHeader?.map((head: any, index: number) => (
                <td
                  className="pb-3 px-3 whitespace-nowrap   font-bold"
                  key={index}
                >
                  {translate(head.title)}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {!tableRows?.length ? (
              <tr>
                <td
                  colSpan={10}
                  className="text-center p-5 font-bold text-[#616161]"
                >
                  {translate('NO_RECORDS_IN_TABLE')}
                </td>
              </tr>
            ) : (
              tableRows?.map((row: any, rowIndex: number) => (
                <tr
                  key={rowIndex}
                  className={clsx(
                    'border-b-2 px-2 cursor-pointer hover:bg-[#eef0f5]',
                    {
                      'cursor-not-allowed': row?.disable,
                      'bg-[#e3e7f1]': row?.selected,
                    }
                  )}
                >
                  {tableHeader.map((head: any, index: number) => {
                    return (
                      <td
                        key={index}
                        className="max-w-[160px] p-3 whitespace-nowrap  overflow-hidden text-ellipsis"
                        onClick={(event) => {
                          if (row?.disable) event.preventDefault();
                          else if (viewHandler) viewHandler(row, rowIndex);
                        }}
                      >
                        {typeof head.key === 'function' ? (
                          typeof head.key(row) === 'boolean' ? (
                            <img
                              src={head.key(row) ? circleCheck : circleUncheck}
                              className="max-w-[24px]"
                              alt="check"
                            />
                          ) : typeof head.key(row, rowIndex) === 'number' ? (
                            transformNumberWithCommas(head.key(row, rowIndex))
                          ) : (
                            head.key(row, rowIndex)
                          )
                        ) : typeof row[head.key] === 'boolean' ? (
                          <img
                            src={row[head.key] ? circleCheck : circleUncheck}
                            className="max-w-[24px]"
                            alt="check"
                          />
                        ) : typeof row[head.key] === 'number' ? (
                          transformNumberWithCommas(row[head.key])
                        ) : (
                          row[head.key] ?? ''
                        )}
                      </td>
                    );
                  })}

                  <td className="w-[1%]">
                    <div className="flex gap-3 cursor-pointer mx-3">
                      {rowActions?.map((action: any, index: number) => {
                        return (
                          <div
                            key={index}
                            onClick={() => action?.onClick(row, rowIndex)}
                          >
                            <img
                              src={action?.icon}
                              className="max-w-[25px] "
                              alt={action?.alt}
                            />
                          </div>
                        );
                      })}
                      {hasPermissionsList ? (
                        <TablePermissionsMenu
                          disable={row?.disable}
                          permissionsList={permissionsList}
                          row={row}
                          rowIndex={rowIndex}
                        />
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
