import moment from 'moment';

export function projectsTableHeadData(translate: any) {
  return [
    {
      title: `${translate('PROJECT')}`,
      key: 'id',
    },
    {
      title: `${translate('PLAN')}`,
      key: (row: any) => {
        return row.subscription.plan.name;
      },
    },
    {
      title: `${translate('LAST_UPDATED')}`,
      key: (row: any) => {
        return moment(row.created_on).format('YYYY-MM-DD');
      },
    },
    {
      title: `${translate('RECORDS')}`,
      key: 'archetype',
    },
  ];
}
