import { useNavigate, useParams } from 'react-router-dom';
import useStore from '../../../../store/store';
import { useTranslation } from 'react-i18next';
import { useSearchAnalytics } from '../../../../hooks/analytics/search-analytics.hook';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { LablebTable } from '../../../../design-system/table/table';
import { mostVisitedResultsTableHeadData } from '../search-analytics.page.constants';

export function MostClickedResults() {
  const { projectId }: any = useParams();
  const currentLanguage = useStore((state) => state.currentLanguage);
  const navigate = useNavigate();
  const [translate] = useTranslation();
  const currentIndex = useStore((state) => state.analyticsIndexFilter);
  const setCurrentQuery = useStore((state) => state.setCurrentQuery);
  const analyticsFilters = useStore((state) => state.analyticsFilters);
  const { data, isFetching } = useSearchAnalytics({
    metrics: ['most_visited'],
    projectId: projectId,
    index: currentIndex,
  });

  function mostClickedResultViewHandler(item: any) {
    setCurrentQuery(item?.url);
    navigate(
      `/projects/${projectId}/search-analytics/most-clicked-results/${
        item?.item_id
      }/${analyticsFilters?.metricType.toLowerCase()}`
    );
  }

  function reverseUrl(url: any) {
    return (
      <div dir="ltr" className="flex rtl:justify-end">
        {url}
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex  flex-col ">
        <div className="w-full flex items-center justify-between rounded-t-xl bg-white h-[76px] p-3 border-b-4">
          <span className="text-[20px] font-bold">
            {translate('MOST_CLICKED_RESULTS')}
          </span>
          <div className="flex items-center cursor-pointer">
            <span
              onClick={() =>
                navigate(
                  `/projects/${projectId}/search-analytics/most-clicked-results`
                )
              }
              className="text-[16px] mx-3 text-[#6D7B9B]"
            >
              {translate('VIEW_MORE')}
            </span>
            <ChevronLeftIcon
              className={clsx('ml-2 -mr-1 h-5 w-5 text-[#6D7B9B]', {
                'rotate-180': currentLanguage === 'en',
              })}
            />
          </div>
        </div>
        <LablebTable
          loading={isFetching}
          tableHeader={mostVisitedResultsTableHeadData(translate, reverseUrl)}
          tableRows={data?.most_visited?.slice(0, 7)}
          viewHandler={mostClickedResultViewHandler}
        />
      </div>
    </>
  );
}
