import React, { Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';

import { LayoutPage } from '../components/layout-page/layout.page';
import { NotFoundPage } from '../pages/not-found/not-found.page';
import { CreateProjectPageLoader } from '../pages/create-project/create-project.page.loader';
import { ProjectPageLoader } from '../pages/project/project.page.loader';
import { IndicesPageLoader } from '../pages/indices/indices.page.loader';
import { IndexPageLoader } from '../pages/index/index.page.loader';
import { SchemaPageLoader } from '../pages/schema/schema.page.loader';
import { CreateNewFieldPageLoader } from '../pages/create-new-field/create-new-field.page.loader';
import { QueriesHandlersPageLoader } from '../pages/queries-handlers/queries-handlers.page.loader';
import { SearchHandlerPageLoader } from '../pages/search-handler/search-handler.page.loader';
import { AutocompleteHandlerPageLoader } from '../pages/autocomplete-handler/autocomplete-handler.page.loader';
import { RecommenderHandlerPageLoader } from '../pages/recommender-handler/recommender-handler.page.loader';
import { ResetPasswordPageLoader } from '../pages/reset-password/reset-password.page.loader';
import { ForgetPasswordPageLoader } from '../pages/forget-password/forget-password.page.loader';
import { SignUpPageLoader } from '../pages/signup/signup.page.loader';
import { LoginPageLoader } from '../pages/login/login.page.loader';
import { ProjectsPageLoader } from '../pages/projects/projects.page.loader';
import { LoginGuard } from '../guards/login.guard';
import { ProjectOverviewPageLoader } from '../pages/project-overview/project-overview.page.loader';
import { UploadDocumentsPageLoader } from '../pages/upload-documents/upload-documents.page.loader';
import { CreateCopyFieldPageLoader } from '../pages/create-copy-field/create-copy-field.page.loader';
import { SynonymsPageLoader } from '../pages/synonyms/synonyms.page.loader';
import { SuggestRulesPageLoader } from '../pages/suggest-rules/suggest-rules.page.loader';
import { RulePageLoader } from '../pages/rule/rule.page.loader';
import { CreateNewRulePageLoader } from '../pages/create-new-rule/create-new-rule.page.loader';
import { EditRulePageLoader } from '../pages/edit-rule/edit-rule.page.loader';
import { ApiKeysPageLoader } from '../pages/api-keys/api-keys.page.loader';
import { UsersPageLoader } from '../pages/users/users.page.loader';
import { SearchAnalyticsPageLoader } from '../pages/analytics/search-analytics/search-analytics.page.loader';
import { SearchPageLoader } from '../pages/analytics/search-analytics/pages/search/search.page.loader';
import { ZeroResultsSearchPageLoader } from '../pages/analytics/search-analytics/pages/zero-results-search/zero-results-search.page.loader';
import { RecommendationsAnalyticsPageLoader } from '../pages/analytics/recommendations-analytics/recommendations-analytics.page.loader';
import { MostVisitedPageLoader } from '../pages/analytics/recommendations-analytics/most-vistied/most-vistied.page.loader';
import { FrequentViewedPageLoader } from '../pages/analytics/recommendations-analytics/frequent-viewed/frequent-viewed.page.loader';
import { BillingsPageLoader } from '../pages/billings/billings.page.loader';
import { PaymentPlansPageLoader } from '../pages/billings/pages/payment-plans/payment-plans.page.loader';
import useStore from '../store/store';
import { UserProfilePageLoader } from '../pages/user-profile/user-profile.page.loader';
import { EditAccountPageLoader } from '../pages/user-profile/pages/edit-account/edit-account.page.loader';
import { ChangePasswordPageLoader } from '../pages/user-profile/pages/change-password/change-password.page.loader';
import { SignUpInvitationPageLoader } from '../pages/signup-invitation/signup-invitation.page.loader';
import { MostClickedResultsLoader } from '../pages/analytics/search-analytics/pages/most-clicked-results-page/most-clicked-results.page.loader';
import { ZeroVitiQueriesPageLoader } from '../pages/analytics/search-analytics/pages/zero-visit-queries-page/zero-visit-queries.page.loader';
import { SignupStopedPage } from '../pages/signup-invitation/signup-stoped/signup-stoped.page';
import { DemoPageLoader } from '../pages/demo/demo-page-loader';
import { QueryInfoClickedPageLoader } from '../pages/analytics/search-analytics/pages/most-clicked-results-page/query-info-clicked/query-info-clicked.page.loader';
import { QueryInfoPageLoader } from '../pages/analytics/search-analytics/pages/query-info/query-info.loader.page';
import { SupportPage } from '../pages/support/support.page';
import { OauthPage } from '../pages/login/oauth.page';

const ProjectsPage = React.lazy(
  () => import('../pages/projects/projects.page')
);
const IndicesPage = React.lazy(() => import('../pages/indices/indices.page'));
const ProjectPage = React.lazy(() => import('../pages/project/project.page'));
const AutocompleteHandlerPage = React.lazy(
  () => import('../pages/autocomplete-handler/autocomplete-handler.page')
);
const RecommenderHandlerPage = React.lazy(
  () => import('../pages/recommender-handler/recommender-handler.page')
);
const SearchHandlerPage = React.lazy(
  () => import('../pages/search-handler/search-handler.page')
);
const QueriesHandlersPage = React.lazy(
  () => import('../pages/queries-handlers/queries-handlers.page')
);
const SchemaPage = React.lazy(() => import('../pages/schema/schema.page'));
const SynonymsPage = React.lazy(
  () => import('../pages/synonyms/synonyms.page')
);
const SuggestRulesPage = React.lazy(
  () => import('../pages/suggest-rules/suggest-rules.page')
);
const LoginPage = React.lazy(() => import('../pages/login/login.page'));
const CreateProjectPage = React.lazy(
  () => import('../pages/create-project/create-project.page')
);
const CreateNewFieldPage = React.lazy(
  () => import('../pages/create-new-field/create-new-field.page')
);
const CreateCopyFieldPage = React.lazy(
  () => import('../pages/create-copy-field/create-copy-field.page')
);
const IndexPage = React.lazy(() => import('../pages/index/index.page'));
const ForgetPasswordPage = React.lazy(
  () => import('../pages/forget-password/forget-password.page')
);
const SignUpPage = React.lazy(() => import('../pages/signup/signup.page'));
const SignUpInvitationPage = React.lazy(
  () => import('../pages/signup-invitation/signup-invitation.page')
);
const ResetPasswordPage = React.lazy(
  () => import('../pages/reset-password/reset-password.page')
);
const ProjectOverviewPage = React.lazy(
  () => import('../pages/project-overview/project-overview.page')
);
const UploadDocumentsPage = React.lazy(
  () => import('../pages/upload-documents/upload-documents.page')
);
const RulePage = React.lazy(() => import('../pages/rule/rule.page'));
const CreateNewRulePage = React.lazy(
  () => import('../pages/create-new-rule/create-new-rule.page')
);
const EditRulePage = React.lazy(
  () => import('../pages/edit-rule/edit-rule.page')
);
const ApiKeysPage = React.lazy(() => import('../pages/api-keys/api-keys.page'));
const UsersPage = React.lazy(() => import('../pages/users/users.page'));
const SearchAnalyticsPage = React.lazy(
  () => import('../pages/analytics/search-analytics/search-analytics.page')
);
const SearchPage = React.lazy(
  () => import('../pages/analytics/search-analytics/pages/search/search.page')
);
const QueryInfoPage = React.lazy(
  () =>
    import(
      '../pages/analytics/search-analytics/pages/query-info/query-info.page'
    )
);
const MostClickedResultsPage = React.lazy(
  () =>
    import(
      '../pages/analytics/search-analytics/pages/most-clicked-results-page/most-clicked-results.page'
    )
);
const QueryInfoClickedPage = React.lazy(
  () =>
    import(
      '../pages/analytics/search-analytics/pages/most-clicked-results-page/query-info-clicked/query-info-clicked.page'
    )
);
const ZeroVitiQueriesPage = React.lazy(
  () =>
    import(
      '../pages/analytics/search-analytics/pages/zero-visit-queries-page/zero-visit-queries.page'
    )
);
const ZeroResultsSearchPage = React.lazy(
  () =>
    import(
      '../pages/analytics/search-analytics/pages/zero-results-search/zero-results-search.page'
    )
);
const RecommendationsAnalyticsPage = React.lazy(
  () =>
    import(
      '../pages/analytics/recommendations-analytics/recommendations-analytics.page'
    )
);
const MostVisitedPage = React.lazy(
  () =>
    import(
      '../pages/analytics/recommendations-analytics/most-vistied/most-vistied.page'
    )
);

const FrequentViewedPage = React.lazy(
  () =>
    import(
      '../pages/analytics/recommendations-analytics/frequent-viewed/frequent-viewed.page'
    )
);
const BillingsPage = React.lazy(
  () => import('../pages/billings/billings.page')
);
const PaymentPlansPage = React.lazy(
  () => import('../pages/billings/pages/payment-plans/payment-plans.page')
);
const ProjectsLayoutPage = React.lazy(
  () => import('../pages/projects-layout/projects-layout.page')
);
const UserProfilePage = React.lazy(
  () => import('../pages/user-profile/user-profile.page')
);
const EditAccountPage = React.lazy(
  () => import('../pages/user-profile/pages/edit-account/edit-account.page')
);
const ChangePasswordPage = React.lazy(
  () =>
    import('../pages/user-profile/pages/change-password/change-password.page')
);
const DemoPage = React.lazy(() => import('../pages/demo/demo-page'));

export function RootRouter() {
  const currentProject = useStore((state) => state?.currentProject);
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="" element={<LoginPage />}>
                        <Route
                            index
                            element={
                                <Navigate to="login" />
                            }
                        />
                    </Route> */}

          <Route
            path="login"
            element={
              <Suspense fallback={<LoginPageLoader />}>
                <LoginPage />
              </Suspense>
            }
          />

          <Route
            path="/signup"
            element={
              <Suspense fallback={<SignUpPageLoader />}>
                <SignUpPage />
              </Suspense>
            }
          />

          <Route
            path="/dashboard/signup/user-invitation"
            element={
              <Suspense fallback={<SignUpInvitationPageLoader />}>
                <SignUpInvitationPage />
              </Suspense>
            }
          />

          <Route
            path="/dashboard/signup/invitation"
            element={<SignupStopedPage />}
          />

          <Route
            path="/forget-password"
            element={
              <Suspense fallback={<ForgetPasswordPageLoader />}>
                <ForgetPasswordPage />
              </Suspense>
            }
          />

          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<ResetPasswordPageLoader />}>
                <ResetPasswordPage />
              </Suspense>
            }
          />

          <Route
            path="/oauth/:token"
            element={<OauthPage />}
          />

          <Route path="/" element={<LayoutPage />}>
            <Route index element={<Navigate to="projects" />} />

            <Route path="projects" element={<Outlet />}>
              <Route
                index
                element={
                  <LoginGuard>
                    <ProjectsLayoutPage />
                  </LoginGuard>
                }
              />

              {/* <Route
                                path="all"
                                element={
                                    <Suspense fallback={<ProjectsPageLoader />}>
                                        <LoginGuard>
                                            <ProjectsPage />
                                        </LoginGuard>
                                    </Suspense>
                                }
                            /> */}

              <Route
                path="create"
                element={
                  <Suspense fallback={<CreateProjectPageLoader />}>
                    <LoginGuard>
                      <CreateProjectPage />
                    </LoginGuard>
                  </Suspense>
                }
              />

              <Route path=":projectId" element={<Outlet />}>
                <Route
                  index
                  element={
                    <Suspense fallback={<ProjectPageLoader />}>
                      <LoginGuard>
                        <ProjectPage />
                      </LoginGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="me"
                  element={
                    <Suspense fallback={<UserProfilePageLoader />}>
                      <LoginGuard>
                        <UserProfilePage />
                      </LoginGuard>
                    </Suspense>
                  }
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<EditAccountPageLoader />}>
                        <EditAccountPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="change-password"
                    element={
                      <Suspense fallback={<ChangePasswordPageLoader />}>
                        <ChangePasswordPage />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  path="all"
                  element={
                    <Suspense fallback={<ProjectsPageLoader />}>
                      <LoginGuard>
                        <ProjectsPage />
                      </LoginGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="overview"
                  element={
                    <Suspense fallback={<ProjectOverviewPageLoader />}>
                      <LoginGuard>
                        <ProjectOverviewPage />
                      </LoginGuard>
                    </Suspense>
                  }
                />

                <Route path="indices" element={<Outlet />}>
                  <Route
                    index
                    element={
                      <Suspense fallback={<IndicesPageLoader />}>
                        <LoginGuard>
                          <IndicesPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />

                  <Route path=":indexId" element={<Outlet />}>
                    <Route
                      index
                      element={
                        <Suspense fallback={<IndexPageLoader />}>
                          <LoginGuard>
                            <IndexPage />
                          </LoginGuard>
                        </Suspense>
                      }
                    />

                    <Route
                      path="upload"
                      element={
                        <Suspense fallback={<UploadDocumentsPageLoader />}>
                          <LoginGuard>
                            <UploadDocumentsPage />
                          </LoginGuard>
                        </Suspense>
                      }
                    />

                    <Route path="schema" element={<Outlet />}>
                      <Route
                        index
                        element={
                          <Suspense fallback={<SchemaPageLoader />}>
                            <LoginGuard>
                              <SchemaPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />

                      <Route
                        path="fields/create"
                        element={
                          <Suspense fallback={<CreateNewFieldPageLoader />}>
                            <LoginGuard>
                              <CreateNewFieldPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />

                      <Route
                        path="copy-fields/create"
                        element={
                          <Suspense fallback={<CreateCopyFieldPageLoader />}>
                            <LoginGuard>
                              <CreateCopyFieldPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />

                      <Route path="*" element={<Navigate to=".." />} />
                    </Route>

                    <Route path="query" element={<Outlet />}>
                      <Route
                        index
                        element={
                          <Suspense fallback={<QueriesHandlersPageLoader />}>
                            <LoginGuard>
                              <QueriesHandlersPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />

                      <Route
                        path="search/:handlerId"
                        element={
                          <Suspense fallback={<SearchHandlerPageLoader />}>
                            <LoginGuard>
                              <SearchHandlerPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />

                      <Route
                        path="autocomplete/:handlerId"
                        element={
                          <Suspense
                            fallback={<AutocompleteHandlerPageLoader />}
                          >
                            <LoginGuard>
                              <AutocompleteHandlerPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />

                      <Route
                        path="recommender/:handlerId"
                        element={
                          <Suspense fallback={<RecommenderHandlerPageLoader />}>
                            <LoginGuard>
                              <RecommenderHandlerPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />

                      <Route path="*" element={<Navigate to=".." />} />
                    </Route>

                    <Route
                      path="synonyms"
                      element={
                        <Suspense fallback={<SynonymsPageLoader />}>
                          <LoginGuard>
                            <SynonymsPage />
                          </LoginGuard>
                        </Suspense>
                      }
                    />

                    <Route path="suggest-rules" element={<Outlet />}>
                      <Route
                        index
                        element={
                          <Suspense fallback={<SuggestRulesPageLoader />}>
                            <LoginGuard>
                              <SuggestRulesPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />

                      <Route path=":filedId" element={<Outlet />}>
                        <Route
                          index
                          element={
                            <Suspense fallback={<RulePageLoader />}>
                              <LoginGuard>
                                <RulePage />
                              </LoginGuard>
                            </Suspense>
                          }
                        />

                        <Route
                          path=":ruleIndex"
                          element={
                            <Suspense fallback={<EditRulePageLoader />}>
                              <LoginGuard>
                                <EditRulePage />
                              </LoginGuard>
                            </Suspense>
                          }
                        />

                        <Route
                          path="add"
                          element={
                            <Suspense fallback={<CreateNewRulePageLoader />}>
                              <LoginGuard>
                                <CreateNewRulePage />
                              </LoginGuard>
                            </Suspense>
                          }
                        />
                      </Route>
                    </Route>
                    <Route path="demo" element={<Outlet />}>
                      <Route
                        index
                        element={
                          <Suspense fallback={<DemoPageLoader />}>
                            <LoginGuard>
                              <DemoPage />
                            </LoginGuard>
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                </Route>

                <Route
                  path="api-keys"
                  element={
                    <Suspense fallback={<ApiKeysPageLoader />}>
                      <LoginGuard>
                        <ApiKeysPage />
                      </LoginGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="users"
                  element={
                    <Suspense fallback={<UsersPageLoader />}>
                      <LoginGuard>
                        <UsersPage />
                      </LoginGuard>
                    </Suspense>
                  }
                />

                <Route path="search-analytics" element={<Outlet />}>
                  <Route
                    index
                    element={
                      <Suspense fallback={<SearchAnalyticsPageLoader />}>
                        <LoginGuard>
                          <SearchAnalyticsPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />

                  <Route path="query-info" element={<Outlet />}>
                    <Route index element={<NotFoundPage />} />
                    <Route
                      path=":query/:metricType"
                      element={
                        <Suspense fallback={<QueryInfoPageLoader />}>
                          <LoginGuard>
                            <QueryInfoPage />
                          </LoginGuard>
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route
                    path="search"
                    element={
                      <Suspense fallback={<SearchPageLoader />}>
                        <LoginGuard>
                          <SearchPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />

                  <Route path="most-clicked-results" element={<Outlet />}>
                    <Route
                      index
                      element={
                        <Suspense fallback={<MostClickedResultsLoader />}>
                          <LoginGuard>
                            <MostClickedResultsPage />
                          </LoginGuard>
                        </Suspense>
                      }
                    />
                    <Route
                      path=":queryId/:metricType"
                      element={
                        <Suspense fallback={<QueryInfoClickedPageLoader />}>
                          <LoginGuard>
                            <QueryInfoClickedPage />
                          </LoginGuard>
                        </Suspense>
                      }
                    />
                  </Route>
                  <Route
                    path="zero-visit-queries"
                    element={
                      <Suspense fallback={<ZeroVitiQueriesPageLoader />}>
                        <LoginGuard>
                          <ZeroVitiQueriesPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />

                  <Route
                    path="zero-results"
                    element={
                      <Suspense fallback={<ZeroResultsSearchPageLoader />}>
                        <LoginGuard>
                          <ZeroResultsSearchPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="recommendations-analytics" element={<Outlet />}>
                  <Route
                    index
                    element={
                      <Suspense
                        fallback={<RecommendationsAnalyticsPageLoader />}
                      >
                        <LoginGuard>
                          <RecommendationsAnalyticsPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />

                  <Route
                    path="most-visited"
                    element={
                      <Suspense fallback={<MostVisitedPageLoader />}>
                        <LoginGuard>
                          <MostVisitedPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />

                  <Route
                    path="frequent-views"
                    element={
                      <Suspense fallback={<FrequentViewedPageLoader />}>
                        <LoginGuard>
                          <FrequentViewedPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="billing" element={<Outlet />}>
                  <Route
                    index
                    element={
                      <Suspense fallback={<BillingsPageLoader />}>
                        <LoginGuard>
                          <BillingsPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />

                  <Route
                    path="payment-plans"
                    element={
                      <Suspense fallback={<PaymentPlansPageLoader />}>
                        <LoginGuard>
                          <PaymentPlansPage />
                        </LoginGuard>
                      </Suspense>
                    }
                  />
                </Route>

                {/* <Route
                  path="dashboard"
                  element={
                    <Suspense fallback={<OverViewPageLoader />}>
                      <LoginGuard>
                        <UsersPage />
                      </LoginGuard>
                    </Suspense>
                  }
                /> */}

                <Route
                  path="support"
                  element={
                    <LoginGuard>
                      <SupportPage />
                    </LoginGuard>
                  }
                />
              </Route>
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
