import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import useStore from '../../../store/store';
import { ProjectsPopover } from '../../top-nav/components/projects-popover';
import { sideNavList } from '../side-nav-constants';
import { NavLink, useParams } from 'react-router-dom';
import { LablebNestedMenu } from '../../../design-system/nested-menu/nested-menu';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  useHasFullControl,
  useIsDeveloper,
  useIsEcommerce,
  useIsViewOnlyUser,
  useProjectHasNoSubscription,
  useProjectHasPaymentIssues,
} from '../../../utils/hooks';
import LablebLogo from '../../../assets/images/lableb-logo.png';
import { ProjectsDropDown } from './components/projects-dropdown';

export function ResponsiveSideNav() {
  const { projectId }: any = useParams();
  const { indexId }: any = useParams();
  const openDrawerSideNav = useStore((state) => state?.openDrawerSideNav);
  const setOpenDrawerSideNav = useStore((state) => state?.setOpenDrawerSideNav);
  function onCloseHandler() {
    setOpenDrawerSideNav(false);
  }
  const [translate] = useTranslation();
  const currentIndex = useStore((state) => state.currentIndex);
  const changeLang = useStore((state) => state.changeLang);
  const currentLanguage = useStore((state) => state.currentLanguage);
  const isDeveloper = useIsDeveloper(projectId);
  const isViewOnly = useIsViewOnlyUser(projectId);
  const hasFullControl = useHasFullControl(projectId);
  const hasPaymentIssues = useProjectHasPaymentIssues(projectId);
  const hasNoSubscription = useProjectHasNoSubscription(projectId);
  const isEcommerce = useIsEcommerce(projectId);

  return (
    <div className="sm:hidden">
      <Transition appear show={openDrawerSideNav} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={onCloseHandler}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 sm:hidden" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto sm:hidden">
            <div className="flex h-full items-end justify-start ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="ltr:-translate-x-[30vh] rtl:translate-x-[30vh]"
                enterTo="ltr:-translate-x-0 rtl:translate-x-0"
                leave="ease-in duration-200"
                leaveFrom="ltr:-translate-x-0 rtl:translate-x-0"
                leaveTo="ltr:-translate-x-[30vh] rtl:translate-x-[30vh]"
              >
                <Dialog.Panel className="h-full overflow-auto w-full max-w-[70vw] gap-5 transform   rounded-b-none bg-white  py-4  shadow-xl transition-all flex flex-col">
                  <img
                    src={LablebLogo}
                    alt="lableb-logo"
                    className="w-[80px] overflow-auto h-[40px] mx-7"
                  />
                  <ProjectsDropDown />
                  <hr className="w-full border h-[1px]" />
                  <div className="flex flex-col flex-grow justify-between">
                    <div className="px-4">
                      {sideNavList(
                        translate,
                        projectId,
                        currentIndex,
                        isDeveloper,
                        isViewOnly,
                        hasFullControl,
                        hasPaymentIssues,
                        hasNoSubscription,
                        isEcommerce
                      ).map((item: any) => (
                        <NavLink end to={item?.path} key={item.title}>
                          {({ isActive }) => (
                            <div key={item.title}>
                              {!item.hide ? (
                                item.isNestedMenu ? (
                                  <LablebNestedMenu
                                    title={item.title}
                                    icon={
                                      !isActive ? item.icon : item.iconOnSelect
                                    }
                                    path={item.path}
                                    links={item?.links(
                                      translate,
                                      projectId,
                                      currentIndex,
                                      isEcommerce
                                    )}
                                    isSideNavOpen={false}
                                  />
                                ) : (
                                  <div
                                    className={clsx(
                                      'flex items-center my-1 justify-between w-full px-3 py-3 rounded-xl cursor-pointer ',
                                      {
                                        'bg-primary': isActive,
                                        'bg-[#f56a6a]': item?.warn,
                                      }
                                    )}
                                  >
                                    <div className="flex items-center">
                                      <img
                                        src={
                                          !isActive
                                            ? item.icon
                                            : item.iconOnSelect
                                        }
                                        className="max-w-[25px] text-slate-50"
                                        alt={item.alt}
                                      />

                                      <span
                                        className={clsx(
                                          'text-l mx-3 whitespace-nowrap text-primary',
                                          {
                                            'text-slate-100': isActive,
                                            'text-white': item?.warn,
                                          }
                                        )}
                                      >
                                        {item.title}
                                      </span>
                                    </div>
                                  </div>
                                )
                              ) : null}
                            </div>
                          )}
                        </NavLink>
                      ))}
                    </div>
                    <div className="flex justify-center">
                      <div className="border flex w-fit rounded-md px-2 text-primary text-[14px] border-primary">
                        <button
                          className="rtl:font-[SF-Pro-Display-Regular]  ltr:font-[DubaiW23-Regular]"
                          onClick={() =>
                            changeLang(currentLanguage === 'ar' ? 'en' : 'ar')
                          }
                        >
                          {translate('LANGUAGE')}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
