export function parseFeatureValue(params: {
  value: any | undefined;
  defaultValue: number;
  maxSafeValue?: number;
}): number;
export function parseFeatureValue(params: {
  value: any | undefined;
  defaultValue: boolean;
  maxSafeValue?: number;
}): boolean;
export function parseFeatureValue({
  value,
  defaultValue,
  maxSafeValue,
}: {
  value: any | undefined;
  defaultValue: number | boolean;
  maxSafeValue?: number;
}): number | boolean {
  try {
    const valueType = typeof value;

    switch (valueType) {
      case 'undefined': {
        return defaultValue;
      }

      case 'boolean': {
        return value;
      }

      default: {
        if (value == 'Unlimited')
          if (maxSafeValue) return maxSafeValue;
          else return Infinity;

        return parseInt(value as string);
      }
    }
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
}

export function transformNumberWithCommas(x: number) {
  let res = x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? String(x);

  return res;
}
