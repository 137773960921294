import { useTranslation } from 'react-i18next';
import { LablebSkeleton } from '../../../../design-system/skeleton/skeleton';

export function ChangePasswordPageLoader() {
  const [translate] = useTranslation();

  return (
    <>
      <div className="flex flex-col w-full">
        <span className="text-[24px] text-[#030F2C] font-medium mb-4">
          {translate('CHANGE_PASSWORD')}
        </span>

        <div className="flex flex-col gap-10 max-w-[640px] p-5 bg-white rounded">
          <LablebSkeleton style="w-[90%] h-5" />
          <LablebSkeleton style="w-[70%] h-5" />
          <LablebSkeleton style="w-[60%] h-5" />
          <LablebSkeleton style="w-[100%] h-5" />
          <LablebSkeleton style="w-[80%] h-5" />
          <LablebSkeleton style="w-[40%] h-5" />
          <LablebSkeleton style="w-[100%] h-5" />
        </div>
      </div>
    </>
  );
}
