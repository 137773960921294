import moment from 'moment';

export function searchAnalyticsTableHeadData() {
  return [
    {
      title: `${'QUERY'}`,
      key: 'query',
    },
    {
      title: `${'COUNT'}`,
      key: 'freq',
    },
    {
      title: `${'SESSIONS_TABLE'}`,
      key: 'sessions',
    },
    {
      title: `${'TOTAL_SEARCH'}`,
      key: 'max_results',
    },
  ];
}

export function zeroResultsTableHeadData() {
  return [
    {
      title: `${'QUERY'}`,
      key: 'query',
    },
    {
      title: `${'COUNT'}`,
      key: 'freq',
    },
    {
      title: `${'SESSIONS_TABLE'}`,
      key: 'sessions',
    },
    {
      title: `${'TOTAL_SEARCH'}`,
      key: 'max_results',
    },
  ];
}

export function zeroVisitQueriesTableHeadData() {
  return [
    {
      title: `${'QUERY'}`,
      key: 'query',
    },
    {
      title: `${'COUNT'}`,
      key: 'freq',
    },
    {
      title: `${'SESSIONS_TABLE'}`,
      key: 'sessions',
    },
    {
      title: `${'TOTAL_SEARCH'}`,
      key: 'max_results',
    },
  ];
}

export function mostVisitedResultsTableHeadData(
  translate: any,
  reverseUrl?: any
) {
  return [
    {
      title: `${translate('URL')}`,
      key: (row: any) => {
        return reverseUrl(decodeURI(row?.url));
      },
    },
    {
      title: `${'COUNT'}`,
      key: 'freq',
    },
    {
      title: `${'SESSIONS_TABLE'}`,
      key: 'sessions',
    },
    // {
    //   title: `${'ITEM_ID'}`,
    //   key: 'item_id',
    // },
  ];
}

export function queryCountriesTableHeadData() {
  return [
    {
      title: `COUNTRY_CODE`,
      key: 'country_code',
    },
    {
      title: `${'COUNT'}`,
      key: 'count',
    },
  ];
}
export function queryInfoTableHeadData(
  itemsOrdersList?: any,
  itemsOrdersListCount?: any
) {
  return [
    {
      title: `QUERY`,
      key: 'query',
    },
    {
      title: `${'ORDER'}`,
      key: (row: any) => {
        return itemsOrdersList(row.items_orders);
      },
    },
    {
      title: `${'COUNT'}`,
      key: (row: any) => {
        return itemsOrdersListCount(row.items_orders);
      },
    },
  ];
}

export function queryClicksTableHeadData(reverseUrl: any) {
  return [
    {
      title: `URL`,
      key: (row: any) => {
        return reverseUrl(decodeURI(row?.url));
      },
    },
    {
      title: `${'COUNT'}`,
      key: 'freq',
    },
  ];
}

export function queryLogsTableHeadData(translate: any) {
  return [
    {
      title: `USER_ID`,
      key: (row: any) => {
        return row.user_id ? row.user_id : translate('NOT_SENT');
      },
    },
    {
      title: `USER_IP`,
      key: (row: any) => {
        return row.user_ip ? row.user_ip : translate('NOT_SENT');
      },
    },
    {
      title: `COUNTRY_CODE`,
      key: (row: any) => {
        return row.country ? row.country : translate('NOT_SENT');
      },
    },
    {
      title: `NUM_RESULTS`,
      key: 'num_results',
    },
    {
      title: `DATE`,
      key: (row: any) => {
        return moment(row.timestamp).format('YYYY-MM-DD,h:mm:ss');
      },
    },
  ];
}

export function queryClicksLogsTableHeadData(translate: any) {
  return [
    {
      title: `USER_ID`,
      key: (row: any) => {
        return row.user_id ? row.user_id : translate('NOT_SENT');
      },
    },
    {
      title: `USER_IP`,
      key: (row: any) => {
        return row.user_ip ? row.user_ip : translate('NOT_SENT');
      },
    },
    {
      title: `RESULT_ORDER`,
      key: (row: any) => {
        return row.item_order ? row.item_order : translate('NOT_SENT');
      },
    },
    {
      title: `COUNTRY_CODE`,
      key: (row: any) => {
        return row.country ? row.country : translate('NOT_SENT');
      },
    },
    {
      title: `DATE`,
      key: (row: any) => {
        return moment(row.timestamp).format('YYYY-MM-DD,h:mm:ss');
      },
    },
  ];
}
