import React, { Fragment, ReactNode, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { isValidToken } from '../functions/token-utils';
import useStore from '../store/store';

const storToken = localStorage.getItem('token');

export function LoginGuard({ children }: { children: ReactNode }) {
  const authToken = useStore((state) => state.authToken);
  const isValid = useMemo(() => {
    if (authToken) {
      return isValidToken(authToken);
    } else {
      return isValidToken(storToken || '');
    }
  }, [authToken]);

  if (isValid) return <Fragment>{children}</Fragment>;

  return <Navigate to="/login" />;
}
