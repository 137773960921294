import axios from 'axios';
import { useMutation } from 'react-query';

export function useSupportMessage() {
  return useMutation(
    function sendSupportMessage(params: {
      projectId: string;
      message: string;
      userName: string;
      email: string;
    }) {
      return axios.post(
        'https://api.telegram.org/bot7263245374:AAFuLGSZuVSnskhSJjPaW_WWaZGNnojWU6Y/sendMessage',
        {
          chat_id: '@lableb_support',
          text: `
User Name: ${params.userName}          
Project Name: ${params.projectId}
Email: ${params.email}
Message: ${params.message}

  `,
        }
      );
    },
    {
      onSuccess() {},
    }
  );
}
