import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDatePicker } from '../../../design-system/filter-date-picker/filter-date-picker';
import clicksIcon from '../../../assets/icons/ctr-icon.svg';
import ctrIcon from '../../../assets/icons/ctr-icon-b.svg';
import recommendIcon from '../../../assets/icons/recommend-icon.svg';
import viewsIcon from '../../../assets/icons/views-icon.svg';
import { AnalyticsCard } from '../../../design-system/analytics-card/analytics-card';
import { LablebPopover } from '../../../design-system/popover/popover';
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import { LablebLineChart } from '../../../design-system/line-chart/line-chart';
import { useNavigate, useParams } from 'react-router-dom';
import useStore from '../../../store/store';
import clsx from 'clsx';
import { LablebTable } from '../../../design-system/table/table';
import {
  frequentViewedPagesTableHeadData,
  mostVistiedPagesTableHeadData,
} from './recommendation-analytics.page.constants';
export function RecommendationsAnalyticsPageLoader() {
  const [translate] = useTranslation();

  const { projectId }: any = useParams();
  const navigate = useNavigate();
  const overviewAnalyticsData = [
    { title: 'RECOMMENDATIONS', count: 0, icon: recommendIcon },
    // {
    //   title: 'VIEWS',
    //   count: 0,
    //   icon: viewsIcon,
    // },
    {
      title: 'CTR',
      count: 0,
      icon: ctrIcon,
      info: 'CTR_INFO',
    },
    {
      title: 'CLICKS',
      count: 0,
      icon: clicksIcon,
    },
  ];

  const currentLanguage = useStore((state) => state.currentLanguage);
  return (
    <>
      <div className="w-full flex flex-col">
        <div className="flex w-full items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">
            {translate('RECOMMENDATION_ANALYTICS')}
          </span>
          <FilterDatePicker />
        </div>

        <div className="w-full mb-5 grid gap-2 grid-cols-cards">
          {overviewAnalyticsData?.map((item, index) => (
            <AnalyticsCard
              key={index}
              loading
              title={item?.title}
              value={item?.count}
              cardIcon={item?.icon}
              info={item.info}
            />
          ))}
        </div>

        <div className="w-full flex flex-col h-[400px]  bg-white p-5 rounded-lg">
          <div className="w-full mb-4 flex justify-between items-center">
            <span className="text-[20px] font-bold">
              {translate('REQUESTS_FREQUANCY')}
            </span>
            <LablebPopover
              popoverButton={
                <div className="flex  items-center justify-between rounded-xl px-2 py-2 border-[1px] outline-none border-[#D2DBEE]">
                  <span className="text-[#35405A] mx-5 text-[16px]">
                    {translate('COMPARISON_ITEMS')}
                  </span>
                  <ChevronDownIcon
                    className="ml-2 -mr-1 h-5 w-5  text-gray700"
                    aria-hidden="true"
                  />
                </div>
              }
            >
              <div className="flex flex-col w-[258px] px-3 py-1 justify-between rounded-sm bg-white"></div>
            </LablebPopover>
          </div>

          <LablebLineChart data={[]} linesData={[]} loading />
        </div>

        <div className="w-full flex max-h-[385px] overflow-hidden justify-between my-5">
          <div className="w-[49%] flex flex-col">
            <div className="w-full flex items-center justify-between bg-white py-3 px-5 border-b-4">
              <span className="text-[20px] font-bold">
                {translate('FREQUENT_VIEWED_PAGES')}
              </span>
              <div className="flex items-center cursor-pointer">
                <span
                  onClick={() =>
                    navigate(
                      `/projects/${projectId}/recommendations-analytics/frequent-views`
                    )
                  }
                  className="text-[16px] mx-3 text-[#6D7B9B]"
                >
                  {translate('VIEW_MORE')}
                </span>
                <ChevronLeftIcon
                  className={clsx('ml-2 -mr-1 h-5 w-5 text-[#6D7B9B]', {
                    'rotate-180': currentLanguage === 'en',
                  })}
                />
              </div>
            </div>
            <LablebTable
              loading
              tableHeader={frequentViewedPagesTableHeadData(translate)}
              tableRows={[]}
            />
          </div>

          <div className="w-[49%] flex flex-col">
            <div className="w-full flex items-center justify-between bg-white py-3 px-5 border-b-4">
              <span className="text-[20px] font-bold">
                {translate('MOST_VISTIED_PAGES')}
              </span>
              <div className="flex items-center cursor-pointer">
                <span
                  onClick={() =>
                    navigate(
                      `/projects/${projectId}/recommendations-analytics/most-visited`
                    )
                  }
                  className="text-[16px] mx-3 text-[#6D7B9B]"
                >
                  {translate('VIEW_MORE')}
                </span>
                <ChevronLeftIcon
                  className={clsx('ml-2 -mr-1 h-5 w-5 text-[#6D7B9B]', {
                    'rotate-180': currentLanguage === 'en',
                  })}
                />
              </div>
            </div>
            <LablebTable
              loading
              tableHeader={mostVistiedPagesTableHeadData(translate)}
              tableRows={[]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
