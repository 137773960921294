export const suggestRulesKeys = {
  all: (params: { projectId: string; indexId: string; fieldName: string }) =>
    ['suggest-rules', params] as const,

  details: (params: {
    projectId: string;
    indexId: string;
    fieldName: string;
    ruleId: string;
  }) => ['suggest-rule-details', params] as const,

  add: (params: { projectId: string; indexId: string; fieldName: string }) =>
    ['add-suggest-rules', params] as const,

  addDefaults: (params: { projectId: string; indexId: string }) =>
    ['add-default-suggest-rules', params] as const,

  deleteDefaults: (params: { projectId: string; indexId: string }) =>
    ['delete-default-suggest-rules', params] as const,
};
