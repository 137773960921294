import { useQuery } from 'react-query';
import { lablebClient } from '../../api-client';

export function useProfile() {
  return useQuery(
    ['profile'],
    function fetchProfile() {
      return lablebClient.profile.fetchProfile({});
    },
    {
      select: (response) => response.response.user,
    }
  );
}
