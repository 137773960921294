import { useTranslation } from 'react-i18next';
import searchIcon from '../../assets/icons/search.svg';
import { useState } from 'react';
import { LablebTable } from '../../design-system/table/table';
import { searchHandlersTableHeadData } from '../queries-handlers/queries-handlers.page.constants';

export function SearchHandlerPageLoader() {
  const [inputSearch, setinputSearch] = useState<string>('');
  function inputSearchHandler(event: any) {
    setinputSearch(event.target.value);
  }

  const [translate] = useTranslation();

  return (
    <>
      <div className="w-full ">
        <div className="w-full flex items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">{translate('QUERIES')}</span>
          <div className=" flex items-center justify-between">
            <div className="relative">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[22px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                value={inputSearch}
                onChange={inputSearchHandler}
              />
            </div>
          </div>
        </div>

        <LablebTable
          loading
          tableHeader={searchHandlersTableHeadData(
            () => {},
            translate,
            () => {},
            []
          )}
          tableRows={[]}
        />

        {/* <RelevanceFormulaTable
          loading
          openDialog={() => {}}
          handlerType="search"
        />

        <CustomParametersTable
          loading
          openAddMode={() => {}}
          openEditMode={() => {}}
          handlerType="search"
        /> */}
      </div>
    </>
  );
}
