export const schemaKeys = {
  all: ({ projectId, indexId }: { projectId: string; indexId: string }) =>
    ['schema', { projectId, indexId }] as const,

  fieldDetails: (params: {
    projectId: string;
    indexId: string;
    fieldName: string;
  }) => ['field', params] as const,

  addField: ({ projectId, indexId }: { projectId: string; indexId: string }) =>
    ['add-field', { projectId, indexId }] as const,

  copyFieldDetails: (params: {
    projectId: string;
    indexId: string;
    copyFieldName: string;
  }) => ['copy-field', params] as const,

  addCopyField: (params: { projectId: string; indexId: string }) =>
    ['add-copy-field', params] as const,
};
