import { useCurrentUser } from '../../hooks/projects/project-roles.hook';
import { useProject } from '../../hooks/projects/project.hook';
import { useProjects } from '../../hooks/projects/projects.hook';

export function useHasFullControl(platformId: string) {
  const { data: user } = useCurrentUser(platformId);
  const roles = user?.roles ?? [];
  const powerfullRoles = ['owner', 'admin', 'root'];

  if (roles.some((role) => powerfullRoles.includes(role))) return true;

  return false;
}
export function useIsDeveloper(platformId: string) {
  const { data: user } = useCurrentUser(platformId);
  const roles = user?.roles ?? [];

  if (roles.includes('developer')) return true;

  return false;
}

export function useIsOwner(platformId: string) {
  const { data: user } = useCurrentUser(platformId);
  const roles = user?.roles ?? [];

  if (roles.includes('owner')) return true;

  return false;
}

export function useIsEcommerce(platformId: string) {
  const { data: user } = useCurrentUser(platformId);
  const { data: projects } = useProjects();
  const roles = user?.roles ?? [];

  if (
    roles.includes('owner') &&
    projects?.length == 1 &&
    (platformId.includes('salla_') || platformId.includes('zid_'))
  )
    return true;

  return false;
}

export function useIsViewOnlyUser(platformId: string) {
  const { data: user } = useCurrentUser(platformId);
  const roles = user?.roles ?? [];

  if (roles.length == 1 && roles.includes('view')) return true;

  return false;
}

export function useProjectHasPaymentIssues(platformId: string) {
  const { data: project } = useProject(platformId);

  const invoice_status = project?.subscription?.invoice?.status ?? null;

  return (
    invoice_status == 'open' ||
    invoice_status == 'uncollectible' ||
    project?.subscription?.status == 'subscription required'
  );
}

export function useProjectHasNoSubscription(platformId: string) {
  const { data: project } = useProject(platformId);

  return project?.subscription?.status == 'subscription required';
}
