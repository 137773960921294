export function synonymsTableHeadData(translate: any) {
  return [
    {
      title: `${translate('GROUP')}`,
      key: (row: any) => {
        return row.words[0];
      },
    },
    {
      title: `${translate('SYNONYMS')}`,
      key: (row: any) => {
        return `${row?.words?.slice(0, 3)?.join(', ')}${
          row.words.length > 2 ? '...' : ''
        }`;
        //  row.words.slice(0, 3)?.join(', ')}${row.words.length > 2 ? '...' : ''
      },
    },
  ];
}
