import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LablebSkeleton } from '../skeleton/skeleton';

interface LablebTableLoaderProps {
  tableHeader: any;
}

export function LablebTableLoader(props: LablebTableLoaderProps) {
  const { tableHeader } = props;

  const [translate] = useTranslation();

  return (
    <>
      <div className="w-full  bg-white  rounded-b-xl py-4">
        <table className="w-full ">
          <thead>
            <tr className="border-b-4 ">
              {tableHeader?.map((head: any, index: any) => (
                <td
                  className="pb-3 px-3 whitespace-nowrap   font-bold"
                  key={index}
                >
                  {translate(head.title)}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3]?.map((row: any, rowIndex: number) => (
              <tr className="border-b-2" key={rowIndex}>
                {tableHeader?.map((head: any, index: any) => (
                  <td key={index} className=" py-3 ">
                    <div className="flex justify-center mx-2 items-center">
                      <LablebSkeleton style="w-full h-[20px]" />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
