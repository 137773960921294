import * as Yup from 'yup';

export const permissionsTypes: any = [
  {
    type: 'admin',
    lable: 'ADMIN',
    color: '#8E44AD',
  },
  {
    type: 'view',
    lable: 'VIEWER',
    color: '#D45400',
  },
  {
    type: 'developer',
    lable: 'DEVELOPER',
    color: '#2980B9',
  },
  {
    type: 'owner',
    lable: 'OWNER',
    color: '#15A085',
  },
];

export function usersTableHeadData(translate?: any, permissionsStyle?: any) {
  return [
    {
      title: `${translate('NAME')}`,
      key: 'name',
    },
    {
      title: `${translate('EMAIL')}`,
      key: 'email',
    },
    {
      title: `${translate('PERMISSIONS')}`,
      key: (row: any) => {
        return (
          permissionsStyle(
            permissionsTypes.find(
              (option: any) => option?.type === row?.roles[0]
            )
          ) ?? ''
        );
      },
    },
  ];
}

export const usersValidationSchema = Yup.object({
  email: Yup.string()
    .required('PLAESE_ENTER_EMAIL')
    .email('VALIDATION_EMAIL_INVALID'),
  role: Yup.string().required(),
});

export const usersFormInitiValues = {
  email: '',
  role: 'developer',
};
