import { useTranslation } from 'react-i18next';
import { planList } from '../../../create-project/plans-constants';
import { PlanCard } from '../../components/plan-card';
import { ComparePlansTable } from '../../components/compare-plans-table';

export function PaymentPlansPageLoader() {
  const [translate] = useTranslation();
  return (
    <>
      <div className="w-full">
        <div className="flex items-center mb-5">
          <span className="text-[#6D7B9B] text-[24px]">
            {translate('BILLING')}
          </span>
          <hr className="w-[2px] h-[20px] bg-[#6D7B9B] border-none rounded-sm mx-2" />
          <span className="text-[#030F2C] text-[24px]">
            {translate('PAYMENT_PLANS')}
          </span>
        </div>

        <div className=" flex items-center mb-8 justify-between flex-wrap ">
          {planList.map((plan, index: number) =>
            plan.name == 'FREE_TRIAL' || plan.name == 'CUSTOM' ? null : (
              <PlanCard
                key={index}
                loading
                planInformation={plan}
                currentPlanId={{}}
                onSelect={() => {}}
                needSubscription={false}
              />
            )
          )}
        </div>

        <span className="text-[24px] text-[#030F2C] font-medium mb-[6px]">
          {translate('DETAILS')}
        </span>
        {/* <div className="w-full  my-5"> */}
        <ComparePlansTable loading />
        {/* </div> */}
      </div>
    </>
  );
}
