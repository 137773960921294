import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import searchIcon from '../../assets/icons/search.svg';
import circlePlusIcon from '../../assets/icons/circle-plus.svg';
import { LablebTable } from '../../design-system/table/table';
import { rulesTableHeadData } from './rule.page.constants';

export function RulePageLoader() {
  const [translate] = useTranslation();
  const { projectId, indexId, filedId }: any = useParams();

  return (
    <>
      <>
        <div className="w-full ">
          <div className="w-full flex items-center mb-8 justify-between">
            <div className="flex items-center">
              <span className="text-[22px] text-[#6D7B9B]">
                {translate('SUGGESTION_RULES')}
              </span>
              <hr className="w-[2px] h-[20px] bg-[#6D7B9B] border-none rounded-sm mx-2" />
              <span className=" font-bold text-[20px]">{filedId}</span>
            </div>

            <div className=" flex items-center justify-between">
              <div className="relative mx-3">
                <button className="absolute top-[20%] mx-2">
                  <img src={searchIcon} className="max-w-[22px]" alt="search" />
                </button>

                <input
                  className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                  placeholder={translate('SEARCH_PLACEHOLDER')}
                />
              </div>

              <div className="flex p-2 cursor-pointer rounded-xl bg-primary items-center justify-center ">
                <img src={circlePlusIcon} className="max-w-[25px]" alt="add" />
              </div>
            </div>
          </div>

          <LablebTable
            loading
            tableHeader={rulesTableHeadData(translate, () => {})}
            tableRows={[]}
          />
        </div>
      </>
    </>
  );
}
