import { Switch } from '@headlessui/react';

interface LablebSwitchProps {
  enabled: boolean;
  setEnabled: any;
  disabled?: boolean;
}

export function LablebSwitch(props: LablebSwitchProps) {
  const { enabled, setEnabled, disabled } = props;

  return (
    <div className="rtl:rotate-180">
      <Switch
        disabled={disabled}
        checked={enabled}
        onChange={setEnabled}
        className={`${enabled ? 'bg-[#F5F7F8]' : 'bg-[#F5F7F8]'}
              relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${
            enabled
              ? 'translate-x-6 rtl:-translate-x-6 bg-[#345C75]'
              : 'translate-x-0 bg-[#D2DBEE]'
          }
                pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
}
