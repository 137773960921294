import visaLogo from '../../../assets/icons/visa-logo.svg';
import removeIcon from '../../../assets/icons/trash.svg';
import circlePlusBlue from '../../../assets/icons/circle-plus-blue.svg';
import { useTranslation } from 'react-i18next';
import { LablebSkeleton } from '../../../design-system/skeleton/skeleton';

interface LablebCreditCardProps {
  paymentInfo: any;
  hasCreditCard: boolean;
  setOpen?: any;
  loading?: boolean;
}

export function LablebCreditCard(props: LablebCreditCardProps) {
  const { paymentInfo, hasCreditCard, setOpen, loading } = props;
  const [translate] = useTranslation();

  return (
    <>
      <div className="w-full flex justify-between items-center p-5 bg-white rounded-xl">
        {hasCreditCard ? (
          <>
            <div className="flex w-full items-center">
              <img src={visaLogo} alt={'visa-icon'} />
              {loading ? (
                <div className="flex w-full flex-col justify-between mx-5">
                  <LablebSkeleton style="w-16 h-5 mb-2" />
                  <LablebSkeleton style="w-[80%] h-5" />
                </div>
              ) : (
                <div className="flex flex-col justify-between mx-5">
                  <span className="text-[16px] text-[#6D7B9B]">
                    {paymentInfo?.card?.brand}
                  </span>
                  <span
                    dir="ltr"
                    className="text-[16px] text-[#6D7B9B] "
                  >{`**** **** **** ${paymentInfo?.card?.last_digit}`}</span>
                </div>
              )}
            </div>
            {/* <img
                                src={removeIcon}
                                alt={'remove-icon'}
                                className="cursor-pointer"
                            /> */}
          </>
        ) : (
          <div
            onClick={() => setOpen(true)}
            className="flex cursor-pointer items-center"
          >
            <img src={circlePlusBlue} alt={'remove-icon'} />
            <span className="text-[#345C75] text-[16px] mx-3">
              {translate('ADD_CREDIT_CARD')}
            </span>
          </div>
        )}
      </div>
    </>
  );
}
