import { LablebSlider } from '../../design-system/slider/slider';
import { contentList } from '../../pages/login/login.page.constant';
import useStore from '../../store/store';
import loginImgAr from '../../assets/images/login-img-ar.png';
import loginImgEn from '../../assets/images/login-img-en.png';
import LablebLogo from '../../assets/images/lableb-logo.png';
import { ReactNode } from 'react';

interface RegisterLayoutProps {
  children: ReactNode;
}
export function RegisterLayout(props: RegisterLayoutProps) {
  const { children } = props;
  const currentLanguage = useStore((state) => state.currentLanguage);

  return (
    <>
      <div className="grid  grid-rows-[30%_1fr] grid-cols-1 lg:grid-cols-[45%_55%] lg:grid-rows-1  min-h-screen bg-red-300 lg:flex-row ">
        <div className="flex flex-col px-[8%] md:px-[17%] justify-center bg-white  p-5 order-last lg:order-first">
          <div className="w-full flex max-w-[70%] mb-5">
            <img src={LablebLogo} alt="logo" className="w-[100px] h-[70x] " />
          </div>

          {children}
        </div>

        <div className="bg-[#1F3746] flex flex-col justify-between ">
          <div className="px-10 pt-10">
            <LablebSlider contentList={contentList} />
          </div>

          <div className="w-full hidden lg:flex md:hidden sm:hidden   justify-end ">
            {currentLanguage === 'ar' ? (
              <img src={loginImgAr} className="w-[80%]" alt="alert" />
            ) : (
              <img src={loginImgEn} className="w-[80%]" alt="alert" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
