import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import moment from 'moment';
import { FilterDatePicker } from '../../../../../design-system/filter-date-picker/filter-date-picker';
import searchIcon from '../../../../../assets/icons/search.svg';
import { LablebTable } from '../../../../../design-system/table/table';
import { mostVisitedResultsTableHeadData } from '../../search-analytics.page.constants';

export function MostClickedResultsLoader() {
  const [translate] = useTranslation();

  function reverseUrl(url: any) {
    return <div dir="ltr">{url}</div>;
  }
  return (
    <>
      <div className="w-full flex flex-col justify-between">
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center mb-5 justify-between">
            <div className="flex items-center">
              <span className="text-[#6D7B9B] text-[24px]">
                {translate('SEARCH_ANALYTICS')}
              </span>
              <hr className="w-[2px] h-[20px] bg-[#6D7B9B] border-none rounded-sm mx-2" />
              <span className="text-[#030F2C] text-[24px]">
                {translate('MOST_CLICKED_RESULTS')}
              </span>
            </div>

            <div className="flex items-center">
              <FilterDatePicker />

              <div className="relative  mx-3 w-[180px]">
                <button className="absolute top-[20%] mx-2">
                  <img src={searchIcon} className="max-w-[22px]" alt="search" />
                </button>

                <input
                  className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                  placeholder={translate('SEARCH_PLACEHOLDER')}
                  value={''}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <LablebTable
              loading
              tableHeader={mostVisitedResultsTableHeadData(
                translate,
                reverseUrl
              )}
              tableRows={[]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
