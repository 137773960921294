import { useEffect, useState } from 'react';
import useStore from '../../store/store';

const recordsPerPage = 20;

export function usePaginatedData({ data }: any) {
  const [currentPage, setCurrentPage] = useState(1);
  const analyticsDateFilters = useStore((state) => state.analyticsDateFilters);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(1);
  }, [analyticsDateFilters]);

  return {
    currentRecords,
    currentPage,
    setCurrentPage,
  };
}
