import clsx from 'clsx';
import arrowButtonLeftIcon from '../../assets/icons/arrow-button-left.svg';
import useStore from '../../store/store';
import arrowButtonRightIcon from '../../assets/icons/arrow-button-right.svg';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

interface LablebPaginationProps {
  currentPage: number;
  itemsCount: number;
  recordsPerPage?: number;
  displayedCount: number;
  onPageChange: Dispatch<SetStateAction<number>>;
}

export function LablebPagination(props: LablebPaginationProps) {
  const {
    currentPage,
    onPageChange: setCurrentPage,
    recordsPerPage = 20,
    itemsCount,
    displayedCount,
  } = props;

  const [translate] = useTranslation();

  const currentLanguage = useStore((state) => state.currentLanguage);

  const nPages = Math.ceil(itemsCount / recordsPerPage);

  const pageNumbers = Array.from({ length: nPages }, (_, index) => index + 1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  function generatePagesNumbers() {
    let from = currentPage - 2;
    let to = currentPage + 3;

    if (to > pageNumbers.length) {
      to = pageNumbers.length;
      from = to - 5;
    }

    if (from < 0) {
      from = 0;
      to = pageNumbers.length >= 5 ? 5 : pageNumbers.length;
    }

    from = Math.max(0, from);

    const slicedPages = Array(pageNumbers.length)
      .fill(0)
      .map((_, i) => i + 1)
      .slice(from, to);

    return slicedPages;
  }

  return (
    <>
      <div className="flex justify-between flex-row-reverse items-center w-full">
        <div
          className={clsx('flex items-center', {
            'flex-row-reverse': currentLanguage === 'ar',
          })}
        >
          <img
            onClick={prevPage}
            src={arrowButtonLeftIcon}
            className={clsx('w-[24px] h-[24px] cursor-pointer', {
              hidden: currentPage === 1 || pageNumbers.length == 0,
            })}
            alt="search"
          />

          {generatePagesNumbers()
            ?.filter((page) => page != pageNumbers.length)
            .map((pN) => (
              <div
                key={pN}
                onClick={() => setCurrentPage(pN)}
                className={clsx(
                  'mx-2  w-[24px] h-[24px] flex justify-center items-center cursor-pointer rounded-[4px]',
                  {
                    'bg-primary': currentPage === pN,
                  }
                )}
              >
                <span
                  className={clsx('text-[#6D7B9B] text-[16px]', {
                    'text-[#fff]': currentPage === pN,
                  })}
                >
                  {pN}
                </span>
              </div>
            ))
            .concat(
              <span
                key="divider"
                className={clsx('hidden', {
                  '!inline mx-2': pageNumbers.length > 5,
                })}
              >
                {'...'}
              </span>
            )
            .concat(
              <div
                key={pageNumbers.length}
                onClick={() => setCurrentPage(pageNumbers.length)}
                className={clsx(
                  'mx-2  w-[24px] h-[24px] flex justify-center items-center cursor-pointer rounded-[4px]',
                  {
                    'bg-primary': currentPage === pageNumbers.length,
                  }
                )}
              >
                <span
                  className={clsx('text-[#6D7B9B] text-[16px]', {
                    'text-[#fff]': currentPage === pageNumbers.length,
                  })}
                >
                  {pageNumbers.length}
                </span>
              </div>
            )}

          <img
            onClick={nextPage}
            src={arrowButtonRightIcon}
            className={clsx('w-[24px] h-[24px] cursor-pointer', {
              hidden: currentPage === nPages || pageNumbers.length == 0,
            })}
            alt="search"
          />
        </div>

        <span className="text-[16px] text-[#6D7B9B]">{`${translate('VIEW')} ${
          displayedCount || 0
        } ${translate('FROM')} ${itemsCount || 0} ${translate(
          'ITEM_PG'
        )}`}</span>
      </div>
    </>
  );
}
