import { useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LablebPopover } from '../../../../design-system/popover/popover';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { LablebSwitch } from '../../../../design-system/switch/switch';
import { LablebLineChart } from '../../../../design-system/line-chart/line-chart';
import useStore from '../../../../store/store';
import { useSearchAnalytics } from '../../../../hooks/analytics/search-analytics.hook';

export function AnalyticsClickSuggest() {
  const { projectId }: any = useParams();

  const [translate, { language }] = useTranslation();
  const currentIndex = useStore((state) => state.analyticsIndexFilter);

  const { data, isFetching } = useSearchAnalytics({
    metrics: ['query_sessions_frequencies', 'click_sessions_frequencies'],
    projectId: projectId,
    index: currentIndex,
  });

  const [selectedAnalytics, setSelectedAnalytics] = useState<any>({
    SEARCH: true,
    CLICKS: true,
  });

  const toggleSelectedAnalytics = useCallback(
    ({ name, isActive }: any) =>
      setSelectedAnalytics((oldValue: any) => ({
        ...oldValue,
        [name]: isActive,
      })),
    []
  );

  let searchAndClicksFrequencies = useMemo(
    function calculate() {
      let query_sessions_frequencies = data?.query_sessions_frequencies;
      let click_sessions_frequencies = data?.click_sessions_frequencies;

      /**
       * search and clicks data for line chart component
       */
      if (query_sessions_frequencies && click_sessions_frequencies) {
        return query_sessions_frequencies.map(
          ({ date, freq }: any, index: number) => ({
            name: moment(date).format('MM/D'),
            [translate('SEARCH')]: selectedAnalytics.SEARCH ? freq : null,
            [translate('CLICKS')]: selectedAnalytics.CLICKS
              ? click_sessions_frequencies?.[index]?.freq
              : null,
          })
        );
      }

      return [];
    },
    [
      data?.query_sessions_frequencies,
      data?.click_sessions_frequencies,
      selectedAnalytics.SEARCH,
      selectedAnalytics.CLICKS,
      language,
    ]
  );

  const linesData = useMemo(
    function calcLinesData() {
      return [
        { name: 'SEARCH', color: '#1ABC9C', active: selectedAnalytics.SEARCH },
        {
          name: 'CLICKS',
          color: '#1FB2C6',
          active: selectedAnalytics.CLICKS,
        },
      ];
    },
    [selectedAnalytics.SEARCH, selectedAnalytics.CLICKS, language]
  );

  return (
    <>
      <div className="w-full flex flex-col h-[400px]  bg-white p-5 rounded-lg">
        <div className="w-full mb-4 flex justify-between items-center">
          <span className="text-[20px] font-bold">{translate('SESSIONS')}</span>
          <LablebPopover
            popoverButton={
              <div className="flex  items-center justify-between rounded-xl px-2 py-2 border-[1px] outline-none border-[#D2DBEE]">
                <span className="text-[#35405A] mx-5 text-[16px]">
                  {translate('COMPARISON_ITEMS')}
                </span>
                <ChevronDownIcon
                  className="ml-2 -mr-1 h-5 w-5  text-gray700"
                  aria-hidden="true"
                />
              </div>
            }
          >
            <div className="flex flex-col w-[258px] px-3 py-1 justify-between rounded-sm bg-white">
              {linesData?.map((item: any, index: number) => (
                <div
                  key={index}
                  className="flex items-center justify-between mb-3 w-full"
                >
                  <div className="flex items-center">
                    <div
                      style={{ backgroundColor: `${item?.color}` }}
                      className="rounded-full w-[8px] h-[8px]"
                    ></div>
                    <span className="text-[14px] mx-2 text-[#6D7B9B]">
                      {translate(item?.name)}
                    </span>
                  </div>

                  <LablebSwitch
                    enabled={item?.active}
                    setEnabled={() =>
                      toggleSelectedAnalytics({
                        name: item?.name,
                        isActive: !item?.active,
                      })
                    }
                  />
                </div>
              ))}
            </div>
          </LablebPopover>
        </div>

        <LablebLineChart
          data={searchAndClicksFrequencies}
          linesData={linesData}
          loading={isFetching}
        />
      </div>
    </>
  );
}
