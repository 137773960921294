import fileIcon from '../../assets/icons/file-text.svg';
import headset from '../../assets/icons/headset.svg';
import accountSettingsIcon from '../../assets/icons/account-settings.svg';
import logoutIcon from '../../assets/icons/logout.svg';

export function settingsList(translate: any, navigate: any, projectId: string) {
  return [
    {
      name: `${translate('LABLEB_DOCUMENTS')}`,
      icon: fileIcon,
      alt: 'documents',
      onClick: () => window.open('https://lableb.com/ar/doc'),
    },
    {
      name: `${translate('CONTACT_SUPPORT')}`,
      icon: headset,
      alt: 'support',
      onClick: () => navigate(`/projects/${projectId}/support`),
    },
  ];
}

export function accountList(translate: any, isEcommerce: boolean) {
  return [
    {
      name: `SETTINGS`,
      icon: accountSettingsIcon,
      alt: 'settings',
      isLogout: false,
      hide: isEcommerce,
    },
    {
      name: 'LOGOUT',
      icon: logoutIcon,
      alt: 'logout',
      isLogout: true,
    },
  ];
}
