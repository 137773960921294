import { ChevronDownIcon } from '@heroicons/react/solid';
import projectIcon from '../../../../assets/icons/project-icon.svg';
import { useProjects } from '../../../../hooks/projects/projects.hook';
import useStore from '../../../../store/store';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function ProjectsDropDown() {
  const navigate = useNavigate();
  const [translate] = useTranslation();
  const { data: projects, isLoading } = useProjects();
  let projectsList = projects?.slice(0, 3);
  const currentProject = useStore((state) => state?.currentProject);
  let currentProjectName: any = projects?.find(
    (project) => project.id === currentProject
  );
  const [open, setOpen] = useState<boolean>(false);
  function openHandler() {
    setOpen(!open);
  }
  function showMoreHandler() {
    navigate(`/projects/${currentProject}/all`);
  }
  function viewProjectHandler(item: string) {
    navigate(`/projects/${item}/overview`);
  }

  return (
    <>
      <div className=" rounded-md flex flex-col gap-5 border border-[#D2DBEE] p-2 mx-4">
        <div
          onClick={openHandler}
          className="flex justify-between items-center cursor-pointer"
        >
          <div className="flex  items-center gap-3">
            <img src={projectIcon} className="max-w-[25px]" alt="platform" />
            <span className="text-md  text-primary">
              {currentProjectName?.name
                ? currentProjectName.name
                : currentProjectName?.id}
            </span>
          </div>
          <ChevronDownIcon
            className="ml-2 -mr-1 h-5 w-5 text-gray700"
            aria-hidden="true"
          />
        </div>
        {open ? (
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <span className="text-sm  text-[#303030]">
                {translate('PROJECTS')}
              </span>
              <span
                onClick={showMoreHandler}
                className="text-gray700 cursor-pointer text-xs"
              >
                {translate('SEE_ALL')}
              </span>
            </div>

            <div className="flex flex-col gap-4">
              {projectsList?.map((project: any) => {
                let projectName = project?.name ? project.name : project.id;
                return (
                  <div
                    key={project?.id}
                    onClick={() => viewProjectHandler(project?.id)}
                    className="flex items-center gap-3 hover:bg-[#c5d7ff] cursor-pointer p-1 rounded-md"
                  >
                    <img
                      src={projectIcon}
                      className="max-w-[25px]"
                      alt="platform"
                    />
                    <span className="text-md  text-primary">{projectName}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
