import moment from 'moment';
import create from 'zustand';

let store = (set: any) => ({
  currentLanguage: 'ar',
  changeLang: (lang: any) => set((state: any) => ({ currentLanguage: lang })),

  currentProject: '',
  changeProject: (project: any) =>
    set((state: any) => ({ currentProject: project })),

  currentIndex: '',
  changeIndex: (index: any) => set((state: any) => ({ currentIndex: index })),

  authToken: localStorage.getItem('token') || '',
  setAuthToken: (token: any) => set((state: any) => ({ authToken: token })),

  openErrorMessage: false,
  openErrorMessageHandler: (open: any) =>
    set((state: any) => ({ openErrorMessage: open })),

  openLoadingSpinner: false,
  openLoadingSpinnerHandler: (open: any) =>
    set((state: any) => ({ openLoadingSpinner: open })),

  openAddIndexDialog: false,
  openAddIndexDialogHandler: (open: any) =>
    set((state: any) => ({ openAddIndexDialog: open })),

  purpose: 'search',
  changePurpose: (purpose: string) => set((state: string) => ({ purpose })),

  stringLength: 'long',
  changeStringLength: (stringLength: string) =>
    set((state: string) => ({ stringLength })),

  stringProcessing: 'tokenized',
  changeStringProcessing: (stringProcessing: string) =>
    set((state: string) => ({ stringProcessing })),

  textLanguage: 'any',
  changeTextLanguage: (textLanguage: string) =>
    set((state: string) => ({ textLanguage })),

  floatPrecision: 'normal',
  changeFloatPrecision: (floatPrecision: string) =>
    set((state: string) => ({ floatPrecision })),

  integerPrecision: 'short',
  changeIntegerPrecision: (integerPrecision: string) =>
    set((state: string) => ({ integerPrecision })),

  numberType: 'integer',
  changeNumberType: (numberType: string) =>
    set((state: string) => ({ numberType })),

  autocomplete: true,
  changeAutocomplete: (autocomplete: boolean) =>
    set((state: boolean) => ({ autocomplete })),

  isArray: false,
  changeIsArray: (isArray: boolean) => set((state: boolean) => ({ isArray })),

  addCopyFieldCollectionType: '',
  changeAddCopyFieldCollectionType: (
    addCopyFieldCollectionType: '' | 'suggest'
  ) => set((state: '' | 'suggest') => ({ addCopyFieldCollectionType })),

  editCopyFieldCollectionType: '',
  changeEditCopyFieldCollectionType: (
    editCopyFieldCollectionType: '' | 'suggest'
  ) => set((state: '' | 'suggest') => ({ editCopyFieldCollectionType })),

  copyFieldIndex: '',
  changeCopyFieldIndex: (copyFieldIndex: string) =>
    set((state: string) => ({ copyFieldIndex })),

  ruleId: '',
  changeRuleId: (ruleId: string) => set((state: string) => ({ ruleId })),

  ruleType: '',
  changeRuleType: (ruleType: string) => set((state: string) => ({ ruleType })),

  ruleOpenMode: '',
  changeRuleOpenMode: (ruleOpenMode: string) =>
    set((state: string) => ({ ruleOpenMode })),

  openWarningDialog: false,
  openWarningDialogHandler: (open: any) =>
    set((state: any) => ({ openWarningDialog: open })),

  warningDialogInfo: {
    open: false,
    title: '',
    body: '',
    onAcceptDispatch: () => {},
    onCancelDispatch: () => {},
    hideAcceptButton: false,
  },
  setWarningDialogHandler: (info: any) =>
    set((state: any) => ({ warningDialogInfo: info })),

  closeWarningDialogHandler: () =>
    set((state: any) => ({
      warningDialogInfo: {
        open: false,
        title: '',
        body: '',
        onAcceptDispatch: () => {},
        onCancelDispatch: () => {},
        hideAcceptButton: false,
      },
    })),

  snackbarInfo: {
    open: false,
    message: '',
    severity: '',
    duration: 3,
  },
  setSnackbarInfo: (info: any) => set((state: any) => ({ snackbarInfo: info })),
  closeSnackbar: () =>
    set((state: any) => ({
      snackbarInfo: {
        open: false,
        message: '',
        severity: '',
        duration: 3,
      },
    })),

  analyticsIndexFilter: 'ALL_INDEXS',
  setAnalyticsIndexFilter: (index: any) =>
    set((state: any) => ({ analyticsIndexFilter: index })),

  analyticsPlatformFilter: 'ALL_PLATFORMS',
  setAnalyticsPlatformFilter: (platform: any) =>
    set((state: any) => ({ analyticsPlatformFilter: platform })),

  openDrawerSideNav: false,
  setOpenDrawerSideNav: (open: any) =>
    set((state: any) => ({ openDrawerSideNav: open })),

  sortField: '',
  setSortField: (sort: any) => set((state: any) => ({ sortField: sort })),

  sortDirection: 'desc',
  setSortDirection: (sort: any) =>
    set((state: any) => ({ sortDirection: sort })),

  recommendDocument: {},
  setRecommendDocument: (doc: any) =>
    set((state: any) => ({ recommendDocument: doc })),
  openSimilarDocDialog: false,
  setOpenSimilarDocDialog: (open: any) =>
    set((state: any) => ({ openSimilarDocDialog: open })),

  openDemoHandlerConfigDialog: false,
  setOpenDemoHandlerConfigDialog: (open: any) =>
    set((state: any) => ({ openDemoHandlerConfigDialog: open })),

  currentDemoHandlerConfig: {
    searchHandler: {
      key: 0,
      value: 'default',
    },
    autocompleteHandler: {
      key: 0,
      value: 'suggest',
    },
    recommendHandler: {
      key: 0,
      value: 'recommend',
    },
  },
  setCurrentDemoHandlerConfig: (handler: any) =>
    set((state: any) => ({
      currentDemoHandlerConfig: handler,
    })),

  currentQuery: '',
  setCurrentQuery: (query: any) =>
    set((state: any) => ({ currentQuery: query })),

  analyticsFilters: {
    index: null,
    metricType: 'SEARCH',
  },
  setAnalyticsFilters: (filter: any) =>
    set((state: any) => ({ analyticsFilters: filter })),

  analyticsDateFilters: {
    currentSelect: 'LAST7DAYS',
    from: moment().subtract('7', 'day').toDate(),
    to: new Date(),
  },
  setAnalyticsDateFilters: (filter: any) =>
    set((state: any) => ({ analyticsDateFilters: filter })),
});

const useStore = create(store);

export default useStore;
