import { useTranslation } from 'react-i18next';
import { LablebSkeleton } from '../../design-system/skeleton/skeleton';

export function CreateCopyFieldPageLoader() {
  const [translate] = useTranslation();

  return (
    <>
      <div className="flex w-full  justify-center ">
        <div className="max-w-[580px] w-full flex flex-col">
          <div className="flex flex-col gap-10  p-5 bg-white rounded">
            <span className="text-[24px] text-[#030F2C]  font-bold ">
              {translate('CREATE_A_NEW_COPY_FILED')}
            </span>
            <LablebSkeleton style="w-[90%] h-5" />
            <LablebSkeleton style="w-[70%] h-5" />
            <LablebSkeleton style="w-[60%] h-5" />
            <LablebSkeleton style="w-[100%] h-5" />
            <LablebSkeleton style="w-[80%] h-5" />
            <LablebSkeleton style="w-[40%] h-5" />
            <LablebSkeleton style="w-[100%] h-5" />
            <LablebSkeleton style="w-[80%] h-5" />
            <LablebSkeleton style="w-[40%] h-5" />
            <LablebSkeleton style="w-[100%] h-5" />
          </div>
        </div>
      </div>
    </>
  );
}
