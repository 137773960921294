import clsx from 'clsx';
import { LablebNestedMenu } from '../../design-system/nested-menu/nested-menu';

import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useProjects } from '../../hooks/projects/projects.hook';
import { useProjectIndices } from '../../hooks/indices/indices.hook';
import { useTranslation } from 'react-i18next';
import { sideNavList } from './side-nav-constants';
import { useProject } from '../../hooks/projects/project.hook';
import {
  useHasFullControl,
  useIsDeveloper,
  useIsEcommerce,
  useIsViewOnlyUser,
  useProjectHasNoSubscription,
  useProjectHasPaymentIssues,
} from '../../utils/hooks';

interface sideNavProps {
  open: boolean;
}

export function SideNav(props: sideNavProps) {
  const [translate] = useTranslation();

  const { open } = props;
  const { projectId }: any = useParams();
  const { indexId }: any = useParams();

  const { data: projects } = useProjects();
  let platformsNames = projects?.map((x) => x.id) ?? [];

  let currentPlatform = projectId === undefined ? platformsNames[0] : projectId;

  const indicesResponse = useProjectIndices({ projectId: currentPlatform });
  let indexs = indicesResponse.data?.map((x) => x.id) ?? [];

  let currentIndex = indexId === undefined ? indexs[0] : indexId;

  const { data: project } = useProject(projectId);

  let subscriptionStatus = project?.subscription?.status;

  const isEcommerce = useIsEcommerce(currentPlatform);
  const isDeveloper = useIsDeveloper(currentPlatform);
  const isViewOnly = useIsViewOnlyUser(currentPlatform);
  const hasFullControl = useHasFullControl(currentPlatform);
  const hasPaymentIssues = useProjectHasPaymentIssues(currentPlatform);
  const hasNoSubscription = useProjectHasNoSubscription(currentPlatform);

  return (
    <>
      <div
        className={clsx(
          'duration-500 px-3 pt-5 transition-all min-w-[240px]  bg-white  hidden sm:flex  flex-col items-center  shadow',
          {
            'min-w-[88px]': open,
          }
        )}
      >
        <div
          className={clsx('w-full', {
            'w-fit': open,
          })}
        >
          {sideNavList(
            translate,
            currentPlatform,
            currentIndex,
            isDeveloper,
            isViewOnly,
            hasFullControl,
            hasPaymentIssues,
            hasNoSubscription,
            isEcommerce
          ).map((item: any) => (
            <NavLink end to={item?.path} key={item.title}>
              {({ isActive }) => (
                <div key={item.title}>
                  {!item.hide ? (
                    item.isNestedMenu ? (
                      <LablebNestedMenu
                        title={item.title}
                        icon={!isActive ? item.icon : item.iconOnSelect}
                        path={item.path}
                        links={item?.links(
                          translate,
                          currentPlatform,
                          currentIndex,
                          isEcommerce
                        )}
                        isSideNavOpen={open}
                      />
                    ) : (
                      <div
                        className={clsx(
                          'flex items-center my-1 justify-between w-full px-3 py-3 rounded-xl cursor-pointer ',
                          {
                            'bg-primary': isActive,
                            'bg-[#f56a6a]': item?.warn,
                          }
                        )}
                      >
                        <div className="flex items-center">
                          <img
                            src={!isActive ? item.icon : item.iconOnSelect}
                            className="max-w-[25px] text-slate-50"
                            alt={item.alt}
                          />
                          {open === false ? (
                            <span
                              className={clsx(
                                'text-l mx-3 whitespace-nowrap text-primary',
                                {
                                  'text-slate-100': isActive,
                                  'text-white': item?.warn,
                                }
                              )}
                            >
                              {item.title}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
}
