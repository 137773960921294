import Slider from 'react-slick';
import { sliderSettings } from './slider.constants';
import SliderWrapper from './slider.style';
import { useTranslation } from 'react-i18next';

interface LablebSliderProps {
  contentList: any;
}

export function LablebSlider(props: LablebSliderProps) {
  const { contentList } = props;
  const [translate] = useTranslation();
  return (
    <>
      <SliderWrapper>
        <Slider className="text-right ltr:text-left" {...sliderSettings}>
          {contentList.map((item: any) => (
            <div
              key={item.title}
              className="flex w-full px-4 mb-3 md:px-12" //mb-5 lg:mb-5 md:mb-5
            >
              <div className="flex w-full flex-col">
                <span className="text-[17px] lg:text-[21px]   text-white font-bold">
                  {translate(item.title)}
                </span>
                <p className=" pt-4 lg:pt-5 md:pt-5 text-gray500 text-[14px] lg:text-[16px] md:text-[16px] ">
                  {translate(item.content)}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    </>
  );
}
