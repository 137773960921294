import { ChevronLeftIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { zeroVisitQueriesTableHeadData } from '../search-analytics.page.constants';
import { LablebTable } from '../../../../design-system/table/table';
import clsx from 'clsx';
import useStore from '../../../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchAnalytics } from '../../../../hooks/analytics/search-analytics.hook';

export function ZeroVisitQueries() {
  const { projectId }: any = useParams();
  const currentLanguage = useStore((state) => state.currentLanguage);
  const navigate = useNavigate();
  const [translate] = useTranslation();
  const currentIndex = useStore((state) => state.analyticsIndexFilter);

  const { data, isFetching } = useSearchAnalytics({
    metrics: ['zero_visit_queries'],
    projectId: projectId,
    index: currentIndex,
  });

  return (
    <>
      <div className="w-full flex flex-col ">
        <div className="w-full flex items-center justify-between h-[76px] bg-white rounded-t-xl p-3 border-b-4">
          <span className="text-[20px] font-bold">
            {translate('SEARCH_WITHOUT_CLICKING')}
          </span>
          <div className="flex items-center cursor-pointer">
            <span
              onClick={() =>
                navigate(
                  `/projects/${projectId}/search-analytics/zero-visit-queries`
                )
              }
              className="text-[16px] mx-3 text-[#6D7B9B]"
            >
              {translate('VIEW_MORE')}
            </span>
            <ChevronLeftIcon
              className={clsx('ml-2 -mr-1 h-5 w-5 text-[#6D7B9B]', {
                'rotate-180': currentLanguage === 'en',
              })}
            />
          </div>
        </div>
        <LablebTable
          loading={isFetching}
          tableHeader={zeroVisitQueriesTableHeadData()}
          tableRows={data?.zero_visit_queries?.slice(0, 7)}
        />
      </div>
    </>
  );
}
