import { useQuery } from 'react-query';
import { lablebClient } from '../../api-client';

export function useProjects() {
  return useQuery(
    ['projects'],
    function fetchProjects() {
      return lablebClient.platforms.fetchPlatforms({});
    },
    {
      select: (response) => response?.response?.projects,
    }
  );
}
