import LablebLogo from '../../assets/images/lableb-logo.png';

export function ForgetPasswordPageLoader() {
  return (
    <>
      <div className="flex w-full h-[100vh] px-2 justify-center items-center">
        <div className="flex flex-col items-center max-w-[400px] w-full">
          <img src={LablebLogo} alt="logo" className="w-[140px] h-[74px]" />
          <div className="flex flex-col  items-center w-full mt-[81px]">
            <span className="font-bold text-center text-[34px] text-[#030F2C] mb-4">
              {'هل نسيت كلمة المرور؟'}
            </span>
            <span className="text-[16px] text-center text-[#6D7B9B]">
              {'سنقوم بإعادة تعيين كلمة المرور من خلال بريدك الإلكتروني '}
            </span>
            <div className="flex flex-col w-full  my-5">
              <span className="text-[14px] mb-2">{'البريد الإلكتروني'}</span>
              <input
                type="email"
                className="rounded-xl py-2 px-5 h-[56px] border-[1px] border-[#c7c7c7] outline-none"
                readOnly
              />
              <button
                type="submit"
                className=" rounded-xl py-3 h-[56px]  mt-8 flex justify-center items-center bg-primary"
              >
                <span className="text-[16px] font-bold text-white mx-7">
                  {'متابعة'}
                </span>
              </button>
            </div>
            <span className="font-bold text-[#6D7B9B] text-base cursor-pointer">
              {'العودة إلى تسجيل الدخول'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
