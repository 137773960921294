import { useTranslation } from 'react-i18next';
import { FilterDatePicker } from '../../../design-system/filter-date-picker/filter-date-picker';
import { useState } from 'react';
import moment from 'moment';
import autocompleteIcon from '../../../assets/icons/autocomplete-icon.svg';
import ctrIcon from '../../../assets/icons/ctr-icon.svg';
import converstionRateIcon from '../../../assets/icons/converstion-rate-icon.svg';
import hitsRequestsIcon from '../../../assets/icons/hits-requests-icon.svg';
import noClickRateIcon from '../../../assets/icons/no-click-rate-icon.svg';
import noResultsRateIcon from '../../../assets/icons/no-results-rate-icon.svg';
import searchAnalyticsIcon from '../../../assets/icons/search-analytics-icon.svg';
import { AnalyticsCard } from '../../../design-system/analytics-card/analytics-card';
import { RequestsFrequency } from './components/requests-frequency';
import { AnalyticsClickSuggest } from './components/analytics-click-suggest';
import { MostClickedResults } from './components/most-clicked-results';
import { AnalyticsTable } from '../../../design-system/analytics-table/analytics-table';
import { ZeroVisitQueries } from './components/zero-visit-queries';
import totalRequestIcon from '../../../assets/icons/total-request-icon.svg';

import { useParams } from 'react-router-dom';
export function SearchAnalyticsPageLoader() {
  const [translate] = useTranslation();

  const { projectId }: any = useParams();
  const overviewAnalyticsData = [
    {
      title: 'TOTAL_REQUESTS',
      count: 0,
      icon: totalRequestIcon,
    },
    {
      title: 'SEARCH_REQUESTS',
      count: 0,
      icon: searchAnalyticsIcon,
    },
    {
      title: 'AUTOCOMPLETE',
      count: 0,
      icon: autocompleteIcon,
    },
    { title: 'HITS_REQUESTS', count: 0, icon: hitsRequestsIcon },

    {
      title: 'CTR',
      count: 0,
      icon: ctrIcon,
      info: 'CTR_INFO',
    },
    { title: 'NO_CLICK_RATE', count: 0, icon: noClickRateIcon },
    { title: 'NO_RESULTS_RATE', count: 0, icon: noResultsRateIcon },
  ];

  const MostFrequentTableList = [
    {
      name: 'SEARCH',
      path: `/projects/${projectId}/search-analytics/search`,
      tableHead: [],
      metric: 'frequent_search_custom_range',
    },
    {
      name: 'AUTOCOMPLETE',
      path: `/projects/${projectId}/search-analytics/search`,
      tableHead: [],
      metric: 'frequent_autocomplete_custom_range',
    },
  ];

  const ZeroResultsTableList = [
    {
      name: 'SEARCH',
      path: `/projects/${projectId}/search-analytics/zero-results`,
      tableHead: [],
      metric: 'zero_results_searches',
    },
    {
      name: 'AUTOCOMPLETE',
      path: `/projects/${projectId}/search-analytics/zero-results`,
      tableHead: [],
      metric: 'zero_results_autocompletes',
    },
  ];

  return (
    <>
      <>
        <div className="w-full flex flex-col">
          <div className="flex w-full items-center mb-8 justify-between">
            <span className="text-[20px] font-bold">
              {translate('SEARCH_ANALYTICS')}
            </span>
            <FilterDatePicker />
          </div>

          <div className="w-full  mb-5 grid gap-2 grid-cols-cards">
            {overviewAnalyticsData?.map((item, index) => (
              <AnalyticsCard
                key={index}
                title={item?.title}
                value={item?.count}
                cardIcon={item?.icon}
                info={item.info}
                loading
              />
            ))}
          </div>

          <div className="flex flex-col gap-5">
            <RequestsFrequency />

            <AnalyticsClickSuggest />

            <MostClickedResults />

            <AnalyticsTable
              tableName={'MOST_FREQUENT_SEARCH'}
              analyticsTableList={MostFrequentTableList}
            />

            <div className="w-full flex justify-between">
              <div className="w-[49%]">
                <AnalyticsTable
                  tableName={'SEARCH_WITHOUT_RESULTS'}
                  analyticsTableList={ZeroResultsTableList}
                />
              </div>
              <div className="w-[49%]">
                <ZeroVisitQueries />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
