import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import { useNavigate, useParams } from 'react-router-dom';
import circlePlusIcon from '../../../assets/icons/circle-plus.svg';
import { LablebTable } from '../../../design-system/table/table';
import { schemaTableHeadData } from '../schema.page.constants';
import { useFields } from '../../../hooks/schema/fields-fetch.hook';
import useStore from '../../../store/store';
import { useFieldDelete } from '../../../hooks/schema/field-delete.hook';
import { useRemoveDefaultSuggestRules } from '../../../hooks/suggest-rules/defaults-remove-suggest-rules.hook';

interface SchemaFieldsSectionProps {
  fieldTypeStyle: any;
  viewField: any;
  schemaResponse: any;
  loading?: boolean;
}

export function SchemaFieldsSection(props: SchemaFieldsSectionProps) {
  const { fieldTypeStyle, viewField, loading, schemaResponse } = props;
  const { projectId, indexId }: any = useParams();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const [searchFildes, setSearchFildes] = useState<string>('');

  function searchFildesHandler(event: any) {
    setSearchFildes(event.target.value);
  }
  function openCreateFieldPage() {
    navigate(`/projects/${projectId}/indices/${indexId}/schema/fields/create`);
  }
  const setWarningDialogHandler = useStore(
    (state) => state.setWarningDialogHandler
  );
  const {
    mutateAsync: removeDefaultSuggestRulesAsync,
    isLoading: suggestRuleDeleteLoading,
  } = useRemoveDefaultSuggestRules({
    projectId,
    indexId,
  });

  const schemaFields =
    schemaResponse?.fields?.filter((field: any) => !field.inherit) ?? [];

  const { mutateAsync: deleteField } = useFieldDelete({
    projectId,
    indexId,
    fieldName: 'field-name',
  });

  async function removeField(field: any) {
    await deleteField({
      indexId,
      fieldName: field?.name,
    });
    // if (field.suggest_rules.has_filter || field.suggest_rules.has_navigational)
    //   await removeDefaultSuggestRulesAsync(field.name);
  }

  return (
    <>
      <div>
        <div className="w-full flex items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">
            {translate('SCHEMA_FIELDS')}
          </span>
          <div className=" flex items-center justify-between">
            <div className="relative mx-3">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[22px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                value={searchFildes}
                onChange={searchFildesHandler}
              />
            </div>

            {/* <div className="flex p-2 cursor-pointer rounded-xl mx-3 bg-white items-center justify-center border-[1px] border-[#c7c7c7]">
            <img src={filterIcon} className="max-w-[25px]" alt="filter" />
          </div> */}

            <div
              onClick={openCreateFieldPage}
              className="flex p-2 cursor-pointer rounded-xl bg-primary items-center justify-center "
            >
              <img src={circlePlusIcon} className="max-w-[25px]" alt="add" />
            </div>
          </div>
        </div>

        <LablebTable
          loading={loading}
          tableHeader={schemaTableHeadData(translate, fieldTypeStyle)}
          tableRows={schemaFields?.filter((item: any) => {
            if (searchFildes === '') {
              return item;
            } else if (
              item?.name.toLowerCase().includes(searchFildes.toLowerCase())
            ) {
              return item;
            }
            return null;
          })}
          hasPermissionsList
          deleteHandler={(field: any) => {
            //   setCurrentField(field);
            //   setCurrentFieldType('field');
            setWarningDialogHandler({
              open: true,
              title: 'DELETE_SCHEMA_FIELD_WARNING_TITLE',
              body: 'DELETE_SCHEMA_FIELD_WARNING_DESCRIPTION',
              onAcceptDispatch: () => removeField(field),
              onCancelDispatch: {},
            });
          }}
          viewHandler={viewField}
        />
      </div>
    </>
  );
}
