import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './dubai-font.css';
import './sf-font.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import reportWebVitals from './reportWebVitals';
import { RootRouter } from './routing/root.router';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { TranslationsProvider } from './translations/translations.provider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { LablebWarningDialog } from './design-system/warning-dialog/warning-dialog';
import { LablebSnakbar } from './design-system/snackbar/snakbar';

// import axios from 'axios';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIP_PK));

const reactQueryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: globalOnError,
  }),
  mutationCache: new MutationCache({
    onError: globalOnError,
  }),
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});
export function globalOnError(error: any) {
  // if (axios.isAxiosError(error)) {
  //   let errorData: any = error?.response?.data;
  //   console.log(errorData)
  // }
}

root.render(
  <React.StrictMode>
    <QueryClientProvider client={reactQueryClient}>
      <TranslationsProvider>
        <Elements stripe={stripePromise}>
          <LablebWarningDialog />
          <LablebSnakbar />
          <RootRouter />
        </Elements>
      </TranslationsProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
