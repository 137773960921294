import { useTranslation } from 'react-i18next';
import supportImg from '../../assets/images/support_img.svg';
import mailIcon from '../../assets/icons/mail.svg';
import * as Yup from 'yup';
import { LablebButton } from '../../design-system/button/button';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSupportMessage } from '../../hooks/support/support.hook';
import { Formik, Field } from 'formik';
import clsx from 'clsx';
import useStore from '../../store/store';
import { useProfile } from '../../hooks/me/profile.hook';
export function SupportPage() {
  const { projectId }: any = useParams();
  const [translate] = useTranslation();
  const setSnackbarInfo = useStore((state) => state?.setSnackbarInfo);
  const { data: profile, isLoading: fetchLoading }: any = useProfile();
  const { mutateAsync: sendSupportMessage } = useSupportMessage();

  async function sendMessagehandler(values: any, { resetForm }: any) {
    await sendSupportMessage({
      projectId,
      message: values.message,
      userName: profile?.name,
      email: profile?.email,
    });
    resetForm();
    setSnackbarInfo({
      open: true,
      message: 'REQUEST_SEND_SUCCESS',
      severity: 'success',
    });
  }
  function mailHandler() {
    window.open('mailto:support@lableb.com');
  }
  return (
    <div className="  flex flex-col w-full  h-full">
      <div className="w-full flex flex-col mb-8 gap-1">
        <span className="text-[20px] font-bold">{translate('SUPPORT')}</span>
        <span className="text-[17px] ">{translate('SUPPORT_DES')}</span>
      </div>
      <div className="flex flex-col lg:flex-row items-center  gap-3 ">
        <div className="flex flex-col items-center w-full lg:w-1/2 gap-3">
          <Formik
            onSubmit={sendMessagehandler}
            initialValues={{ message: '' }}
            validationSchema={Yup.object({
              message: Yup.string().required(),
            })}
            enableReinitialize
          >
            {(formik) => (
              <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="flex flex-col w-full h-full gap-3">
                  <span className="font-bold text-gray-400">
                    {translate('PROBLEM_DESCRIPTION')}
                  </span>
                  <Field
                    as="textarea"
                    id="message"
                    name="message"
                    placeholder={translate('WRITE_PROBLEM_DESCRIPTION')}
                    rows={10}
                    className={clsx(
                      'rounded-lg outline-none h-full p-3 border-[2px]  ',
                      {
                        'border-red-600':
                          formik.errors.message && formik.touched.message,
                      }
                    )}
                  />
                  <LablebButton type="submit">{translate('SEND')}</LablebButton>
                </div>
              </form>
            )}
          </Formik>
          <hr className="w-[90%] rounded-lg bg-gray-300 h-1 my-2" />
          <span className="text-center text-sm font-bold text-primary">
            {translate('OR_VIA_EMAIL')}
          </span>
          <div
            onClick={mailHandler}
            className="w-full cursor-pointer justify-center flex items-center gap-3 rounded-lg text-primary text-center p-5 shadow-lg border-primary border-[2px] "
          >
            <span className="font-bold text-sm">{'support@lableb.com'}</span>
            <img src={mailIcon} width={25} height={25} alt="mail" />
          </div>
        </div>

        <div className="flex flex-col w-full lg:w-1/2">
          <img
            src={supportImg}
            alt="supportImg"
            className="object-cover w-full h-[400px] "
          />
        </div>
      </div>
    </div>
  );
}
