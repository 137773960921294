export const planList = [
  {
    id: 2,
    name: `CUSTOM`,
    description: 'CUSTOM_PLAN_DESCRIPTION',
    features: [
      { name: 'INDEX_DOCS', value: 'UNLIMITED' },
      { name: 'QUERY_LIMIT', value: 'UNLIMITED' },
      { name: 'ANALYITC_INTERVAL', value: 90 },
      { name: 'SYNONYMS_COUNT', value: 'UNLIMITED' },
      { name: 'USERS_LIMIT', value: 'UNLIMITED' },
    ],
  },
  {
    id: 100,
    name: `FREE_TRIAL`,
    description: 'FREE_TRIAL_FOR_14',
    features: [
      { name: 'INDEX_DOCS', value: 1000000 },
      { name: 'QUERY_LIMIT', value: 2000000 },
      { name: 'ANALYITC_INTERVAL', value: 14 },
      { name: 'SYNONYMS_COUNT', value: 10000 },
      { name: 'USERS_LIMIT', value: 20 },
    ],
  },
  {
    id: 1,
    name: `FREE_PLAN`,
    description: 'FREE_PLAN_DESCRIPTION',
    price: '$0',
    features: [
      { name: 'INDEX_DOCS', value: 1000 },
      { name: 'QUERY_LIMIT', value: 5000 },
      { name: 'ANALYITC_INTERVAL', value: 7 },
      { name: 'SYNONYMS_COUNT', value: 100 },
      { name: 'USERS_LIMIT', value: 2 },
    ],
  },
  {
    id: 3,
    name: `STARTED_PLAN`,
    description: 'STARTED_PLAN_DESCRIPTION',
    price: '$29',
    features: [
      { name: 'INDEX_DOCS', value: 5000 },
      { name: 'QUERY_LIMIT', value: 30000 },
      { name: 'ANALYITC_INTERVAL', value: 30 },
      { name: 'SYNONYMS_COUNT', value: 1000 },
      { name: 'USERS_LIMIT', value: 10 },
    ],
  },
  {
    id: 4,
    name: `PRO_PLAN`,
    description: 'PRO_PLAN_DESCRIPTION',
    price: '$99',
    features: [
      { name: 'INDEX_DOCS', value: 50000 },
      { name: 'QUERY_LIMIT', value: 300000 },
      { name: 'ANALYITC_INTERVAL', value: 90 },
      { name: 'SYNONYMS_COUNT', value: 10000 },
      { name: 'USERS_LIMIT', value: 10 },
    ],
  },
  {
    id: 6,
    name: `ENTERPRICE_PLAN`,
    description: 'ENTERPRICE_PLAN_DESCRIPTION',
    price: '$499',
    features: [
      { name: 'INDEX_DOCS', value: 1000000 },
      { name: 'QUERY_LIMIT', value: 2000000 },
      { name: 'ANALYITC_INTERVAL', value: 90 },
      { name: 'SYNONYMS_COUNT', value: 10000 },
      { name: 'USERS_LIMIT', value: 20 },
    ],
  },
];
