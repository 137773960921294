import clsx from 'clsx';
import { ReactNode } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useIsFetching, useIsMutating } from 'react-query';

interface LablebButtonProps {
  children?: ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'reset' | 'submit' | undefined;
}

export function LablebButton(props: LablebButtonProps) {
  const { children, style, disabled, onClick, type } = props;
  const isMutating = useIsMutating();
  let isLoading = Boolean(isMutating);

  return (
    <>
      <button
        type={type}
        disabled={isLoading || disabled}
        style={{
          ...style,
        }}
        onClick={onClick}
        className={clsx(
          `rounded-xl py-[10px] px-4 max-h-[56px] flex items-center justify-center min-w-[136px]  text-[16px]  font-bold text-white bg-primary`,
          {
            'opacity-70': isLoading,
          }
        )}
      >
        {children}
        {isLoading ? (
          <div className=" rtl:mr-4 ltr:ml-4">
            <TailSpin color="#00BFFF" height={23} width={23} />
          </div>
        ) : null}
      </button>
    </>
  );
}
