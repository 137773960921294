import { useTranslation } from "react-i18next";
import searchIcon from '../../assets/icons/search.svg';
import circlePlusIcon from '../../assets/icons/circle-plus.svg';
import { useState } from "react";
import { LablebTable } from "../../design-system/table/table";
import { indiciesTableHeadData } from "./indices.page.constants";
import indexIcon from '../../assets/icons/index-icon.svg';

export function IndicesPageLoader() {

  const [searchIndexs, setSearchIndexs] = useState<string>('');
  function searchIndexHandler(event: any) {
    setSearchIndexs(event.target.value);
  }

  const [translate] = useTranslation()
  return (
    <>
      <div className="w-full">
            <div className="w-full flex items-center mb-8 justify-between">
              <span className="text-[20px] font-bold">
                {translate('INDEXS')}
              </span>
              <div className=" flex items-center justify-between">
                <div className="relative mx-3">
                  <button className="absolute top-[20%] mx-2">
                    <img
                      src={searchIcon}
                      className="max-w-[22px]"
                      alt="search"
                    />
                  </button>

                  <input
                    className="w-full rounded-xl py-2 px-10  border-[1px] border-[#c7c7c7] outline-none"
                    placeholder={translate('SEARCH_PLACEHOLDER')}
                    value={searchIndexs}
                    onChange={searchIndexHandler}
                  />
                </div>

             

                <div
                  className="flex p-2 cursor-pointer rounded-xl bg-primary items-center justify-center "
                >
                  <img
                    src={circlePlusIcon}
                    className="max-w-[25px]"
                    alt="add"
                  />
                </div>
              </div>
            </div>

            <LablebTable
              tableHeader={indiciesTableHeadData(translate)}
              tableRows={[]}
              loading
      
             
            />
          </div>
    </>
  );
}
