import { ChevronDownIcon } from '@heroicons/react/solid';
import { LablebPopover } from '../../../design-system/popover/popover';
import projectIcon from '../../../assets/icons/project-icon.svg';
import searchIcon from '../../../assets/icons/search.svg';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import { useProjects } from '../../../hooks/projects/projects.hook';
import useStore from '../../../store/store';
import { LablebSkeleton } from '../../../design-system/skeleton/skeleton';
import clsx from 'clsx';

export function ProjectsPopover() {
  const { projectId }: any = useParams();
  const [searchProjects, setSearchProjects] = useState<string>('');
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { data: projects, isLoading } = useProjects();
  let platformsNames = projects?.map((x: any) => x?.id) ?? [];
  let currentPlatform = projectId === undefined ? platformsNames[0] : projectId;
  function searchProjectHandler(event: any) {
    setSearchProjects(event.target.value);
  }
  const setAnalyticsIndexFilter = useStore(
    (state) => state?.setAnalyticsIndexFilter
  );
  const currentProject = useStore((state) => state?.currentProject);
  const changeProject = useStore((state) => state.changeProject);
  let currentProjectName: any = projects?.find(
    (project) => project.id === currentProject
  );
  useEffect(() => {
    changeProject(currentPlatform);

    //eslint-disable-next-line
  }, [currentPlatform, projects]);

  function showMoreHandler() {
    navigate(`/projects/${currentPlatform}/all`);
  }
  return (
    <>
      <LablebPopover
        popoverButton={
          <div className="flex items-center justify-center rounded-md px-2 py-1 border-[1px] outline-none mx-4 border-[#c7c7c7]">
            <div className="flex items-center">
              <img src={projectIcon} className="max-w-[25px]" alt="platform" />
              {isLoading ? (
                <LablebSkeleton style="w-20 h-5 mx-3" />
              ) : (
                <span className="text-sm whitespace-nowrap mx-3 text-primary">
                  {currentProjectName?.name
                    ? currentProjectName.name
                    : currentProjectName?.id}
                </span>
              )}
            </div>

            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-gray700"
              aria-hidden="true"
            />
          </div>
        }
      >
        <div className="flex flex-col min-w-[350px]">
          <div className="flex flex-col box-border p-1">
            <div className="relative">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[25px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={t('SEARCH_PLACEHOLDER')}
                value={searchProjects}
                onChange={searchProjectHandler}
              />
            </div>

            <div className="w-full my-4">
              <div className="w-full mb-3 flex items-center justify-between">
                <p>{t('PROJECTS')}</p>

                <Popover.Button>
                  <p
                    onClick={showMoreHandler}
                    className="text-gray700 cursor-pointer text-sm"
                  >
                    {t('SEE_ALL')}
                  </p>
                </Popover.Button>
              </div>

              <div className="flex flex-col">
                {projects
                  ?.filter((item: any) => {
                    if (searchProjects === '') {
                      return item;
                    } else if (
                      item.name
                        ?.toLowerCase()
                        .includes(searchProjects.toLowerCase()) ||
                      item.id
                        .toLowerCase()
                        .includes(searchProjects.toLowerCase()) ||
                      item?.website
                        ?.toLowerCase()
                        ?.includes(searchProjects.toLowerCase())
                    ) {
                      return item;
                    }
                    return null;
                  })
                  .map((item: any, index) => {
                    let projectName = item?.name ? item.name : item.id;
                    if (index < 3) {
                      return (
                        <Popover.Button key={item?.id}>
                          <div
                            key={item?.id}
                            onClick={() => (
                              changeProject(item?.id),
                              setAnalyticsIndexFilter('ALL_INDEXS'),
                              navigate(`/projects/${item?.id}/overview`)
                            )}
                            className={clsx(
                              'flex items-center cursor-pointer p-2 min-w-[200px] rounded-lg hover:bg-[#c5d7ff]',
                              {
                                'bg-[#f56a6a] hover:bg-[#f99292]':
                                  item?.subscription?.invoice?.status ==
                                    'open' ||
                                  item?.subscription?.invoice?.status ==
                                    'uncollectible' ||
                                  item?.subscription?.status ==
                                    'subscription required',
                              }
                            )}
                          >
                            <img
                              src={projectIcon}
                              className="max-w-[25px]"
                              alt={item.alt}
                            />

                            <p
                              className={clsx(
                                'text-sm font-medium px-2 text-gray-900',
                                {
                                  'text-[#f5f5f5]':
                                    item?.subscription?.invoice?.status ==
                                      'open' ||
                                    item?.subscription?.invoice?.status ==
                                      'uncollectible' ||
                                    item?.subscription?.status ==
                                      'subscription required',
                                }
                              )}
                            >
                              {projectName}
                            </p>
                          </div>
                        </Popover.Button>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
          </div>

          {/* <hr className="w-full h-[2px] bg-gray600" /> */}
          {/* <Popover.Button>
                  <button
                    onClick={() => navigate(`/projects/create`)}
                    className="w-full p-4 flex items-center justify-center"
                  >
                    <img
                      src={squarePlusIcon}
                      className="max-w-[25px]"
                      alt="add"
                    />

                    <p className="mx-3 text-primary font-medium">
                      {t('CREATE_NEW_PROJECTS')}
                    </p>
                  </button>
                </Popover.Button> */}
        </div>
      </LablebPopover>
    </>
  );
}
