import { Popover, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useRef } from 'react';

interface LablebInfoPopoverProps {
  children: ReactNode;
  popoverButton: ReactNode;
}

export function LablebInfoPopover(props: LablebInfoPopoverProps) {
  const { children, popoverButton } = props;
  let buttonRef = useRef<any>(null);

  function onHover(open: any) {
    if (open) return;
    return buttonRef.current?.click();
  }
  function onLeave(open: any) {
    if (!open) return;
    return buttonRef.current?.click();
  }

  return (
    <>
      <Popover className="relative ">
        {({ open }) => (
          <>
            <Popover.Button
              ref={buttonRef}
              className=" flex items-center outline-none"
              onMouseEnter={() => onHover(open)}
              onMouseLeave={() => onLeave(open)}
            >
              {popoverButton}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute mt-3 z-50  px-2 ">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className=" bg-white p-2 flex flex-col ">{children}</div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
