import { useQuery, useQueryClient } from 'react-query';
import { AnalyticsMetrics, AnalyticsResponse } from '@lableb/api-client';
import { analyticsKeys } from './analytics.keys';
import {
  lablebAnalyticsClient,
  LABLEB_API_DATE_FORMAT,
} from '../../api-client';
import axios from 'axios';
import useStore from '../../store/store';
import moment from 'moment';

export interface UseSearchAnalyticsParams {
  from?: any;
  to?: any;
  projectId: string;
  metrics: any;
  index?: string;
  itemId?: string;
  query?: string;
  limit?: number;
  page?: number;
}

const prevValues: any = {};

/**
 * this hook make a closure around previous values in order to cancel prev requests
 * when new requests arrives
 * */
export function useSearchAnalytics(params: UseSearchAnalyticsParams) {
  const { from, to, projectId, metrics, index, itemId, query, limit, page } =
    params;
  const authToken = useStore((state) => state.authToken);
  const analyticsPlatformFilter = useStore(
    (state) => state.analyticsPlatformFilter
  );
  const analyticsDateFilters: any = useStore(
    (state) => state.analyticsDateFilters
  );

  let key = metrics.join(',');
  if (!prevValues[key]) prevValues[key] = { metrics: [], from: '', to: '' };

  return useQuery(
    analyticsKeys.searchAnalytics({
      metrics,
      from: from ? from : analyticsDateFilters.from,
      to: to ? to : analyticsDateFilters.to,
      projectId,
      index,
      page,
      query,
      itemId,
      platform: analyticsPlatformFilter,
    }),

    function fetchSearchAnalyticsFreq() {
      // if (prevValues[key]) {
      //   let prevFrom = prevValues[key].from;
      //   let prevTo = prevValues[key].to;
      //   let prevMetrics = prevValues[key].metrics.slice();

      //   if (
      //     prevFrom &&
      //     prevTo &&
      //     prevMetrics.length &&
      //     metrics.join(',') == prevMetrics.join(',')
      //   ) {
      //     queryClient.cancelQueries(
      //       analyticsKeys.searchAnalytics({
      //         metrics: prevMetrics,
      //         from: prevFrom,
      //         to: prevTo,
      //         projectId,
      //       })
      //     );
      //   }
      //   prevValues[key] = { from, to, metrics: metrics.slice() };
      // }

      if (key) {
        return axios(
          `${process.env.REACT_APP_ANALYTICS_API}/v2/projects/${projectId}/analytics`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            params: {
              metrics: key,
              from: from
                ? from
                : analyticsDateFilters.from === ''
                ? ''
                : moment(analyticsDateFilters.from).format(
                    LABLEB_API_DATE_FORMAT
                  ),
              to: to
                ? to
                : analyticsDateFilters.to === ''
                ? ''
                : moment(analyticsDateFilters.to).format(
                    LABLEB_API_DATE_FORMAT
                  ),
              platformId: projectId,
              index: index === 'ALL_INDEXS' ? null : index,
              item_id: itemId ? itemId : null,
              limit: limit ? limit : 200,
              page: page || page == 0 ? page : null,
              query: query ? query : null,
              request_source:
                analyticsPlatformFilter === 'ALL_PLATFORMS'
                  ? null
                  : analyticsPlatformFilter,
            },
          }
        );
      }

      // return lablebAnalyticsClient.searchAnalytics.fetchAnalytics({
      //     metrics,
      //     from,
      //     to,
      //     platformId: projectId,
      //     signal,
      // });
    },

    {
      select: function extractAnalyticsResponse(response: any): any {
        return response.data.response.analytics;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
}
