import { SchemaField, FieldType } from '@lableb/api-client';

interface BuildFieldOptionsProps {
  dataType: (typeof FieldType)[number];
  isSearchable: boolean;
}

interface BuildFieldOptionsReturnType {
  is_store: boolean;
  is_filter: boolean;
  is_search: boolean;
}

export const fieldTypeArray: any = [
  {
    value: 1,
    key: 'TYPE_int',
    fieldType: 'int',
    lable: 'Number',
    color: '#15A085',
  },
  {
    value: 2,
    key: 'TYPE_boolean',
    fieldType: 'boolean',
    lable: 'Boolean',
    color: '#345C75',
  },
  {
    value: 3,
    key: 'TYPE_string',
    fieldType: 'string',
    lable: 'String',
    color: '#E67E22',
  },
  {
    value: 4,
    key: 'TYPE_double',
    fieldType: 'double',
    lable: 'Double',
    color: '#345C75',
  },
  {
    value: 5,
    key: 'TYPE_long',
    fieldType: 'long',
    lable: 'Long',
    color: '#345C75',
  },
  {
    value: 6,
    key: 'TYPE_float',
    fieldType: 'float',
    lable: 'Float',
    color: '#345C75',
  },
  {
    value: 7,
    key: 'TYPE_date',
    fieldType: 'date',
    lable: 'Dates and times',
    color: '#8E44AD',
  },
  {
    value: 8,
    fieldType: 'Array<int>',
    key: 'TYPE_Array<int>',
    lable: 'Array of int',
    color: '#345C75',
  },
  {
    value: 9,
    fieldType: 'Array<string>',
    key: 'TYPE_Array<string>',
    lable: 'Array of string',
    color: '#345C75',
  },

  {
    value: 10,
    fieldType: 'Array<double>',
    key: 'TYPE_Array<double>',
    lable: 'Array of double',
    color: '#345C75',
  },
  {
    value: 11,
    fieldType: 'Array<long>',
    key: 'TYPE_Array<long>',
    lable: 'Array of long',
    color: '#345C75',
  },
  {
    value: 12,
    key: 'TYPE_Array<float>',
    fieldType: 'Array<float>',
    lable: 'Array of float',
    color: '#345C75',
  },
  {
    value: 13,
    fieldType: 'text',
    key: 'TYPE_text',
    lable: 'Text',
    color: '#2980B9',
  },

  {
    value: 14,
    fieldType: 'ar-string',
    key: 'TYPE_ar-string',
  },

  {
    value: 15,
    fieldType: 'en-string',
    key: 'TYPE_en-string',
  },
  {
    value: 16,
    fieldType: 'string-b0',
    key: 'TYPE_string-b0',
  },
  {
    value: 17,
    fieldType: 'ar-string-b0',
    key: 'TYPE_ar-string-b0',
  },
  {
    value: 18,
    fieldType: 'en-string-b0',
    key: 'TYPE_en-string-b0',
  },
  {
    value: 19,
    fieldType: 'Array<string-b0>',
    key: 'TYPE_Array<string-b0>',
  },
  {
    value: 20,
    fieldType: 'Array<ar-string>',
    key: 'TYPE_Array<ar-string>',
  },
  {
    value: 21,
    fieldType: 'Array<en-string>',
    key: 'TYPE_Array<en-string>',
  },
  {
    value: 22,
    fieldType: 'Array<ar-string-b0>',
    key: 'TYPE_Array<ar-string-b0>',
  },
  {
    value: 23,
    fieldType: 'Array<en-string-b0>',
    key: 'TYPE_Array<en-string-b0>',
  },
];

export function schemaTableHeadData(translate: any, fieldTypeStyle: any) {
  return [
    {
      title: `${translate('FIELD')}`,
      key: 'name',
    },
    {
      title: `${translate('FIELD_TYPE')}`,
      key: (row: any) => {
        return (
          fieldTypeStyle(
            fieldTypeArray.find(
              (option: any) => option?.fieldType === row?.field_type
            )
          ) ?? ''
        );
      },
    },
    {
      title: `${translate('INDEXING_REQUIRED')}`,
      key: 'required',
    },
    {
      title: `${translate('SEARCHABLE')}`,
      key: (row: any) => {
        return row?.field_options?.is_search;
      },
    },
    {
      title: `${translate('AUTOCOMPLETE')}`,
      key: (row: any) => {
        if (
          row?.suggest_rules?.has_filter ||
          row?.suggest_rules.has_navigational
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      title: `${translate('DESCRIPTION')}`,
      key: (row: any) => {
        return (
          translate(
            fieldTypeArray.find(
              (option: any) => option?.fieldType === row?.field_type
            )?.key
          ) ?? ''
        );
      },
    },
  ];
}

export function copyFiledTableHeadData(translate: any, fieldTypeStyle: any) {
  return [
    {
      title: `${translate('FIELD')}`,
      key: (row: any) => {
        return row?.target?.name;
      },
    },
    {
      title: `${translate('FIELD_TYPE')}`,
      key: (row: any) => {
        return (
          fieldTypeStyle(
            fieldTypeArray.find(
              (option: any) => option?.fieldType === row?.target?.field_type
            )
          ) ?? ''
        );
      },
    },
    {
      title: `${translate('MAX_CHARS')}`,
      key: (row: any) => {
        return row?.max_chars ?? 0;
      },
    },
    {
      title: `${translate('SEARCHABLE')}`,
      key: (row: any) => {
        return row?.target?.field_options?.is_search;
      },
    },
    {
      title: `${translate('DESCRIPTION')}`,
      key: (row: any) => {
        return (
          translate(
            fieldTypeArray.find(
              (option: any) => option?.fieldType === row?.target?.field_type
            )?.key
          ) ?? ''
        );
      },
    },
  ];
}

export interface SchemaFieldFormState {
  mode: 'add' | 'edit';
  step: number;
  name: string;
  fieldType: 'string' | 'number' | 'boolean' | 'date';
  autocomplete: boolean;
  required: boolean;
  isArray: boolean;
  purpose: 'search' | 'filter' | 'store';
  stringProcessing: 'tokenized' | 'non-tokenized';
  stringLength: 'long' | 'short';
  textLanguage: 'any' | 'ar' | 'en';
  numberType: 'float' | 'integer';
  integerPrecision: 'short' | 'long';
  floatPrecision: 'normal' | 'high';
  originalAutocomplete: boolean;
}

export const SCHEMA_FIELD_FORM_EXAMPLES = {
  date: ['2021-12-28T11:49:28Z'],
  boolean: ['true', 'false'],
  string: {
    search: {
      short: {
        ar: ['قال', 'لقي', 'صاح'],
        en: ['say', 'mad', 'yes'],
        any: [...['قال', 'لقي', 'صاح'], ...['say', 'mad', 'yes']],
      },
      long: {
        ar: ['سنكتبه', 'صنوان', 'فأسقيناكموه', 'فَسَيَكْفِيكَهُمُ'],
        en: ['composition', 'conventional', 'magnitudes'],
        any: [
          ...['composition', 'conventional', 'magnitudes'],
          ...['سنكتبه', 'صنوان', 'فأسقيناكموه', 'فَسَيَكْفِيكَهُمُ'],
        ],
      },
    },
    filter: ['chemical', 'food', 'technical'],
    store: [
      'https://en.wikipedia.org/wiki/Main_Page',
      'https://stackoverflow.com/',
    ],
  },
  number: {
    integer: {
      short: [5, 1, 76, 124, 256],
      long: [5334126, 8351156, 22206981276225],
    },
    float: {
      normal: [12.4, 54.73, 64.02],
      high: [3.141592653589793238, 2.7182824926642],
    },
  },
};

export function buildFieldOptions({
  dataType,
  isSearchable,
}: BuildFieldOptionsProps): BuildFieldOptionsReturnType {
  switch (dataType) {
    case 'string':
    case 'Array<string>': {
      if (isSearchable) {
        return {
          is_store: true,
          is_search: true,
          is_filter: false,
        };
      }

      return {
        is_store: true,
        is_search: false,
        is_filter: true,
      };
    }
    case 'text': {
      return {
        is_store: true,
        is_search: true,
        is_filter: false,
      };
    }
    default: {
      return {
        is_store: true,
        is_filter: true,
        is_search: false,
      };
    }
  }
}
