import { useState } from 'react';
import { FilterDatePicker } from '../../../../../../design-system/filter-date-picker/filter-date-picker';
import { LablebTable } from '../../../../../../design-system/table/table';
import { AnalyticsIndexFilter } from '../../../components/indexs-filter';
import {
  queryCountriesTableHeadData,
  queryInfoTableHeadData,
} from '../../../search-analytics.page.constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export function QueryInfoClickedPageLoader() {
  const [translate] = useTranslation();

  return (
    <>
      <div className="w-full flex flex-col justify-between">
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center gap-5 mb-5 justify-between flex-wrap">
            <div className="flex items-center ">
              <span className="text-[#6D7B9B] whitespace-nowrap text-[24px] ">
                {translate('MOST_CLICKED_RESULTS')}
              </span>
              <hr className="w-[2px] h-[20px] bg-[#6D7B9B] border-none rounded-sm mx-2" />
              <span className="text-[#030F2C] text-[24px] whitespace-nowrap">
                {translate('CLICK_INFO')}
              </span>
            </div>

            <div className="flex items-center flex-wrap gap-5 ">
              <div className="flex items-center gap-5 flex-wrap">
                <AnalyticsIndexFilter />
                <FilterDatePicker />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-5 mb-5">
            <div className="w-full flex  flex-col mb-6">
              <div className="w-full flex items-center justify-between rounded-t-xl bg-white h-[76px] p-3 border-b-4">
                <span className="text-[20px] font-bold">
                  {translate('CLICKED_QUERY_INFO')}
                </span>
              </div>
              <LablebTable
                tableHeader={queryInfoTableHeadData()}
                tableRows={[]}
                loading={true}
              />
            </div>
          </div>
          <div className="w-full flex  flex-col ">
            <div className="w-full flex items-center justify-between rounded-t-xl bg-white h-[76px] p-3 border-b-4">
              <span className="text-[20px] font-bold">
                {translate('CLICED_QUERY_COUNTRIES')}
              </span>
            </div>
            <LablebTable
              tableHeader={queryCountriesTableHeadData()}
              tableRows={[]}
              loading={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
