import { useTranslation } from 'react-i18next';
import searchIcon from '../../assets/icons/search.svg';
import fileUploadIcon from '../../assets/icons/file-upload-blue.svg';
import circlePlusIcon from '../../assets/icons/circle-plus.svg';
import { LablebTable } from '../../design-system/table/table';
import { synonymsTableHeadData } from './synonyms.page.constants';

export function SynonymsPageLoader() {
  const [translate] = useTranslation();

  return (
    <>
      <div className="w-full ">
        <div className="w-full flex items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">{translate('SYNONYMS')}</span>
          <div className=" flex items-center justify-between">
            <div className="relative">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[22px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                readOnly
              />
            </div>

            <div className="flex p-2 cursor-pointer rounded-xl mx-3 bg-primary items-center justify-center ">
              <img src={circlePlusIcon} className="max-w-[25px]" alt="add" />
            </div>

            <div className="flex p-2 cursor-pointer rounded-xl  bg-[#EBEFF1] items-center justify-center ">
              <img src={fileUploadIcon} className="max-w-[25px]" alt="filter" />
            </div>
          </div>
        </div>

        <LablebTable
          loading
          tableHeader={synonymsTableHeadData(translate)}
          tableRows={[]}
        />
      </div>
    </>
  );
}
