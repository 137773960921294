export const rulesTypes: any = [
  {
    type: 'filter',
    lable: 'FILTER_SUGGESTION',
    color: '#E67E22',
  },
  {
    type: 'navigational',
    lable: 'NAVIGATIONS_SUGGESTION',
    color: '#8E44AD',
  },
];

export function rulesTableHeadData(translate: any, rulesTypeStyle: any) {
  return [
    {
      title: `${translate('RULE')}`,
      key: (row: any, index: any) => {
        return `Rule ${index + 1}`;
      },
    },
    {
      title: `${translate('TYPE')}`,
      key: (row: any) => {
        return (
          rulesTypeStyle(
            rulesTypes.find((option: any) => option?.type === row?.type)
          ) ?? ''
        );
      },
    },
    {
      title: `${translate('Prefix')}`,
      key: (row: any) => {
        return row?.prefix ?? false;
      },
    },
    {
      title: `${translate('Payload')}`,
      key: (row: any) => {
        return row?.payloads?.length == 0 ? false : true;
      },
    },
    {
      title: `${translate('Trim')}`,
      key: (row: any) => {
        return row?.trim_length ?? false;
      },
    },
    {
      title: `${translate('Filters')}`,
      key: (row: any) => {
        return row?.filters?.length == 0 ? false : true;
      },
    },
    {
      title: `${translate('Conditions')}`,
      key: (row: any) => {
        return Object.keys(row?.condition).length == 0 ? false : true;
      },
    },
  ];
}
