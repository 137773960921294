import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import searchIcon from '../../../assets/icons/search.svg';
import circlePlusIcon from '../../../assets/icons/circle-plus.svg';
import useStore from '../../../store/store';
import { LablebTable } from '../../../design-system/table/table';
import { copyFiledTableHeadData } from '../schema.page.constants';
import { useFields } from '../../../hooks/schema/fields-fetch.hook';
import { useCopyFieldDelete } from '../../../hooks/schema/copy-field-delete.hook';
interface SuggestCopyFieldSectionProps {
  fieldTypeStyle: any;
  viewSuggestCopyField: any;
  loading?: boolean;
  suggestSchemaResponse: any;
}

export function SuggestCopyFieldSection(props: SuggestCopyFieldSectionProps) {
  const {
    fieldTypeStyle,
    viewSuggestCopyField,
    loading,
    suggestSchemaResponse,
  } = props;
  const { projectId, indexId }: any = useParams();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const changeAddCopyFieldCollectionType: any = useStore(
    (state) => state?.changeAddCopyFieldCollectionType
  );
  const setWarningDialogHandler = useStore(
    (state) => state.setWarningDialogHandler
  );
  const [searchSuggestCopyFildes, setSearchSuggestCopyFildes] =
    useState<string>('');

  function searchSuggestCopyFildesHandler(event: any) {
    setSearchSuggestCopyFildes(event.target.value);
  }
  function openCreateSuggestCopyFieldPage() {
    changeAddCopyFieldCollectionType('suggest');
    navigate(
      `/projects/${projectId}/indices/${indexId}/schema/copy-fields/create`
    );
  }

  const suggestSchemaCopyFields = suggestSchemaResponse?.copies ?? [];

  const { mutate: deleteCopyFieldInSchema } = useCopyFieldDelete({
    projectId,
    indexId: `${indexId}-suggest`,
    copyFieldName: 'copy-name',
  });

  function removeCopyField(field: any) {
    deleteCopyFieldInSchema({
      indexId,
      copyFieldName: field?.target.name,
    });
  }

  return (
    <>
      <div>
        <div className="w-full flex items-center mb-8 justify-between">
          <span className="text-[20px] font-bold">
            {translate('SUGGEST_COPY_FIELD')}
          </span>
          <div className=" flex items-center justify-between">
            <div className="relative mx-3">
              <button className="absolute top-[20%] mx-2">
                <img src={searchIcon} className="max-w-[22px]" alt="search" />
              </button>

              <input
                className="w-full rounded-xl py-2 px-10 border-[1px] border-[#c7c7c7] outline-none"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                value={searchSuggestCopyFildes}
                onChange={searchSuggestCopyFildesHandler}
              />
            </div>

            {/* <div className="flex p-2 cursor-pointer rounded-xl mx-3 bg-white items-center justify-center border-[1px] border-[#c7c7c7]">
              <img src={filterIcon} className="max-w-[25px]" alt="filter" />
            </div> */}

            <div
              onClick={openCreateSuggestCopyFieldPage}
              className="flex p-2 cursor-pointer rounded-xl bg-primary items-center justify-center "
            >
              <img src={circlePlusIcon} className="max-w-[25px]" alt="add" />
            </div>
          </div>
        </div>

        <LablebTable
          loading={loading}
          tableHeader={copyFiledTableHeadData(translate, fieldTypeStyle)}
          tableRows={suggestSchemaCopyFields?.filter((item: any) => {
            if (searchSuggestCopyFildes === '') {
              return item;
            } else if (
              item?.target?.name
                .toLowerCase()
                .includes(searchSuggestCopyFildes.toLowerCase())
            ) {
              return item;
            }
            return null;
          })}
          hasPermissionsList
          viewHandler={viewSuggestCopyField}
          deleteHandler={(field: any) => {
            setWarningDialogHandler({
              open: true,
              title: 'DELETE_SCHEMA_FIELD_WARNING_TITLE',
              body: 'DELETE_SCHEMA_FIELD_WARNING_DESCRIPTION',
              onAcceptDispatch: () => removeCopyField(field),
              onCancelDispatch: {},
            });
          }}
        />
      </div>
    </>
  );
}
