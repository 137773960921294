import { useTranslation } from 'react-i18next';
import { CardLinearProgress } from './components/linear-progress';
import { LablebSkeleton } from '../skeleton/skeleton';
import { LablebCountUp } from '../count-up/count-up';

interface BillingCardProps {
  cardIcon: any;
  cardTitle: string;
  cardValue: number;
  percentageValue: number;
  progressColor: string;
  totalValue: number;
  loading?: boolean;
}

export function BillingCard(props: BillingCardProps) {
  const {
    cardIcon,
    cardTitle,
    cardValue,
    percentageValue,
    progressColor,
    totalValue,
    loading,
  } = props;
  const [translate] = useTranslation();
  return (
    <>
      <div className="flex flex-col w-full h-[160px] justify-between rounded p-5 bg-white">
        <div className="flex items-center">
          <img src={cardIcon} alt="card-icon" />
          <div className="flex flex-col mx-6">
            <span className="text-[16px] text-[#6D7B9B]">
              {translate(cardTitle)}
            </span>
            {loading ? (
              <LablebSkeleton style="w-14 h-5 " />
            ) : (
              <span className="text-[24px] font-bold text-[#030F2C]">
                {<LablebCountUp value={cardValue} />}
              </span>
            )}
          </div>
        </div>

        <CardLinearProgress
          loading={loading}
          total={totalValue}
          value={cardValue}
          percentageValue={percentageValue}
          color={progressColor}
        />
      </div>
    </>
  );
}
