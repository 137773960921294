import databaseIcon from '../../assets/icons/database.svg';
import homeIcon from '../../assets/icons/home.svg';
import chartPieIcon from '../../assets/icons/chart-pie.svg';
import keyIcon from '../../assets/icons/key.svg';
import receiptIcon from '../../assets/icons/receipt.svg';
import usersIcon from '../../assets/icons/users.svg';
import databaseWhite from '../../assets/icons/database-white.svg';
import chartPieWhite from '../../assets/icons/chart-pie-white.svg';
import keyWhite from '../../assets/icons/key-white.svg';
import usersWhite from '../../assets/icons/users-white.svg';
import receiptWhite from '../../assets/icons/receipt-white.svg';
import homeWhite from '../../assets/icons/home-white.svg';
import support from '../../assets/icons/headset.svg';
import supportWhite from '../../assets/icons/headset-white.svg';

export function sideNavList(
  translate: any,
  currentPlatform: string,
  currentIndex: string,
  isDeveloper: boolean,
  isViewOnly: boolean,
  hasFullControl: boolean,
  hasPaymentIssues: boolean,
  hasNoSubscription: boolean,
  isEcommerce: boolean
) {
  return [
    {
      title: `${translate('CONTROL_PANEL')}`,
      icon: homeIcon,
      iconOnSelect: homeWhite,
      path: `/projects/${currentPlatform}/overview`,
      alt: 'control-panel',
      isNestedMenu: false,
      hide: false,
    },
    {
      title: `${translate('INDICIES')}`,
      icon: databaseIcon,
      iconOnSelect: databaseWhite,
      path: !isEcommerce
        ? `/projects/${currentPlatform}/indices`
        : `/projects/${currentPlatform}/indices/${currentIndex}/synonyms`,
      alt: 'indices',
      isNestedMenu: true,
      links: indicesLinks,
      hide: isViewOnly,
    },
    {
      title: `${translate('ANALYTICS')}`,
      icon: chartPieIcon,
      iconOnSelect: chartPieWhite,
      alt: 'analytics',
      path: `/projects/${currentPlatform}/search-analytics`,
      isNestedMenu: true,
      links: analyticsLinks,
      hide: false,
    },
    {
      title: `${translate('APIS_KEYS')}`,
      icon: keyIcon,
      iconOnSelect: keyWhite,
      path: `/projects/${currentPlatform}/api-keys`,
      alt: 'apiKeys',
      isNestedMenu: false,
      hide: isViewOnly || isEcommerce,
    },
    {
      title: `${translate('USERS')}`,
      icon: usersIcon,
      iconOnSelect: usersWhite,
      path: `/projects/${currentPlatform}/users`,
      alt: 'users',
      isNestedMenu: false,
      hide: isDeveloper || isViewOnly,
    },
    {
      title: `${translate('BILLING')}`,
      icon: hasPaymentIssues ? receiptWhite : receiptIcon,
      iconOnSelect: receiptWhite,
      path: hasNoSubscription
        ? `/projects/${currentPlatform}/billing/payment-plans`
        : `/projects/${currentPlatform}/billing`,
      alt: 'billing',
      isNestedMenu: false,
      hide: !hasFullControl || isEcommerce,
      warn: hasPaymentIssues,
    },
    {
      title: `${translate('SUPPORT')}`,
      icon: support,
      iconOnSelect: supportWhite,
      path: `/projects/${currentPlatform}/support`,
      alt: 'support',
      isNestedMenu: false,
    },
  ];
}
export function indicesLinks(
  translate: any,
  currentPlatform: string,
  currentIndex: string,
  isEcommerce?: boolean
) {
  return [
    {
      name: `${translate('SCHEMAS')}`,
      path: `/projects/${currentPlatform}/indices/${currentIndex}/schema`,
      hide: isEcommerce,
    },
    {
      name: `${translate('QUERIES')}`,
      path: `/projects/${currentPlatform}/indices/${currentIndex}/query`,
      hide: isEcommerce,
    },
    {
      name: `${translate('SYNONYMS')}`,
      path: `/projects/${currentPlatform}/indices/${currentIndex}/synonyms`,
    },
    {
      name: `${translate('SUGGESTION_RULES')}`,
      path: `/projects/${currentPlatform}/indices/${currentIndex}/suggest-rules`,
      hide: isEcommerce,
    },
    {
      name: `${translate('DEMO')}`,
      path: `/projects/${currentPlatform}/indices/${currentIndex}/demo`,
      hide: isEcommerce,
    },
  ];
}

export function analyticsLinks(
  translate: any,
  currentPlatform: any,
  currentIndex: any,
  isEcommerce?: boolean
) {
  return [
    {
      name: `${translate('SEARCH_ANALYTICS')}`,
      path: `/projects/${currentPlatform}/search-analytics`,
    },
    {
      name: `${translate('RECOMMENDATION_ANALYTICS')}`,
      path: `/projects/${currentPlatform}/recommendations-analytics`,
      hide: isEcommerce,
    },
  ];
}
